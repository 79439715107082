<template>
  <div :class="classObj" class="app-wrapper">
    <div class="big-header" :class="{'fixed-header':fixedHeader}">
      <navbar @toggleSideBar="toggleSideBar" />
    </div>
    <div class="main-container">
      <!-- <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside" /> -->
      <div v-show="!isMobile" class="main-nav">
        <SideBar @closeSlidebarByMobile="closeSlidebarByMobile" />
      </div>
      <app-main />
    </div>
    <!-- <div class="footer">
      <div class="left-location">
        <span>联系地址：{{ websiteInformation.contact_address }}{{ websiteInformation.contact_number }}</span>
      </div>
      <div class="right-company">
        <div class="company">
          <a href="javascript:;">{{ websiteInformation.copyright_information }}</a>
        </div>
        <div class="archival-information">
          <a href="javascript:;">{{ websiteInformation.record_information }}</a>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { Navbar, AppMain } from './components'
import SideBar from './components/SideBar/index'
import ResizeMixin from './mixin/ResizeHandler'
import { getStorage } from '@/utils/storage'
export default {
  name: 'Layout',
  components: {
    Navbar,
    SideBar,
    AppMain
  },
  mixins: [ResizeMixin],
  data() {
    return {
      isMobile: false
    }
  },
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar
    },
    device() {
      return this.$store.state.app.device
    },
    fixedHeader() {
      return this.$store.state.settings.fixedHeader
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      }
    },
    websiteInformation() {
      return getStorage('website_information')
    }
  },
  watch: {
    device(v) {
      this.isMobile = v === 'mobile'
    }
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
      this.isMobile = !this.isMobile
      console.log('close')
    },
    toggleSideBar() {
      this.isMobile = !this.isMobile
    },
    // 移动端接收关闭通知
    closeSlidebarByMobile() {
      this.isMobile = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.app-main {
  // padding-bottom: 20px !important;
}
.sidebar-container {
  padding-top: 60px;
  padding-bottom: 40px;
}
.big-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2000 !important;
  width: 100%;
}

.main-nav {
  // width: 220px;
  width: 70px;
  height: calc(100vh - 56px);
  // height: 100vh;
  position: fixed;
  left: 0;
  top: 56px;
  z-index: 100;
}

.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #f5f6fa;
  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }

  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background-color: #222;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1010;
    padding: 0 30px;
    color: #ddd;
    font-size: 14px;
    .right-company {
      display: flex;
      font-size: 14px;
      .company {
        margin-right: 30px;
        cursor: pointer;
        a {
          color: #dddddd;
          &:hover {
            color: #00ce6d;
          }
        }
      }

      .archival-information {
        cursor: pointer;
        a {
          color: #dddddd;
          &:hover {
            color: #00ce6d;
          }
        }
      }
    }
  }
}
.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 99;
}

// .fixed-header {
//   position: fixed;
//   top: 0;
//   right: 0;
//   z-index: 9;
//   width: calc(100% - #{$sideBarWidth});
//   transition: width 0.28s;
// }

.fixed-header {
  padding: 0 !important;
}

.mobile .fixed-header {
  width: 100%;
}
</style>

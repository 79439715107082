var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "scrollBox",
      staticClass: "scroll_parent_box",
      on: {
        mousedown: _vm.mEnter,
        mouseup: _vm.mLeave,
        mouseenter: _vm.mEnter,
        mouseleave: _vm.mLeave,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "scroll_list",
          style: { transform: "translate(0px,-" + _vm.scrollTop + "px)" },
        },
        [
          _c(
            "div",
            { ref: "scrollItemBox", staticClass: "sl" },
            [_vm._t("default")],
            2
          ),
          _c("div", {
            staticClass: "sl1",
            domProps: { innerHTML: _vm._s(_vm.copyHtml) },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
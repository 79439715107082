import { asyncRoutes, constantRoutes } from '@/router'
import { getStorage, setStorage } from '@/utils/storage'
import request from '@/api/https'
import { getUserRoleInfo } from '@/api/layout/information'
import Layout from '@/layout/index'
import { isEmpty } from 'element-ui/src/utils/util'

/**
 * 使用 meta.role 确定当前用户是否有权限
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.includes(route.meta.roles)
  } else {
    return true
  }
}

/**
 * 通过递归过滤异步路由表
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  const res = []

  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = tmp.children.filter(route => {
          if (hasPermission(roles, route)) {
            return route
          }
        })
      }
      res.push(tmp)
    }
  })

  return res
}

const state = {
  routes: [],
  addRoutes: []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  },

  SET_FIRSTROUTES: (state, routes) => {
    state.addRoutes = []
    state.addRoutes = routes
    state.routes = state.routes.slice(0, 4)
    state.routes.push(...routes)
  },

  DEL_FIRSTROUTES: (state, routes) => {
    state.addRoutes = []
  }
}

const actions = {
  async getAsyncRoutes({ commit }) {
    const res = []
    // return request({
    //   method: 'post',
    //   params: {
    //     api: 'saas.role.getAsyncRoutesByRoutes',
    //   },
    // }).then(routes => {
    //   if(routes.data.code == '200') {
    //     let route = routes.data.data;
    //     console.log(route,"菜单权限")
    //     if (route.menu.length !== 0) {
    //       const list=[]
    //       route.menu.forEach((menu, index) => {
    //         let icons = [];
    //         if (!isEmpty(menu.image)) {
    //           icons.push(menu.image)
    //         }
    //         if (!isEmpty(menu.image1)) {
    //           icons.push(menu.image1)
    //         }
    //         //一级菜单
    //         let topMenu = {
    //           path: "/" + menu.module,
    //           component: Layout,
    //           redirect: "/" + menu.module + "/" + menu.children[0].module,
    //           name: menu.module,
    //           meta: {title: menu.title, icon: icons},
    //         }
    //         //递归子菜单
    //         topMenu.children = actions.getMenus(menu.children)

    //         res.push(topMenu)
    //         menu.children.forEach((item, i)=>{
    //           list.push(item)
    //         })

    //       })
    //       setStorage("route",list)
    //     }
    //     return actions.generateRoutes(commit, res);
    //   }
    // })

    const routes = await getUserRoleInfo({
      api: 'saas.role.getAsyncRoutesByRoutesTop'
    })
    if (routes.data.code == '200') {
      const route = routes.data.data
      if (route.menu.length !== 0) {
        if (route.menu.some(item => {
          return item.title == '首页'
        })) {
          for (let i = 0; i < route.menu.length; i++) {
            if (route.menu[i].title == '首页') {
              route.menu[i].children = await actions.getMenu(route.menu[i].id)
              break
            }
          }
        } else {
          route.menu[0].children = await actions.getMenu(route.menu[0].id)
        }
        // debugger
        // const list=[]
        route.menu.forEach((menu, index) => {
          const icons = []
          if (!isEmpty(menu.image)) {
            icons.push(menu.image)
          }
          if (!isEmpty(menu.image1)) {
            icons.push(menu.image1)
          }
          // 一级菜单
          const topMenu = {
            children: [],
            path: '/' + menu.module,
            component: Layout,
            redirect: menu.children.length ? '/' + menu.module + '/' + menu.children[0].module : '/' + menu.path + '/',
            name: menu.module,
            meta: { title: menu.title, icon: icons },
            id: menu.id
          }

          // 递归子菜单
          if (menu.children.length) {
            topMenu.children = actions.getMenus(menu.children)
          }

          res.push(topMenu)
          // menu.children.forEach((item, i)=>{
          //   list.push(item)
          // })
        })
        // setStorage("route",list)
      }
      return actions.generateRoutes(commit, res)
    }
  },

  // 菜单递归
  getMenus(menuList) {
    if (isEmpty(menuList)) {
      return []
    }
    menuList.forEach((currentMenu, index) => {
      const childrenMenu = {
        children: currentMenu.children,
        path: currentMenu.module,
        name: currentMenu.module,
        meta: { title: currentMenu.title, is_core: currentMenu.is_core },
        id: currentMenu.id
      }
      // 是否有子菜单
      // if (!currentMenu.isChildren) {
      //   childrenMenu.component = resolve => require([`@/views${currentMenu.url}`], resolve)
      // } else {
      //   childrenMenu.redirect = currentMenu.url
      //   childrenMenu.component = {
      //     render(c) {
      //       return c('router-view')
      //     }
      //   }
      //   //继续递归
      //   childrenMenu.children = actions.getMenus(currentMenu.children);
      // }

      if (childrenMenu.children.length == 0) {
        childrenMenu.component = resolve => require([`@/views${currentMenu.url}`], resolve)
      } else {
        childrenMenu.redirect = currentMenu.url
        childrenMenu.component = {
          render(c) {
            return c('router-view')
          }
        }
        // 继续递归
        childrenMenu.children = this.getMenus(currentMenu.children)
      }
      menuList[index] = childrenMenu
    })
    return menuList
  },

  getMenu(id) {
    const ress = []
    return new Promise((resolve, reject) => {
      getUserRoleInfo({
        api: 'saas.role.getAsyncRoutesByRoutesTop',
        sid: id
      }).then(routes => {
        const id = routes.data.data.menu[0].id
        getUserRoleInfo({
          api: 'saas.role.getAsyncRoutesByRoutesTop',
          sid: id
        }).then(res => {
          if (res.data.data.menu.length !== 0) {
            routes.data.data.menu[0].children = res.data.data.menu
            ress.push(...routes.data.data.menu)
            resolve(ress)
          } else {
            ress.push(...routes.data.data.menu)
            resolve(ress)
          }
        })
      })
    })
  },

  generateRoutes(commit, authorizationList) {
    return new Promise(resolve => {
      // let authorizationLists = authorizationList
      // if(getStorage('laike_admin_userInfo').type == 1) {
      //   authorizationLists = authorizationLists.filter(item => {
      //     if(item.meta.title !== '平台') {
      //       return item
      //     }
      //   })
      // }
      commit('SET_ROUTES', authorizationList)
      resolve(authorizationList)
    })
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "l-transfer" }, [
    _c("div", { staticClass: "transfer_left" }, [
      _c("div", { staticClass: "transfer_left_top" }, [
        _vm._v(" 选择属性/属性值 "),
        _c(
          "a",
          { attrs: { href: "javascript:;" }, on: { click: _vm.checkQX } },
          [_vm._v(_vm._s(_vm.qxFlag ? "取消全选" : "全选"))]
        ),
      ]),
      _c("div", { staticClass: "transfer_left_bottom" }, [
        _c(
          "div",
          { staticClass: "iptBox" },
          [
            _c("el-input", {
              attrs: {
                "prefix-icon": "el-icon-search",
                placeholder: "请输入关键字",
                size: "small",
              },
              on: { input: _vm.searchIpt },
              model: {
                value: _vm.keyword,
                callback: function ($$v) {
                  _vm.keyword = $$v
                },
                expression: "keyword",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "treeBox" },
          [
            _c("el-tree", {
              ref: "tree",
              attrs: {
                data: _vm.data,
                "show-checkbox": "",
                "default-expand-all": "",
                "node-key": "id",
                "highlight-current": "",
                props: _vm.defaultProps,
              },
              on: { check: _vm.getCheck },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var node = ref.node
                    return _c(
                      "span",
                      { staticClass: "custom-tree-node" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: { content: node.label, placement: "bottom" },
                          },
                          [_c("span", [_vm._v(_vm._s(node.label))])]
                        ),
                      ],
                      1
                    )
                  },
                },
              ]),
            }),
            _vm.dataLeng > 5
              ? _c(
                  "div",
                  { staticClass: "loading" },
                  [
                    _vm.loadingType == 0
                      ? _c(
                          "a",
                          {
                            attrs: { href: "javascript:;" },
                            on: { click: _vm.scroll },
                          },
                          [_vm._v("加载更多")]
                        )
                      : _vm.loadingType == 1
                      ? [
                          _c("i", { staticClass: "el-icon-loading" }),
                          _vm._v(" 正在加载中 "),
                        ]
                      : [_vm._v("暂无更多数据")],
                  ],
                  2
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
    _c(
      "div",
      {
        staticClass: "transfer_center",
        class: { disabled: _vm.checkAll1.length == 0 },
        on: { click: _vm.saveCheck },
      },
      [_c("i", { staticClass: "el-icon-arrow-right" })]
    ),
    _c("div", { staticClass: "transfer_right" }, [
      _c("div", { staticClass: "transfer_right_top" }, [
        _vm._v(" 已选 "),
        _c(
          "div",
          { staticClass: "transfer_right_top_Box" },
          [
            _vm.text
              ? [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:;" },
                      on: { click: _vm.rightClick },
                    },
                    [_vm._v(_vm._s(_vm.text))]
                  ),
                  _c("p", { staticClass: "hr" }, [_vm._v("|")]),
                ]
              : _vm._e(),
            _c(
              "a",
              {
                attrs: { href: "javascript:;" },
                on: { click: _vm.clearCheck },
              },
              [_vm._v("清空")]
            ),
          ],
          2
        ),
      ]),
      _c(
        "div",
        { staticClass: "checkBox" },
        _vm._l(_vm.checkAll, function (item, index) {
          return _c(
            "el-tooltip",
            { key: index, attrs: { content: item.name, placement: "bottom" } },
            [
              _c("div", [
                _c("div", [_vm._v(_vm._s(item.name))]),
                _c("i", {
                  staticClass: "el-icon-circle-close",
                  on: {
                    click: function ($event) {
                      return _vm.delCheck(index)
                    },
                  },
                }),
              ]),
            ]
          )
        }),
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
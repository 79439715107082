<template>
  <div
    ref="scrollBox"
    class="scroll_parent_box"
    @mousedown="mEnter"
    @mouseup="mLeave"
    @mouseenter="mEnter"
    @mouseleave="mLeave"
  >
    <div class="scroll_list" :style="{ transform: `translate(0px,-${scrollTop}px)` }">
      <div ref="scrollItemBox" class="sl">
        <slot />
      </div>
      <div class="sl1" v-html="copyHtml" />
    </div>
  </div>
</template>

<script>
let timer = null
export default {
  name: 'InfiniteScroll',
  props: {
    list: {
      type: Array
    }
  },
  data() {
    return {
      scrollTop: 0, // 列表滚动高度
      speed: 50, // 滚动时间
      copyHtml: '', // 复制多一份防止滚动到后面出现空白
      canScroll: false,
      scrollStep:1,
      fontSizeScale:1
    }
  },
  watch: {
    list(newVal, oldVal) {
      if (newVal instanceof Array && newVal.length > 0) {
        setTimeout(() => {
          if (this.$refs.scrollItemBox && this.$refs.scrollBox) {
            var childHeight = this.$refs.scrollItemBox.offsetHeight
            var parentHeight = this.$refs.scrollBox.offsetHeight
            if (parentHeight < childHeight) {
              this.canScroll = true
              this.initScroll()
            }
          }
        }, 1000)
      } else {
        this.copyHtml = ''
      }
    }
  },
  methods: {
    initScroll() {
      this.$nextTick(() => {
        this.copyHtml = this.$refs.scrollItemBox.innerHTML
        this.startScroll()
      })
    },
    // 鼠标移入停止滚动
    mEnter() {
      if (this.canScroll) {
        clearInterval(timer)
      }
    },
    // 鼠标移出继续滚动
    mLeave() {
      if (this.canScroll) {
        this.startScroll()
      }
    },
    // 开始滚动
    startScroll() {
      if (this.canScroll) {
        if (timer) {
          clearInterval(timer)
        }
        const fontsize = document.documentElement.style.fontSize
        const size = fontsize.replace(/px/g, '')
        this.fontSizeScale = parseFloat(size) / 16
        timer = setInterval(this.scroll, this.speed)
      }
    },
    // 滚动处理方法
    scroll() {
      if (this.$refs.scrollItemBox) {
        this.scrollTop = this.scrollTop + this.scrollStep * this.fontSizeScale
        // 获取需要滚动的盒子的高度
        const scrollItemBox = this.$refs.scrollItemBox.offsetHeight
        // 当判断滚动的高度大于等于盒子高度时，从头开始滚动
        if (this.scrollTop >= scrollItemBox) {
          this.scrollTop = 0
        }
      }
    }
  }
}
</script>

<style scoped>

.scroll_parent_box {
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.scroll_list {
  transition: all 0ms ease-in 0s;
}
</style>


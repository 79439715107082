var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-wrapper", class: _vm.classObj }, [
    _c(
      "div",
      { staticClass: "big-header", class: { "fixed-header": _vm.fixedHeader } },
      [_c("navbar", { on: { toggleSideBar: _vm.toggleSideBar } })],
      1
    ),
    _c(
      "div",
      { staticClass: "main-container" },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isMobile,
                expression: "!isMobile",
              },
            ],
            staticClass: "main-nav",
          },
          [
            _c("SideBar", {
              on: { closeSlidebarByMobile: _vm.closeSlidebarByMobile },
            }),
          ],
          1
        ),
        _c("app-main"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "app-main" }, [
    this.tag == true
      ? _c("div", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "loading",
        })
      : _c(
          "div",
          { staticClass: "container" },
          [
            _c(
              "div",
              { staticClass: "bread-nav" },
              [
                _c("breadcrumb", {
                  staticClass: "breadcrumb-container",
                  attrs: { "separator-class": "el-icon-arrow-right" },
                }),
                _vm.is_return()
                  ? _c(
                      "el-button",
                      {
                        staticClass: "bgColor",
                        attrs: { icon: "el-icon-arrow-left", type: "primary" },
                        on: { click: _vm.returns },
                      },
                      [_vm._v("返回")]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "transition",
              { attrs: { name: "fade-transform", mode: "out-in" } },
              [
                _c(
                  "div",
                  { staticClass: "table-container" },
                  [_c("router-view")],
                  1
                ),
              ]
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "container" } }, [
    _c("div", { staticClass: "login" }, [
      _c("div", { class: [_vm.isPlatform ? "platform" : "", "rotate-div"] }, [
        _c("div", { staticClass: "login-box" }, [
          _c(
            "div",
            {
              staticClass: "login-logo",
              on: {
                click: function ($event) {
                  return _vm.changeLogin(1)
                },
              },
            },
            [_c("img", { attrs: { src: _vm.logo_img } })]
          ),
          _c("h2", [_vm._v("全国农商互联暨乡村振兴产销对接大会")]),
          _c("div", { staticClass: "login-form" }, [
            _c("div", { staticClass: "welcome" }, [_vm._v("WELCOME")]),
            _c(
              "div",
              { staticClass: "login-form-div" },
              [
                _c("h3", [_vm._v("登录")]),
                _c(
                  "el-form",
                  {
                    ref: "merchantsForm",
                    staticClass: "demo-ruleForm",
                    attrs: {
                      model: _vm.merchantsForm,
                      "status-icon": "",
                      rules: _vm.rules2,
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "customerNumber" } },
                      [
                        _c("el-input", {
                          ref: "userNum",
                          attrs: {
                            placeholder: "请输入展商编号",
                            type: "text",
                            autocomplete: "on",
                            "prefix-icon": "el-icon-my-shop",
                          },
                          model: {
                            value: _vm.merchantsForm.customerNumber,
                            callback: function ($$v) {
                              _vm.$set(_vm.merchantsForm, "customerNumber", $$v)
                            },
                            expression: "merchantsForm.customerNumber",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "userName" } },
                      [
                        _c("el-input", {
                          ref: "userName",
                          attrs: {
                            placeholder: "请输入登录账号",
                            type: "text",
                            autocomplete: "on",
                            "prefix-icon": "el-icon-my-user",
                          },
                          model: {
                            value: _vm.merchantsForm.userName,
                            callback: function ($$v) {
                              _vm.$set(_vm.merchantsForm, "userName", $$v)
                            },
                            expression: "merchantsForm.userName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "pwd" } },
                      [
                        _c("el-input", {
                          ref: "pwd",
                          attrs: {
                            placeholder: "请输入登录密码",
                            type: "password",
                            autocomplete: "on",
                            "prefix-icon": "el-icon-my-lock",
                            "show-password": "",
                          },
                          model: {
                            value: _vm.merchantsForm.pwd,
                            callback: function ($$v) {
                              _vm.$set(_vm.merchantsForm, "pwd", $$v)
                            },
                            expression: "merchantsForm.pwd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "56px" } },
                      [
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { float: "right" },
                                attrs: {
                                  loading: _vm.loading,
                                  type: "primary",
                                  "native-type": "submit",
                                  round: "",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.merchantsLogin("merchantsForm")
                                  },
                                },
                              },
                              [_vm._v("登录")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "login-box" }, [
          _c(
            "div",
            {
              staticClass: "login-logo",
              on: {
                click: function ($event) {
                  return _vm.changeLogin(0)
                },
              },
            },
            [_c("img", { attrs: { src: _vm.logo_img } })]
          ),
          _c("h2", [_vm._v("全国农商互联暨乡村振兴产销对接大会")]),
          _c("div", { staticClass: "login-form" }, [
            _c("div", { staticClass: "welcome" }, [_vm._v("WELCOME")]),
            _c(
              "div",
              { staticClass: "login-form-div" },
              [
                _c("h3", [_vm._v("登录")]),
                _c(
                  "el-form",
                  {
                    ref: "platformForm",
                    staticClass: "demo-ruleForm",
                    attrs: {
                      model: _vm.platformForm,
                      "status-icon": "",
                      rules: _vm.rules1,
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "userName" } },
                      [
                        _c("el-input", {
                          ref: "user",
                          attrs: {
                            placeholder: "请输入登录账号",
                            type: "text",
                            autocomplete: "on",
                            "prefix-icon": "el-icon-my-user",
                          },
                          model: {
                            value: _vm.platformForm.userName,
                            callback: function ($$v) {
                              _vm.$set(_vm.platformForm, "userName", $$v)
                            },
                            expression: "platformForm.userName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "pwd" } },
                      [
                        _c("el-input", {
                          ref: "pass",
                          attrs: {
                            placeholder: "请输入密码",
                            type: "password",
                            autocomplete: "on",
                            "prefix-icon": "el-icon-my-lock",
                            "show-password": "",
                          },
                          model: {
                            value: _vm.platformForm.pwd,
                            callback: function ($$v) {
                              _vm.$set(_vm.platformForm, "pwd", $$v)
                            },
                            expression: "platformForm.pwd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "56px" } },
                      [
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  loading: _vm.loading,
                                  type: "primary",
                                  "native-type": "submit",
                                  round: "",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.platformLogin($event)
                                  },
                                },
                              },
                              [_vm._v("登录")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "footer_box" }, [
        _c(
          "ul",
          { staticClass: "link-list" },
          _vm._l(_vm.linkList, function (item, index) {
            return _c("li", { key: index }, [
              item.url
                ? _c("a", { attrs: { href: item.url, target: "_blank" } }, [
                    _vm._v(" " + _vm._s(item.name) + " "),
                  ])
                : _c("span", [_vm._v(_vm._s(item.name))]),
            ])
          }),
          0
        ),
        _c("div", { staticClass: "introduce" }, [
          _c("span", [_vm._v(_vm._s(_vm.copyright_information))]),
          _c("span", { staticClass: "hr" }, [_vm._v("版权所有")]),
        ]),
        _vm._m(0),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "introduce" }, [
      _c("span", [
        _c(
          "a",
          {
            attrs: {
              href: "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802034296",
              target: "_blank",
            },
          },
          [_vm._v("京公网安备 11010802034296号")]
        ),
      ]),
      _c("span", [
        _c(
          "a",
          {
            attrs: {
              href: "https://beian.miit.gov.cn/#/Integrated/index",
              target: "_blank",
            },
          },
          [_vm._v("京ICP备2020040306号-2")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
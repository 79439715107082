<template>
  <div>
    <el-upload   ref="upload" class="upload-file" :on-preview="handlePreview"  :on-remove="deleteFileItem" :file-list="fileLists" name="file"  action=""  :before-upload="beforeUpload" :http-request="customRequest">
      <div class='upload-btn' >点击上传</div>
    </el-upload>
  </div>
</template>
<script>
export default {
  props: {
    fileList: {
      type: Array
    },

    fileNum:{
      type:Number,
      default:1
    }
  },
  data  () {
    return {
      fileLists:this.fileList
    }
  },
  created () {

  },
  methods: {
    // 限制文件大小
    beforeUpload(info) {
      const getFileSize = info.size / 1024 / 1024
      if (getFileSize > 10) {
        return this.$message.error('上传文件不能超过10M')
      }
    },
    customRequest (data) {
      this.$emit('customRequest', data)
    },
    deleteFileItem (file) {
      this.$emit('deleteFileItem', file)
    },
    handlePreview(file){
      this.$emit('handlePreview', file)
    }
  },
  watch:{
    fileList(val){
      this.fileLists = val

    }
  }
}
</script>

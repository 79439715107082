import request from '../https'

// 关联小程序
export const bindMini = params => {
  return request({
    method: 'post',
    params
  })
}
// 注册小程序
export const regMini = params => {
  return request({
    method: 'post',
    params
  })
}

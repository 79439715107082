// import request from '@/plugins/request';

import request from '@/api/https'

/**
 * @description 表格--删除
 * @param {Number} param id {Number} 配置id
 */
export function tableDelApi(data) {
  return request({
    url: data.url,
    method: data.method,
    data: data.ids
  })
}

/**
 * 获取消息提醒
 */
export function jnoticeRequest() {
  return request({
    url: 'jnotice',
    method: 'GET'
  })
}
export const post = params => {
  return request({
    method: 'post',
    params
  })
}
export const get = params => {
  return request({
    method: 'get',
    params
  })
}
export const formData = (data, params) => {
  return request({
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    url: process.env.VUE_APP_BASE_API,
    transformRequest: [
      function(data) {
        let ret = ''
        for (const it in data) {
          ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
        }
        ret = ret.substring(0, ret.lastIndexOf('&'))
        return ret
      }
    ],
    data: data,
    params: params
  })
}
/**
 * 通用
*/
export const http = params => {
    return request({
      method: 'post',
      params
    })
  }

<template>
  <div class="reg">
    <wxthird-header />
    <div class="box">
      <el-steps :active="registerCurrent" class="el-steps-1" simple> 
        <el-step title="基本信息" />
        <el-step title="微信认证授权" />
        <el-step title="填写小程序信息" />
      </el-steps>
      <!-- 审核出错 -->
      <div v-if="registerCurrent==-1 && errorMes" class="errorTip">
        <el-result icon="error" :title="errorMes" />
      </div>
      <div v-else>
        <div v-if="registerCurrent == 0">
          <el-form ref="registerRuleForm" class="form" :model="registerParam" :rules="registerRules" label-width="150px" :disabled="true">

            <div v-if="errorMessage" class="register-error">*{{ errorMessage }}</div>
            <el-form-item ref="legalPersonaWechat" label="法人微信号" prop="legalPersonaWechat">
              <el-input v-model.trim="registerParam.legalPersonaWechat" :disabled="isDisabled" />
            </el-form-item>
            <el-form-item ref="legalPersonaName" label="法人姓名" prop="legalPersonaName">
              <el-input v-model.trim="registerParam.legalPersonaName" :disabled="isDisabled" />
            </el-form-item>
            <el-form-item ref="principalName" label="机构名称" prop="principalName">
              <el-input v-model.trim="registerParam.principalName" :disabled="isDisabled" />
            </el-form-item>
            <!-- <el-form-item label="证照代码类型">
              <el-radio-group v-model="registerParam.codeType" name="radioGroup" :disabled="isDisabled">
                <el-radio :label="1">统一社会信用代码</el-radio>
                <el-radio :label="2">组织机构代码</el-radio>
                <el-radio :label="3">营业执照注册号</el-radio>
              </el-radio-group>
            </el-form-item> -->
            <el-form-item ref="principalCode" label="统一社会信用代码" prop="principalCode">
              <el-input v-model.trim="registerParam.principalCode" :disabled="isDisabled" />
            </el-form-item>
          </el-form>

        </div>
        <div v-if="registerCurrent == 1" class="el-result">
          <el-icon class="el-icon-warning-outline" style="font-size: 36px" />
          <div class="title">温馨提示</div>
          <div class="sub-title">请在微信中完成认证授权，授权完成可点击下一步!</div>
        </div>
        <div v-if="registerCurrent == 2" v-loading="loading" class="step2">
             <el-steps :active="miniConfigActive" finish-status="success"  direction="vertical" style="cursor: pointer;">
              <el-step v-for="(item,index) in miniConfigList" :key="index" :title="item" @click.native="changeMiniConfigActive(index)"></el-step>
            </el-steps>
          <el-form ref="miniRuleForm" class="form" :model="miniParams" :rules="miniRules" label-width="20">
            <div  v-if="miniConfigActive==0">
              <el-form-item ref="miniName" label="" prop="miniName">
                <div class="name-box">
                  <el-input v-model.trim="miniParams.miniName" :disabled="threeStep.nickNameAuditstatus == 1"></el-input>
                  <el-button type="primary" @click="checkName" style="margin-left: 10px;" :disabled="threeStep.nickNameAuditstatus == 1">检测名称是否可用</el-button>
                </div> 
              </el-form-item> 
              <el-form-item
                label="营业执照或组织代码证"
                required
                prop="licenseMediaid"
                v-if="miniNameObj.showMore"
              >
                <el-upload
                  :action="imgUrl"
                  :data="{ appid: threeStep.miniAppId, type: 'image' }"
                  :limit="1"
                  accept="image/png, image/jpeg"
                  :before-upload="beforeUpload"
                  :file-list="miniNameObj.fileListLicense"
                  :on-success="
                    (response, file, fileList) =>
                      getNameFile(response, file, fileList, 'license')
                  "
                  :on-remove="
                    (file, fileList) => removeNameFile(file, fileList, 'license')
                  "
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip" class="el-upload__tip">
                    只能上传jpg/png文件，且不超过1MB
                  </div>
                </el-upload>
              </el-form-item>
              <el-form-item
                v-if="false"
                label="管理员身份证照片"
              >
                <el-upload
                  :action="imgUrl"
                  :data="{ appid: threeStep.miniAppId, type: 'image' }"
                  :limit="1"
                  accept="image/png, image/jpeg"
                  :before-upload="beforeUpload"
                  :file-list="miniNameObj.fileListIdcard"
                  :on-success="
                    (response, file, fileList) =>
                      getNameFile(response, file, fileList, 'idCard')
                  "
                  :on-remove="
                    (file, fileList) => removeNameFile(file, fileList, 'idCard')
                  "
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip" class="el-upload__tip">
                    只能上传jpg/png文件，且不超过1MB
                  </div>
                </el-upload>
              </el-form-item>
              <el-form-item
                label="补充材料"
                v-if="miniNameObj.showMore"
              >
                <el-upload
                  :action="imgUrl"
                  :data="{ appid: threeStep.miniAppId, type: 'image' }"
                  :limit="8"
                  accept="image/png, image/jpeg"
                  :before-upload="beforeUpload"
                  :file-list="miniNameObj.fileListFJ"
                  :on-success="
                    (response, file, fileList) =>
                      getNameFile(response, file, fileList, 'FJ')
                  "
                  :on-remove="
                    (file, fileList) => removeNameFile(file, fileList, 'FJ')
                  "
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip" class="el-upload__tip">
                    只能上传jpg/png文件，且不超过1MB
                  </div>
                </el-upload>
              </el-form-item>
              <div class="name-tips" :class="miniNameObj.status?'suc':'err'" v-if="miniNameObj.tips">
                {{ miniNameObj.tips }}
              </div>
              <p class="name-tips" v-else>
                帐号名称长度为4-30个字符，一个中文字等于2个字符。
              </p>  
              <el-button type="primary" @click="submitName" v-if="threeStep.nickNameAuditstatus != 1 &&(miniNameObj.status || (miniNameObj.showMore&&miniParams.licenseMediaid))">提交</el-button>
            </div>
            <el-form-item ref="miniHead" label="" prop="miniHead" v-if="miniConfigActive==1">
              <div style="position:relative; margin-bottom:10px">
                <el-upload
                  :action="imgUrl"
                  :data="{ appid: threeStep.miniAppId, type: 'image' }"
                  accept="image/png, image/jpeg"
                  :before-upload="beforeUpload"
                  :show-file-list="false"
                  :on-success="getLogoFile"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip" class="el-upload__tip">
                    头像不允许涉及政治敏感与色情;图片格式必须为：png,bmp,jpeg,jpg,gif；不可大于1M；建议使用png格式图片，以保持最佳效果；建议图片尺寸为144px*144px
                  </div>
                </el-upload>
              </div>
              <div v-if="miniParams.miniHead" style="display:flex;padding:10px;background:#f1f1f1;width:236px;margin-bottom: 10px;">
                <img style="width:104px;height:104px;margin-right:8px" :src="miniParams.miniHead" alt="">
                <img style="width:104px;height:104px;border-radius:100px" :src="miniParams.miniHead" alt="">
              </div>
              <el-button type="primary" v-if="logoId" @click="submitLogo">提交</el-button>
            </el-form-item> 
            <el-form-item ref="miniSignature" label="" prop="miniSignature" v-if="miniConfigActive==2">
              <el-input v-model="miniParams.miniSignature" type="textarea" :rows="3" style="margin-bottom:10px" />
              <p :style="{color: signatureTipsStatus?'red':'#999'}">
                {{ signatureTips }}
              </p>
              <el-button type="primary" @click="submitSignature">提交介绍
              </el-button>
            </el-form-item>
            <div v-if="miniConfigActive == 3">
              <el-form-item><div class="name-tips">以下4种联系方式至少要填一种</div></el-form-item>
              <el-form-item
                ref="ownerContactPhone"
                label="电话"
                prop="ownerContactPhone"
              >
                <el-input v-model="miniParams.ownerContactPhone" />
              </el-form-item>
              <el-form-item
                ref="ownerContactEmail"
                label="邮箱"
                prop="ownerContactEmail"
              >
                <el-input v-model="miniParams.ownerContactEmail" />
              </el-form-item>
              <el-form-item
                ref="ownerContactWeixin"
                label="微信"
                prop="ownerContactWeixin"
              >
                <el-input v-model="miniParams.ownerContactWeixin" />
              </el-form-item>
              <el-form-item ref="ownerContactQq" label="QQ" prop="ownerContactQq">
                <el-input v-model="miniParams.ownerContactQq" />
              </el-form-item>
              <el-button type="primary" @click="submitContact">提交</el-button>
            </div>
          </el-form>
        </div>

        <el-button type="primary" style="display:block;margin:50px auto;" @click="registerNextStep" v-if="registerCurrent != 2">下一步</el-button>
      </div>

    </div>

  </div>
</template>

<script>
import wxthirdHeader from '../head.vue'
import { regMini } from '@/api/wxthird'
import { post } from "@/api/common";

export default {
  components: {
    wxthirdHeader
  },
  data() {
    return {
      registerCurrent: 0,
      miniConfigActive: 0, // 填写小程序信息子步骤
      errorMessage: '', // 错误信息
      isDisabled: false, // 是否可以修改快速注册小程序 第一步填写过得信息
      registerParam: {
        legalPersonaWechat: '',
        legalPersonaName: '',
        principalName: '',
        codeType: 1,
        principalCode: ''

      },
      registerRules: {
        legalPersonaWechat: [
          { required: true, message: '法人微信账号不能为空！', trigger: 'blur' }
        ],
        legalPersonaName: [
          { required: true, message: '企业法人姓名不能为空！', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '企业名称不能为空！', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '营业执照代码不能为空！', trigger: 'blur' }
        ]

      },
      miniParams: {
        miniName: '',    
        idCardMediaid: "",
        licenseMediaid: "",
        nickNameMediaids: [],
        miniHead: '',
        miniSignature: '',
        ownerContactPhone: "",
        ownerContactEmail: "",
        ownerContactWeixin: "",
        ownerContactQq: ""
      },
      miniRules: {
        miniName: [
          { required: true, message: '小程序名称不能为空！', trigger: 'blur' },
          { max: 30, min: 2, message: '请输入2-30个字符的名称', trigger: 'blur' }
        ],
        miniHead: [
          { required: true, message: '小程序logo不能为空！' }
        ],
        miniSignature: [
          { required: true, message: '小程序介绍不能为空！', trigger: 'blur' },
          { max: 120, min: 4, message: '请输入4-120个字符的名称', trigger: 'blur' }
        ]
      },  
      miniNameObj: {
        status: false,
        tips: "",
        fileListLicense: [],
        fileListIdcard: [],
        fileListFJ: [],
      },
      miniConfigList:['设置小程序名称','设置小程序logo','设置小程序介绍','设置用户隐私保护指引'],
      logoFiles: "",
      logoId: '',
      signatureTips: '请确认介绍内容不含国家相关法律法规禁止内容,介绍字数为4~120个字',
      signatureTipsStatus: false,
      threeStep: {},
      errorMes: '',
      loading: false
    }
  },
  computed:{
    imgUrl() {
      return process.env.VUE_APP_UPLOAD_IMG_URL;
    },
  },
  created() {
    this.getStatus()
  },
  methods: {
    // 获取当前状态,走到了第几部
    async getStatus() {
      const res = await regMini({
        api: 'customer.wx.fast.step'
      }) 
      this.registerCurrent = res.data.data.loginStep
      if (this.registerCurrent === 0) { // 第一步
        const regInfo = res.data.data.oneStep
        this.registerParam.codeType = regInfo.codeType * 1
        this.registerParam.legalPersonaWechat = regInfo.legalPersonaWechat
        this.registerParam.legalPersonaName = regInfo.legalPersonaName
        this.registerParam.principalName = regInfo.principalName
        this.registerParam.principalCode = regInfo.principalCode
      } else if (this.registerCurrent === 2) {
        this.threeStep = res.data.data.threeStep
        this.miniParams.miniName = res.data.data.threeStep.nickName
        this.miniParams.miniHead = res.data.data.threeStep.headImg
        this.miniParams.miniSignature = res.data.data.threeStep.signature
         // threeStep.nickNameAuditstatus 审核状态 1审核中 2驳回 3成功
        // threeStep.nickNameReason 小程序名称驳回原因
        if(this.threeStep.nickNameAuditstatus == 1){
          this.miniNameObj.tips = '审核中' 
        }        
        if(this.threeStep.nickNameAuditstatus == 2){
          this.miniNameObj.tips = this.threeStep.nickNameReason
        } 
        this.getWxInfo()
      } else if (this.registerCurrent === 3) {
        this.$router.push('/')
      }
      this.errorMes = res.data.data.oneStep ? res.data.data.oneStep.errorMessage : ''
      if (this.errorMes) {
        this.$message.error(this.errorMes)
      }
    },
    registerNextStep() {
      if (this.registerCurrent == 0) {
        this.registerStep0()
      } else if (this.registerCurrent == 1) {
        this.registerStep1()
      } else if (this.registerCurrent == 2) {
       
        if(this.miniParams.miniNameTrue && this.miniParams.miniHeadTrue && this.miniParams.miniSignatureTrue){
          this.$router.push({ path: '/' })
        }else{
          this.$refs.miniRuleForm.validate()
        }
      }
    },
    // 注册信息步骤
    async registerStep0() {
      const res = await regMini({
        api: 'customer.wx.fast.register',
        codeType: this.registerParam.codeType,
        principalName: this.registerParam.principalName,
        principalCode: this.registerParam.principalCode,
        legalPersonaWechat: this.registerParam.legalPersonaWechat,
        legalPersonaName: this.registerParam.legalPersonaName
      })
      if (res.data.code == 200) {
        this.registerCurrent = 1
      }
    },
    // 法人是否授权完成
    async registerStep1() {
      const res = await regMini({
        api: 'customer.wx.fast.status'
      })
      if (res.data.data) {
        this.registerCurrent = 2
      } else {
        this.$message.error('你还没有完成授权，请认证之后点击下一步！')
      }
    },
    // 校验小程序名称是否可用
    checkName() {
      this.$refs.miniRuleForm.validateField("miniName", (valid) => {
        if (!valid) {
          this.loading = true;
          post({
            api: "customer.wx.check.mini.name",
            nickName: this.miniParams.miniName,
          }).then((res) => {
            this.loading = false;
            if (res.data.code == 200) {
              // 名称可用直接用
              if (res.data.data == "Success") {
                this.miniNameObj.status = true;
                this.miniNameObj.showMore = false;
                this.miniNameObj.tips = "你的名字可以使用";
              } else {
                this.miniNameObj.status = false;
                this.miniNameObj.tips = res.data.data.errorMsg;
                // 需要提交资料
                if (res.data.data.isShow) {
                  this.miniNameObj.showMore = true;
                } else {
                  // 不可以用
                  this.miniNameObj.showMore = false;
                }
              }
            }
          });
        }
      });
    },
    // 提交修改名字
    setName() {
      post({
        api: "customer.wx.set.mini.name",
        nickName: this.miniParams.miniName,
        idCardMediaid:
          !this.miniNameObj.status && this.miniNameObj.showMore
            ? this.miniParams.idCardMediaid
            : "",
        licenseMediaid:
          !this.miniNameObj.status && this.miniNameObj.showMore
            ? this.miniParams.licenseMediaid
            : "",
        nickNameMediaids:
          !this.miniNameObj.status &&
          this.miniNameObj.showMore &&
          this.miniParams.nickNameMediaids.length
            ? this.miniParams.nickNameMediaids.join(",")
            : "",
      }).then((res) => {
        if (res.data.code == 200) {
          this.miniConfigActive = 1;
        }
      });
    },
    submitName() {
      this.$refs.miniRuleForm.validateField('miniName', valid => {
        if (valid === '') {
          // 判断名字是否可直接用
          if (this.miniNameObj.status) {
            // 直接修改
            this.setName();
          } else {
            // 判断是否要提交更多资料
            if (this.miniNameObj.showMore) { 
              this.$refs.miniRuleForm.validateField("licenseMediaid",(valid1)=>{
                if (valid1 == '') {
                  this.setName();
                }
              })
              
            } else {
            }
          }
        }
      })
    },
    // 限制上传大小
    beforeUpload(file) { 
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!isLt1M) {
        this.$message.error('上传头像图片大小不能超过 1MB!');
      }
      return isLt1M;
    },
    getNameFile(response, file, fileList, name) {
      if(name=='license'){
        this.miniParams.licenseMediaid = response.data
        this.miniNameObj.fileListLicense = fileList
      }else if(name=='idCard'){
        this.miniParams.idCardMediaid = response.data
        this.miniNameObj.fileListIdcard = fileList
      } else{
        this.miniParams.nickNameMediaids.push(response.data)
        this.miniNameObj.fileListFJ = fileList
      }
    },
    // 移除时
    removeNameFile(file, fileList, name) {
      if(name=='license'){
        this.miniParams.licenseMediaid = ''
        this.miniNameObj.fileListLicense = []
      }else if(name=='idCard'){
        this.miniParams.idCardMediaid = ''
        this.miniNameObj.fileListIdcard = []
      } else{
        const index = this.miniNameObj.fileListFJ.findIndex(item => item.uid === file.uid);
        this.miniParams.nickNameMediaids.splice(index, 1)
        this.miniNameObj.fileListFJ.splice(index, 1)
      }
    },
    getLogoFile(e,file) {
      this.logoFiles = file.raw;
      this.miniParams.miniHead = window.URL.createObjectURL(this.logoFiles);
      this.logoId = e.data
    },
    submitLogo(e) {
      this.loading = true
      regMini({
        headImgMediaId: this.logoId,
        x1: 0,
        x2: 1,
        y1: 0,
        y2: 1,
        api: 'customer.wx.set.mini.head'
      }).then(res1 => {
        this.loading = false
        if(res1.data.code == 200){
          // this.miniParams.miniHead = res1.data.data.head_image_url
          this.miniParams.miniHead = res1.data.data.miniHeadUrl
          this.miniConfigActive = 2
          this.$message.success(res1.data.message)
        }else{
          this.$message.error(res1.data.message)
        }
      })
    },
    submitSignature() {
      this.$refs.miniRuleForm.validateField('miniSignature', valid => {
        if (valid === '') {
          this.loading = true
          regMini({
            api: 'customer.wx.set.mini.signature',
            signature: this.miniParams.miniSignature
          }).then(res => {
            this.loading = false
            if (res.data.code == 200) {
              this.signatureTips = res.data.message
              this.miniConfigActive = 3
              this.$message.success(res.data.message)
            } else {
              this.miniParams.signature = ''
              this.signatureTips = res.data.data.msg
              this.$message.error(res.data.message)
            }
            this.signatureTipsStatus = res.data.code != 200
          })
        }
      })
    },
    submitContact() {
      var privacy = this.miniParams;
      if (
        privacy.ownerContactPhone == "" &&
        privacy.ownerContactEmail == "" &&
        privacy.ownerContactWeixin == "" &&
        privacy.ownerContactQq == ""
      ) {
        this.$message.error("4种联系方式至少要填一种");
        return false;
      }
      post({
        api: "customer.wx.code.privacy",
        ownerContactPhone: privacy.ownerContactPhone,
        ownerContactEmail: privacy.ownerContactEmail,
        ownerContactWeixin: privacy.ownerContactWeixin,
        ownerContactQq: privacy.ownerContactQq,
      }).then((res) => {
        res = res.data;
        if (res.code == 200) {
          this.$router.push("/");
        }
      });
    },
    changeMiniConfigActive(e){
      this.miniConfigActive = e
    },
    //获取隐私信息
    getWxInfo(){
      post({api: 'customer.wx.get.wx.info'}).then(res=>{
        if(res.data.code == 200){
          let privacy = JSON.parse(res.data.data.privacy)
          this.miniParams.ownerContactEmail = privacy.ownerContactEmail
          this.miniParams.ownerContactPhone = privacy.ownerContactPhone
          this.miniParams.ownerContactQq = privacy.ownerContactQq
          this.miniParams.ownerContactWeixin = privacy.ownerContactWeixin
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.reg { 
  height: 100%;
  display: flex;
  flex-direction: column;
  .box {
    background: #fff;
    box-shadow: 0 0 20px #eee;
    border-radius: 20px;
    padding: 20px;
    margin: 70px 10%;
    height: 100%;
  }
  .form {
    width: 60%;
    margin: 0 auto;
  }
  .el-steps-1 {
    margin: 100px;
  }
  .el-result {
    text-align: center;
    .title {
      font-size: 24px;
      padding: 20px 0;
    }
    .sub-title {
      font-size: 20px;
    }
  }
  .errorTip {
    text-align: center;
    font-size: 24px;
    color: #f56c6c;
    padding: 100px 0 200px;
  }
  .name-box{
    display: flex;
  }
  .step2{
    display: flex;
    margin: 0 150px;
    .el-steps--vertical{
      height: 200px;
    }
    .name-tips {
      color: #909399; 
      margin-bottom: 10px;
      &.err {
        color: red;
        line-height: 1.4;
      }
      &.suc {
        color: #67C23A;
        line-height: 1.4;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .reg {
    padding: 50px 20px;
    .el-steps {
      display: none;
    }
    .box {
      margin: 0;
    }
    .form {
      width: 100%;
    }
  }
}
</style>

<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted(){
    document.title = '全国农商互联大会管理后台'
    // document.title = '第三届“扎根基层工作、献身林草事业”林草学科优秀毕业生学习宣传活动'
  }
}
</script>


module.exports = {
  topNav: {
    username: 'username',
    version: 'version',
    changePassword: 'change password',
    basicInformation: 'basic information',
    Blue: 'Blue(default)',
    Black: 'Black',
    Green: 'Green',
    Yellow: 'Yellow',
    Red: 'Red',
    Orange: 'Orange'
  },
  route: {
    // Demo
    Demo: 'Demo',
    tableExample: 'table example',
    tableFrom: 'table from',
    complexTable: 'complex table',
    informationExample: 'information example',
    universalModule: 'universal module',

    // Goods
    Goods: 'Goods',
    good1: 'good1',
    good2: 'good2'
  },

  DemoPage: {
    tableExamplePage: {
      TAB1: 'TAB1',
      TAB2: 'TAB2',
      TAB3: 'TAB3',
      TAB4: 'TAB4',

      Select_commodity_category: 'Please select commodity category',
      Choose_a_Product_Brand: 'Please select the brand',
      Select_display_location: 'Please select display location',
      Select_Product_Type: 'Please select product type0',
      Select_commodity_status: 'Please select commodity status',
      The_input: 'Please enter the contents',
      demand: 'demand',
      reset: 'reset',
      export: 'export',
      batchDownload: 'Bulk download',
      download: 'download',

      time: 'time：',
      Select_Add_Time: 'Please select a time to add',
      state: 'state：',
      Selection_state: 'Please select status',
      Valid_time: 'Valid time:',
      Select_effective_time: 'Please select valid time',
      Menu_Name: 'Menu Name:',
      Enter_menu_name: 'Please enter a menu name',
      Commodity_brand: 'Commodity brand:',

      Add_menu: 'Add menu',
      Batch_delete: 'Batch delete',
      menu_id: 'menu ID',
      picture: 'picture',
      Goods_details: 'Goods details',
      menu_name: 'menu name',
      switch: 'switch',
      Belongs_to: 'Belongs ID',
      operation: 'operation',

      edit: 'edit',
      delete: 'delete',
      copy: 'copy',
      look_junior: 'look junior',
      more: 'more'
    },

    tableFromPage: {
      username: 'username',
      Enter_user_name: 'Enter user name',
      Active_area: 'Active area',
      Select_active_area: 'Select active area',
      Multi_category_display: 'Multi category display',
      choose: 'choose',
      superiorID: 'superior ID',
      Remote_validation_example: 'Remote validation example',
      cell_phone_number: 'cell phone number',
      Example_of_mobile_phone_number_verification: 'Example of mobile phone number verification',
      address: 'address',
      province: 'province',
      city: 'city',
      county: 'county',
      choose_time: 'choose time',
      Select_a_date: 'Select a date',
      Range_of_choice: 'Range of choice',
      Start_date: 'Start date',
      to: 'to',
      End_data: 'End data',
      Instant_delivery: 'Instant delivery',
      Nature_of_the_activities: 'Nature of the activities',
      food: 'food/Restaurant Online Activities',
      To_push_the_activity: 'To push the activity',
      Offline_Theme_Activities: 'Offline Theme  Activities',
      Pure_Brand_Exposure: 'Pure Brand Exposure',
      Special_resources: 'Special resources',
      Online_Brand_Sponsorship: 'Online Brand Sponsorship',
      Offline_venue_free: 'Offline venue free',
      Activity_form: 'Activity form',
      price: 'price',
      Keep_the_number: 'Keep the number',
      discount: 'discount',
      save: 'save',
      cancel: 'cancel',
      Upload_photo: 'Upload photo',
      phote_info: '(Upload one photo at most. It is recommended to upload a 1:1 size picture)',
      Upload_photos: 'Upload photos',
      photos_info: '(Upload a maximum of three images, it is recommended to upload 1:1 size images)'
    },

    informationExamplePage: {
      message: 'message',
      success: 'success',
      warning: 'warning',
      error: 'error',
      Popup_confirm: 'Popup confirm',
      Popup_dialog: 'Popup dialog'
    }
  },

  mechanism: {
    dataKanban: {
      pendingPayment: 'PendingPayment',
      toBeDelivered: 'ToBeDelivered',
      pendingReceipt: 'PendingReceipt',
      comment: 'PendingComment',
      returnOfGoods: 'ReturnOfGoods',
      unit: '',
      turnover: 'Turnover',
      turnoverThisWeek: 'TurnoverThisWeek',
      turnoverThisMonth: 'TurnoverThisMonth',
      cumulativeTurnover: 'TotalTurnover',
      productCategory: 'ProductCategory',
      announcement: 'Announcement',
      numberOfVisitors: 'NumberOfVisitors',
      numberOfOrder: 'NumberOfOrders',
      salesRanking: 'SalesRanking'
    }
  }
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rel" },
    [
      _c("wxthird-header"),
      _vm.isErr
        ? _c(
            "div",
            { staticClass: "box", on: { click: _vm.goto } },
            [
              _c("img", {
                attrs: {
                  src: "https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20240626/1805809300835975168.png",
                  alt: "",
                },
              }),
              _c("div", { domProps: { innerHTML: _vm._s(_vm.errorTips) } }),
              _c("el-button", { attrs: { type: "primary" } }, [
                _vm._v("下一步"),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.isAll
        ? _c("div", { staticClass: "download" }, [
            _vm._v(" 正在关联小程序，请稍后。。。"),
            _c("br"),
            _c("span", [_vm._v(_vm._s(_vm.num))]),
            _vm._v(" 秒倒计时, 请不要关闭当前页面 "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import {isEmpty} from "element-ui/src/utils/util";

export function getQueryVariable(variable) {
  var query = window.location.search.substring(1)
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] == variable) {
      return pair[1]
    }
  }
  return (false)
}

export function conver(limit) {
  var size = ''
  if (limit < 0.1 * 1024) {
    // 如果小于0.1KB转化成B
    size = limit.toFixed(2) + 'B'
  } else if (limit < 0.1 * 1024 * 1024) {
    // 如果小于0.1MB转化成KB
    size = (limit / 1024).toFixed(2) + 'KB'
  } else if (limit < 0.1 * 1024 * 1024 * 1024) {
    // 如果小于0.1GB转化成MB
    size = (limit / (1024 * 1024)).toFixed(2) + 'MB'
  } else {
    // 其他转化成GB
    size = (limit / (1024 * 1024 * 1024)).toFixed(2) + 'GB'
  }

  var sizestr = size + ''
  var len = sizestr.indexOf('.')
  var dec = sizestr.substr(len + 1, 2)
  if (dec == '00') {
    // 当小数点后为00时 去掉小数部分
    return sizestr.substring(0, len) + sizestr.substr(len + 3, 2)
  }
  return sizestr
}

// 格式化时间 年月日时分秒
export function getTime(val,format='Y-M-D H:m:s') {
  const d = new Date(val)
  const month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1
  const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate()
  const hours = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours()
  const min = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes()
  const sec = d.getSeconds() < 10 ? `0${d.getSeconds()}` : d.getSeconds()
  if(format){
    const formatList = ["Y", "M", "D", "H", "m", "s"];
    const resultList = [];
    resultList.push(d.getFullYear().toString());
    resultList.push(month);
    resultList.push(day);
    resultList.push(hours);
    resultList.push(min);
    resultList.push(sec);
    for (let i = 0; i < resultList.length; i++) {
      format = format.replace(formatList[i], resultList[i]);
    }
    return format
  }
  const times = `${d.getFullYear()}-${month}-${day} ${hours}:${min}:${sec}`
  return times
}

export function openDataBoard(name = 'homemap', feature, target = '_blank') {
  const routeInfo = this.$router.resolve({ name: name })
  feature = feature == undefined ? 'fullscreen=yes,channelmode=yes, titlebar=no, toolbar=no, ' +
    'status=no, menubar=no,top=0,left=0,width=' + (window.screen.availWidth - 10) + ',height=' + (window.screen.availHeight - 30) : feature
  window.open(routeInfo.href, target, feature)
}
export function checkPath(routes, path, parent) {
  var flag = false
  if (isEmpty(routes)) {
    flag = false
  }
  for (let i = 0; i < routes.length; i++) {
    var route = routes[i]
    var now = parent + (route.name ? '/' + route.name : '')
    if (path == now || path == route.path || path == route.redirect) {
      flag = true
      break
    }
    if (route.children) {
      flag = checkPath(route.children, path, now)
      if (flag){
        break
      }
    }
  }
  return flag
}

/**
 * 账号来源
 * @param value 来源类型
 */
export function accountFrom(value){
  if (value == 1) {
    return '小程序'
  } else if (value == 2) {
    return 'APP'
  } else if (value == 3) {
    return '支付宝小程序'
  } else if (value == 4) {
    return '头条小程序'
  } else if (value == 5) {
    return '百度小程序'
  } else if (value == 6) {
    return 'pc端'
  } else if (value == 7) {
    return 'H5移动端'
  } else if (value == 8) {
    return '人工录入'
  } else {
    return '未知'
  }
}

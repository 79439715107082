<template>
  <el-container class="dialog-container">
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <!-- <el-tab-pane v-if="(groupType==0||(groupType==1&&canEdit))&&groupId!=-2" label="上传文件" name="upload" class="upload-tab-pane">
        <div class="upload-wrap">
          <div v-if="true" style="display: flex;flex:1">
            <el-upload
              class="upload-drag"
              drag
              :action="actionUrl"
              name="file"
              multiple
              :data="uploadData"
              :show-file-list="false"
              :before-upload="handleBeforeUpload"
              :on-success="handleUploadSuccess"
              :on-change="changes"
              accept="image/*,audio/*,video/*"
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
              <div v-if="maxSize" slot="tip" class="el-upload__tip" style="text-align: center">
                最大上传文件大小：{{ maxSizeText }}
              </div>
            </el-upload>
          </div>
          <div v-if="false" style="margin:20px 0 0 20px">
            <el-button type="primary" class="select-file">
              <input id="changeInput" type="file" class="file-input" accept="" style="opacity:0" @change="handleChange">
              <div data-v-62f4c554="" class="ant-upload-text">
                <el-icon type="plus" />
                选择上传图片
              </div>
            </el-button>
            <div class="vue-cropper" :style="{'width':imgWidth + 'px', 'height':imgHeight + 'px', zoom: 0.5}">
              <vue-cropper ref="cropper" mode="cover" :auto-crop="true" :img="option.img" :output-size="option.size" :output-type="option.outputType" :auto-crop-width="imgWidth" :auto-crop-height="imgHeight" :fixed-box="isFixedBox" :can-move-box="false" />
            </div>
            <div>
              <el-button type="primary" @click="toUpfile">
                <i class="el-icon-upload el-icon--right" />  上传
              </el-button>
            </div>
            <div style="line-height: 1.4;padding-top:10px">请在预览框内调整图片，最终将生成尺寸为{{ imgWidth }}×{{ imgHeight }}的图片</div>
          </div>

        </div>
        <div class="gap" />
        <dialog-container-footer />
      </el-tab-pane> -->
      <div  class="file-list-tab-pane">
        <div class="file-list">
          <div class="file-list-left">
            <el-form ref="ruleForm2" class="file-info-form" :model="ruleForm2" :rules="rules2">
              <el-row class="file-list-left-header" type="flex">
                <el-col :span="4" class="all-date">
                  <el-date-picker v-model="ruleForm2.time" type="month" placeholder="全部日期" @change="onTimeChange" />
                </el-col>

                <el-col :span="5">
                  <el-input v-model="ruleForm2.title" placeholder="搜索文件项目" @blur="queryFileList" />
                </el-col>
              </el-row>
            </el-form>

            <ul v-infinite-scroll="load"  class="infinite-list" style="overflow: auto" >
              <li v-for="(item, index) of list" :key="index" :class="{ active: checkedKey === index }" @click="onHandleClickFileLi(index,item)">
                <img :src="item.url" alt="">
                <el-checkbox v-if="item.checked" v-model="item.checked" class="infinite-list-checked" />
              </li>
              <!--              <li v-for="i in count" class="infinite-list-item">{{ i }}</li>-->
            </ul>
          </div>

          <div v-if="canEdit"  class="file-list-right">
            <template v-if="imageInfo">
              <h4>
                附件详情
              </h4>
              <p>{{ imageInfo.image_name }}</p>
              <div class="image-info">
                <img class="image" :src="imageInfo.url">
                <div class="image-info-content">
                  <p>{{ imageInfo.add_time | dateFormat1 }}</p>
                  <p>{{ imageInfo.size }}</p>
                  <p>{{ imageInfo.widthAndHeight }}</p>
                  <div v-if="imageInfo.groupName">
                    <el-link type="primary" :underline="false" @click="onMoveClassify(imageInfo.id)">移出{{ imageInfo.groupName }}</el-link>
                  </div>
                  <div>
                    <el-link type="danger" :underline="false" @click="onDeleteFile(imageInfo.id)">永久删除</el-link>
                  </div>
                </div>
              </div>

              <el-form ref="ruleForm" class="file-info-form" :model="ruleForm" :rules="rules" label-position="top">
                <el-form-item label="URL" prop="url">
                  <el-input v-model="ruleForm.url" :disabled="true" />
                </el-form-item>
                <el-form-item label="标题" prop="title">
                  <el-input v-model="ruleForm.title" />
                </el-form-item>
                <el-form-item label="说明" prop="explain">
                  <el-input v-model="ruleForm.explain" />
                </el-form-item>

                <el-form-item label="代替文本" prop="alternative_text">
                  <el-input v-model="ruleForm.alternative_text" />
                </el-form-item>
                <el-form-item label="图像描述" prop="describe">
                  <el-input v-model="ruleForm.describe" type="textarea" :rows="2" />
                </el-form-item>
              </el-form>
            </template>
          </div>
        </div>

        <dialog-container-footer
          :groupId="groupId*1"
          :list="list"
          :group-type="groupType*1"
          :checked-key="checkedKey"
          :can-edit="canEdit"
          @move="queryFileList"
          @clear="onHandleClear"
          @add="onHandleAdd"
          @onUploadSuccess="onUploadSuccess"
          @getNewList="queryFileList"
        />
      </div>
    </el-tabs>
  </el-container>
</template>

<script>
import dialogContainerFooter from './dialog-container-footer'
import Config from '../../apis/Config'
import Upload from '../../apis/Upload'
import moment from 'moment'
import { VueCropper } from 'vue-cropper'
import { conver } from '../../utils/utils'
import { getStorage } from '@/utils/storage'
import log from '@/libs/util.log'
import axios from 'axios'
const api = new Upload()

const actionUrl = Config.baseUrl

export default {
  name: 'DialogContainer',
  inject: ['indexCom'],
  components: { dialogContainerFooter, VueCropper },
  props: {
    groupId: {
      type: Number || String
    },
    groupType: {
      type: Number
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    groupList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      isFixedBox: true,
      option: {
        img: '',
        size: 0,
        outputType: 'png'
      },
      imgWidth: 690,
      imgHeight: 350,
      activeName: 'library', // upload library
      actionUrl,
      list: [],
      page: 0,
      pageSize: 30,
      checkedKey: null,
      hasMore: true,
      ruleForm: {
        url: '',
        title: '',
        explain: '',
        alternative_text: '',
        describe: ''
      },
      rules: {},
      ruleForm2: {
        time: '',
        title: '',
        start_time: '',
        end_time: ''
      },
      rules2: {},
      imageName: ''
    }
  },
  computed: {
    uploadData() {
      {
        return {
          api: 'resources.file.uploadFiles',
          storeId: this.$route.path.split('/')[1] == 'Platform' ? 0 : getStorage('laike_admin_userInfo').storeId,
          groupId: this.groupId,
          // mchId: getStorage('laike_admin_userInfo').mchId,
          uploadType: 2,
          accessId: this.$store.getters.token,
          type: this.groupType
        }
      }
    },
    maxSelectNum() {
      return this.indexCom.maxSelectNum
    },
    limit() {
      return this.indexCom.limit
    },
    maxSize() {
      return this.indexCom.maxSize
    },
    maxSizeText() {
      return conver(this.indexCom.maxSize)
    }
  },
  asyncComputed: {
    // 异步获取图片信息
    async imageInfo() {
      if (this.checkedKey !== null) {
        if (this.list[this.checkedKey].url) {
          const res = await fetch(this.list[this.checkedKey].url).catch(err => {
            return {
              id: this.list[this.checkedKey].id,
              image_name: this.list[this.checkedKey].image_name,
              url: this.list[this.checkedKey].url,
              add_time: this.list[this.checkedKey].add_time
            }
          })
          if (res && res.status == 200) {
            const data = await res.blob()
            const size = Math.ceil(data.size / 1024)
            const { width, height } = await this.getMeta(
              this.list[this.checkedKey].url
            )
            return {
              id: this.list[this.checkedKey].id,
              image_name: this.list[this.checkedKey].image_name,
              url: this.list[this.checkedKey].url,
              add_time: this.list[this.checkedKey].add_time,
              size: `${size}kb`,
              widthAndHeight: `${width} x ${height}`
            }
          } else {
            return {
              id: this.list[this.checkedKey].id,
              image_name: this.list[this.checkedKey].image_name,
              url: this.list[this.checkedKey].url,
              add_time: this.list[this.checkedKey].add_time
            }
          }
        } else {
          return {
            id: this.list[this.checkedKey].id,
            image_name: this.list[this.checkedKey].image_name,
            url: this.list[this.checkedKey].url,
            add_time: this.list[this.checkedKey].add_time
          }
        }
      } else {
        return false
      }
    }
  },
  watch: {
    activeName(val) {
      if (val === 'library') {
        this.empty()
        this.list = []
        this.getFileList()
      } else {
      }
    },
    async groupId(v) {
      this.empty()
      if (v == -2) {
        this.list = []
        this.hasMore = false
      } else {
        await this.getFileList()
      }
    },
    groupType(v) {
    },
    checkedKey(val) {
      if (val !== null) {
        this.$set(this.ruleForm, 'url', this.list[this.checkedKey].url)
        this.$set(this.ruleForm, 'id', this.list[this.checkedKey].id)
        this.$set(this.ruleForm, 'title', this.list[this.checkedKey].title)
        this.$set(this.ruleForm, 'explain', this.list[this.checkedKey].explain)
        this.$set(
          this.ruleForm,
          'alternative_text',
          this.list[this.checkedKey].alternative_text
        )
        this.$set(
          this.ruleForm,
          'describe',
          this.list[this.checkedKey].describe
        )
      } else {
      }
    }
  },
  mounted() {
    // this.getFileList();
  },
  methods: {
    toUpfile() {
      if (!this.option.img) {
        this.$message.error('请先选择图片/文件再上传!')
        return
      }
      this.$refs.cropper.getCropData(data => {
        // do something
        const file = this.dataURLtoFile(data, this.imageName)
        var formData = new FormData()
        formData.append('file', file) // 第一个file 后台接收的参数名
        axios({
          url: this.actionUrl, // 上传路径
          method: 'POST',
          params: {
            api: 'resources.file.uploadFiles',
            storeId: this.$route.path.split('/')[1] == 'Platform' ? 0 : getStorage('laike_admin_userInfo').storeId,
            groupId: this.groupId,
            // mchId: getStorage('laike_admin_userInfo').mchId,
            uploadType: 2,
            accessId: this.$store.getters.token
          },
          data: formData
        }).then(res => {
          if (res.data.code == '200') {
            this.option.img = ''
            this.activeName = 'library'
          }
        }).catch(err => {
        })
      })
    },
    // 将base64转换为file格式
    dataURLtoFile(dataurl, filename) {
      // 将base64转换为文件
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1]); var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    handleChange(e) {
      const file = e.target.files[0]
      this.imageName = file.name
      // 预览添加到图片裁剪
      const fileReader = new FileReader()
      const _this = this
      fileReader.onloadend = function() {
        // 此处判断可以不用加，因为fileReader.readAsDataURL()读取成功之后，readyState == DONE 的时候才会调用onloadend方法
        // readyState和DONE都在 new FileReader()实例中，不明白可打印new FileReader()
        if (fileReader.readyState === fileReader.DONE) {
          _this.option.img = fileReader.result
        }
      }
      fileReader.readAsDataURL(file)
    },
    async onHandleAdd() {
      const checkedList = this.list.filter(item => item.checked)
      if (checkedList.length > this.maxSelectNum) {
        this.$message.error('超过最大所选数量')
        return false
      }

      if (this.limit === 1) {
        this.$emit('getPic', {
          url: this.list.filter(item => item.checked)[0].url
        })
      } else {
        this.$emit(
          'getPicD',
          this.list.filter(item => item.checked)
        )
      }
      await api.modifyInfo({
        id: this.list[this.checkedKey].id,
        groupId: this.groupId,
        title: this.ruleForm.title,
        explain: this.ruleForm.explain,
        alternative_text: this.ruleForm.alternative_text,
        describe: this.ruleForm.describe
      }).then(res => {
      })
    },
    onHandleClear() {
      this.checkedKey = null
      this.list = this.list.map(item => {
        item.checked = false
        return item
      })
    },
    onUploadSuccess() {
      // this.list.unshift(img)
    },
    queryFileList() {
      this.list = []
      this.page = 1
      this.checkedKey = null
      this.getFileList()
    },
    onTimeChange(time) {
      const startTime = moment(time)
      const endTime = moment(time)
        .add(1, 'M')
        .subtract(1, 'd')

      this.$set(this.ruleForm2, 'start_time', startTime.format('YYYY-MM-DD'))
      this.$set(this.ruleForm2, 'end_time', endTime.format('YYYY-MM-DD'))
      this.queryFileList()
    },
    async editFileInfo() {
      this.$set(this.list[this.checkedKey], 'title', this.ruleForm.title)
      this.$set(this.list[this.checkedKey], 'explain', this.ruleForm.explain)
      this.$set(
        this.list[this.checkedKey],
        'alternative_text',
        this.ruleForm.alternative_text
      )
      this.$set(this.list[this.checkedKey], 'describe', this.ruleForm.describe)

      const res = await api.editFileInfo(this.ruleForm)
    },
    // 移出分类
    async onMoveClassify(id) {
      await api.moveClassify(-1, [
        {
          id: id
        }
      ])
      this.$message.success('移动成功')
      this.empty()
      await this.getFileList()
    },
    // 初始化清空数据
    empty() {
      this.list = []
      this.page = 1
      this.pageSize = 30
      this.$refs.ruleForm2.resetFields()
      this.checkedKey = null
    },
    // 永久删除图片
    async onDeleteFile(id) {
      await api.deleteFile(id).then(res => {
        if (res.data.code == '200') {
          this.$message.success('删除成功')
          this.empty()
          this.getFileList()
        } else {

        }
      })
    },
    /**
     * 获取分类名称
     * @returns {Promise<null|*>}
     */
    async getClassifyName() {
      api.getClassify().then(res => {
        const key = res.data.list.findIndex(item => item.id == this.groupId)
        if (res.data.list[key].id === -1) {
          // 全部商品不显示移除分类按钮
          return null
        }
        return res.data.list[key].name
      })
    },
    async getMeta(url) {
      const img = new Image()
      img.src = url
      return new Promise(resolve => {
        img.onload = function() {
          return resolve({
            width: this.width,
            height: this.height
          })
        }
      })
    },
    onHandleClickFileLi(index, item) {
      const checkedList = this.list.filter(item => item.checked)
      if (
        checkedList.length >= this.maxSelectNum &&
        !this.list[index].checked &&
        this.limit !== 1
      ) {
        this.$message.error('超过最大所选数量')
        return false
      }
      if (this.limit === 1) {
        this.list = this.list.map(item => {
          item.checked = false
          return item
        })
        this.list[index].checked = true
      } else {
        this.list[index].checked = !this.list[index].checked
      }

      this.checkedKey = index
    },
    load() {
      console.log('执行下拉加载')
      // debugger
      if (this.hasMore) {
        setTimeout(()=>{
          this.page++
          this.getFileList()
        },500)

      }
    },
    handleClick() { },
    changes() {
    },
    // 上传成功
    handleUploadSuccess(res) {
      this.activeName = 'library'
    },
    // 上传之前的处理
    handleBeforeUpload(file) {
      // console.log(this.maxSize)
      // console.log(file.size)
      // if (file.size > 5242880) {
      //   this.$message.error("超出最大上传大小，图片不能大于5MB");
      //   return false;
      // }
      const isLt3M = file.size / 1024 / 1024 < 3
      if (!isLt3M) {
        return this.$message.error('上传图片大小不能超过 3MB!')
      }
      // if (this.maxSize) {
      //   if (file.size > this.maxSize) {
      //     this.$message.error("超出最大上传大小");
      //     return false;
      //   }
      // }
    },
    // 获取文件列表
    async getFileList() {
      const loading = this.$loading({
          lock: true,
          text: '加载中...',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      const that = this
      await api.getFileList({
        type: this.groupType,
        page: this.page,
        pageSize: this.pageSize,
        pid: this.groupId,
        start_time: this.ruleForm2.start_time,
        end_time: this.ruleForm2.end_time,
        title: this.ruleForm2.title,
        storeId: that.$route.path.split('/')[1] == 'Platform' ? 0 : getStorage('laike_admin_userInfo').storeId
      }).then(res => {
        if (res.data.code === '200') {
          res.data.data.list = res.data.data.list.map(item => {
            item['checked'] = false
            return item
          })
          that.list = this.page == 1 ? res.data.data.list : that.list.concat(res.data.data.list)
          if (res.data.data.total > that.list.length) {
            this.hasMore = true
          } else {
            this.hasMore = false
          }
        } else {
          this.hasMore = false
        }
        loading.close()
      })
    }
  }
}
</script>

<style scoped lang="less">
@import "../style/dialog-container.less";
</style>

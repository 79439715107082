<template>
  <el-breadcrumb class="app-breadcrumb">
    <transition-group id="breadcrumb" name="breadcrumb">
      <el-breadcrumb-item v-for="(item,index) in newList" :key="index">
        <span v-if="item.redirect==='noRedirect'||index==newList.length-1" class="no-redirect">{{ generateTitle(item.meta.title) }}</span>
        <a v-else @click.prevent="handleLink(item)">{{ generateTitle(item.meta.title) }}</a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
import pathToRegexp from 'path-to-regexp'
export default {
  data() {
    return {
      levelList: null,
      newList: []
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb()
    }
  },
  created() {
    this.getBreadcrumb()
  },
  methods: {
    getBreadcrumb() {
      const matched = this.$route.matched.filter(item => item.meta && item.meta.title)
      const first = matched[0]

      this.levelList = matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false)
      this.newList = []
      const redirect = this.levelList[1].redirect
      this.levelList.filter(item => {
        this.newList.push(item)
      })
      if (this.newList.length == 3 && this.newList[1].meta.title == this.newList[2].meta.title && this.newList[2].meta.title !== '订单列表') {
        this.newList.pop()
      }
      if (this.newList[0].meta.title == '用户' && this.newList[1].meta.title !== '用户列表') {
        const arr = {
          meta: {
            title: '用户列表'
          },
          redirect: '/members/membersList'
        }
        this.newList.splice(1, 0, arr)

        if (this.newList[2].meta.title == '添加用户等级' || this.newList[2].meta.title == '编辑用户等级') {
          const arrs = {
            meta: {
              title: '用户等级'
            },
            redirect: '/members/membersLevel'
          }
          this.newList.splice(2, 0, arrs)
        }
      }

      if (this.newList[1].meta.title == '秒杀') {
        if (this.newList[2].meta.title == '添加商品' || this.newList[2].meta.title == '商品列表') {
          const arrs = {
            meta: {
              title: '秒杀标签'
            },
            redirect: '/plug_ins/seckill/seckillLabel'
          }
          this.newList.splice(2, 0, arrs)
        }

        if (this.newList[2].meta.title == '编辑' || this.newList[2].meta.title == '查看商品') {
          const arrs1 = {
            meta: {
              title: '秒杀标签'
            },
            redirect: '/plug_ins/seckill/seckillLabel'
          }
          const arrs2 = {
            meta: {
              title: '商品列表'
            },
            redirect: '/plug_ins/seckill/goodsList'
          }
          this.newList.splice(2, 0, arrs1)
          this.newList.splice(3, 0, arrs2)
        }

        if (this.newList[2].meta.title == '售后管理') {
          const arrs = {
            meta: {
              title: '秒杀订单'
            },
            redirect: '/plug_ins/seckill/seckillOrder'
          }

          this.newList.splice(2, 0, arrs)
        }
        if (this.newList[2].meta.title == '售后详情') {
          const arrs1 = {
            meta: {
              title: '秒杀订单'
            },
            redirect: '/plug_ins/seckill/seckillOrder'
          }
          const arrs2 = {
            meta: {
              title: '售后管理'
            },
            redirect: '/plug_ins/seckill/afterSaleList'
          }

          this.newList.splice(2, 0, arrs1)
          this.newList.splice(3, 0, arrs2)
        }

        if (this.newList[2].meta.title == '订单结算') {
          const arrs = {
            meta: {
              title: '秒杀订单'
            },
            redirect: '/plug_ins/seckill/seckillOrder'
          }

          this.newList.splice(2, 0, arrs)
        }
        if (this.newList[2].meta.title == '查看') {
          const arrs1 = {
            meta: {
              title: '秒杀订单'
            },
            redirect: '/plug_ins/seckill/seckillOrder'
          }
          const arrs2 = {
            meta: {
              title: '订单结算'
            },
            redirect: '/plug_ins/seckill/orderSettlementList'
          }

          this.newList.splice(2, 0, arrs1)
          this.newList.splice(3, 0, arrs2)
        }

        if (this.newList[2].meta.title == '评价管理') {
          const arrs = {
            meta: {
              title: '秒杀订单'
            },
            redirect: '/plug_ins/seckill/seckillOrder'
          }

          this.newList.splice(2, 0, arrs)
        }
        if (this.newList[2].meta.title == '修改') {
          const arrs1 = {
            meta: {
              title: '秒杀订单'
            },
            redirect: '/plug_ins/seckill/seckillOrder'
          }
          const arrs2 = {
            meta: {
              title: '评价管理'
            },
            redirect: '/plug_ins/seckill/commentList'
          }

          this.newList.splice(2, 0, arrs1)
          this.newList.splice(3, 0, arrs2)
        }

        if (this.newList[2].meta.title == '订单详情' || this.newList[2].meta.title == '商品发货' || this.newList[2].meta.title == '编辑订单') {
          const arrs = {
            meta: {
              title: '秒杀订单'
            },
            redirect: '/plug_ins/seckill/seckillOrder'
          }

          this.newList.splice(2, 0, arrs)
        }
      }

      if (this.newList[1].meta.title == '积分商城') {
        if (this.newList[2].meta.title == '添加商品' || this.newList[2].meta.title == '编辑') {
          const arrs = {
            meta: {
              title: '积分商品'
            },
            redirect: '/plug_ins/integralMall/integralGoodsList'
          }
          this.newList.splice(2, 0, arrs)
        }

        if (this.newList[2].meta.title == '售后管理') {
          const arrs = {
            meta: {
              title: '兑换订单'
            },
            redirect: '/plug_ins/integralMall/integralOrder'
          }

          this.newList.splice(2, 0, arrs)
        }
        if (this.newList[2].meta.title == '售后详情') {
          const arrs1 = {
            meta: {
              title: '兑换订单'
            },
            redirect: '/plug_ins/integralMall/integralOrder'
          }
          const arrs2 = {
            meta: {
              title: '售后管理'
            },
            redirect: '/plug_ins/integralMall/afterSaleList'
          }

          this.newList.splice(2, 0, arrs1)
          this.newList.splice(3, 0, arrs2)
        }

        if (this.newList[2].meta.title == '订单结算') {
          const arrs = {
            meta: {
              title: '兑换订单'
            },
            redirect: '/plug_ins/integralMall/integralOrder'
          }

          this.newList.splice(2, 0, arrs)
        }
        if (this.newList[2].meta.title == '查看') {
          const arrs1 = {
            meta: {
              title: '兑换订单'
            },
            redirect: '/plug_ins/integralMall/integralOrder'
          }
          const arrs2 = {
            meta: {
              title: '订单结算'
            },
            redirect: '/plug_ins/integralMall/orderSettlementList'
          }

          this.newList.splice(2, 0, arrs1)
          this.newList.splice(3, 0, arrs2)
        }

        if (this.newList[2].meta.title == '评价管理') {
          const arrs = {
            meta: {
              title: '兑换订单'
            },
            redirect: '/plug_ins/integralMall/integralOrder'
          }

          this.newList.splice(2, 0, arrs)
        }
        if (this.newList[2].meta.title == '修改') {
          const arrs1 = {
            meta: {
              title: '兑换订单'
            },
            redirect: '/plug_ins/integralMall/integralOrder'
          }
          const arrs2 = {
            meta: {
              title: '评价管理'
            },
            redirect: '/plug_ins/integralMall/commentList'
          }

          this.newList.splice(2, 0, arrs1)
          this.newList.splice(3, 0, arrs2)
        }

        if (this.newList[2].meta.title == '订单详情' || this.newList[2].meta.title == '商品发货' || this.newList[2].meta.title == '编辑订单') {
          const arrs = {
            meta: {
              title: '兑换订单'
            },
            redirect: '/plug_ins/integralMall/integralOrder'
          }

          this.newList.splice(2, 0, arrs)
        }
      }

      if (this.newList[1].meta.title == '分销') {
        if (this.newList[2].meta.title == '查看下级' || this.newList[2].meta.title == '编辑') {
          const arrs = {
            meta: {
              title: '分销商列表'
            },
            redirect: '/plug_ins/distribution/distributorsList'
          }
          this.newList.splice(2, 0, arrs)
        }
        if (this.newList[2].meta.title == '添加分销等级' || this.newList[2].meta.title == '编辑分销等级') {
          const arrs = {
            meta: {
              title: '分销等级'
            },
            redirect: '/plug_ins/distribution/distributionLevel'
          }
          this.newList.splice(2, 0, arrs)
        }
        if (this.newList[2].meta.title == '添加商品' || this.newList[2].meta.title == '编辑商品') {
          const arrs = {
            meta: {
              title: '分销商品'
            },
            redirect: '/plug_ins/distribution/distributionGoods'
          }
          this.newList.splice(2, 0, arrs)
        }
      }

      // 预售
      if (this.newList[1].meta.title == '商品预售') {
        if (this.newList[2].meta.title == '发布商品' || this.newList[2].meta.title == '编辑商品' || this.newList[2].meta.title == '查看详情') {
          const arrs = {
            meta: {
              title: '商品列表'
            },
            redirect: '/plug_ins/preSale/goodsList'
          }
          this.newList.splice(2, 0, arrs)
        }

        if (this.newList[2].meta.title == '售后管理') {
          const arrs = {
            meta: {
              title: '预售订单'
            },
            redirect: '/plug_ins/preSale/preSaleOrder'
          }

          this.newList.splice(2, 0, arrs)
        }
        if (this.newList[2].meta.title == '售后详情') {
          const arrs1 = {
            meta: {
              title: '预售订单'
            },
            redirect: '/plug_ins/preSale/preSaleOrder'
          }
          const arrs2 = {
            meta: {
              title: '售后管理'
            },
            redirect: '/plug_ins/preSale/afterSaleList'
          }

          this.newList.splice(2, 0, arrs1)
          this.newList.splice(3, 0, arrs2)
        }

        if (this.newList[2].meta.title == '订单结算') {
          const arrs = {
            meta: {
              title: '预售订单'
            },
            redirect: '/plug_ins/preSale/preSaleOrder'
          }

          this.newList.splice(2, 0, arrs)
        }

        if (this.newList[2].meta.title == '查看') {
          const arrs1 = {
            meta: {
              title: '预售订单'
            },
            redirect: '/plug_ins/preSale/preSaleOrder'
          }
          const arrs2 = {
            meta: {
              title: '订单结算'
            },
            redirect: '/plug_ins/preSale/orderSettlementList'
          }

          this.newList.splice(2, 0, arrs1)
          this.newList.splice(3, 0, arrs2)
        }

        if (this.newList[2].meta.title == '评价管理') {
          const arrs = {
            meta: {
              title: '预售订单'
            },
            redirect: '/plug_ins/preSale/preSaleOrder'
          }

          this.newList.splice(2, 0, arrs)
        }
        if (this.newList[2].meta.title == '修改') {
          const arrs1 = {
            meta: {
              title: '预售订单'
            },
            redirect: '/plug_ins/preSale/preSaleOrder'
          }
          const arrs2 = {
            meta: {
              title: '评价管理'
            },
            redirect: '/plug_ins/preSale/evaluateList'
          }

          this.newList.splice(2, 0, arrs1)
          this.newList.splice(3, 0, arrs2)
        }

        if (this.newList[2].meta.title == '订单详情' || this.newList[2].meta.title == '商品发货' || this.newList[2].meta.title == '编辑订单' || this.newList[2].meta.title == '查看物流') {
          const arrs = {
            meta: {
              title: '预售订单'
            },
            redirect: '/plug_ins/preSale/preSaleOrder'
          }

          this.newList.splice(2, 0, arrs)
        }
      }
    },
    isDashboard(route) {
      const name = route && route.name
      if (!name) {
        return false
      }
      return name.trim().toLocaleLowerCase() === 'Dashboard'.toLocaleLowerCase()
    },
    pathCompile(path) {
      const { params } = this.$route
      var toPath = pathToRegexp.compile(path)
      return toPath(params)
    },
    handleLink(item) {
      const { redirect, path } = item
      if (item.meta.returns || item.returns) {
        this.$router.go(-1)
      } else {
        if (redirect) {
          this.$router.push(redirect)
          return
        }
        this.$router.push(this.pathCompile(path))
      }
      console.log(redirect)
    },

    generateTitle(title) {
      const hasKey = this.$te('route.' + title)
      if (hasKey) {
        const translatedTitle = this.$t('route.' + title)
        return translatedTitle
      }
      return title
    }
  }
}
</script>

<style lang="scss" scoped>
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 18px;
  line-height: 60px;
  margin-left: 20px;

  .no-redirect {
    color: #414658;
    cursor: text;
  }

  a {
    color: #414658;
  }

  a:hover {
    color: #06c377;
  }

  span {
    color: #6a7076 !important;
  }
}
@media screen and (max-width: 768px) {
  .app-breadcrumb.el-breadcrumb {
    font-size: 14px;
    line-height: 40px;
  }
}
</style>

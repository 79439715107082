import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // 进度条
import 'nprogress/nprogress.css' // 进度条样式
import getPageTitle from '@/utils/get-page-title'
import { getStorage } from '@/utils/storage'
import { RouterLinkStub } from '@vue/test-utils'
import { checkPath } from '@/utils/utils'

const userInfo = getStorage('laike_admin_userInfo')
NProgress.configure({ showSpinner: false })

const whiteList = ['/login','/poster'] // 没有重定向白名单

router.beforeEach(async(to, from, next) => {
  console.log('to：', to)
  // 开始进度条
  NProgress.start()

  // 设置页面标题
  document.title = getPageTitle(to.meta.title)
  if (to.path === '/formRender') {
    next()
    NProgress.done()
  } else if (getStorage('laike_admin_userInfo')) {
    if (to.path === '/login') {
      // 如果已经登录, 重定向到首页
      next({ path: '/' })
      NProgress.done()
    } else if (to.path === '/wxthird/reg' || to.path === '/wxthird/rel' || to.path === '/wxthird/wpp') {
      next()
      NProgress.done()
    } else {
      const name = store.getters.name
      if (getStorage('rolesInfo') && name) {
        // next()
        // 判断路由是否存在目标页面，如不存在则提示，防止打开后空白
        var have = checkPath(store.state.permission.routes, to.path, '')
        if (have) {
          next()
        } else {
          Message.warning('当前页面无权限')
        }
      } else {
        try {
          await store.dispatch('user/getAuthorizationList')
          if (getStorage('laike_head_img')) {
            store.commit('user/SET_MERCHANTSLOGO', getStorage('laike_head_img'))
          }
          const userInfo = getStorage('laike_admin_userInfo')
          const { name } = userInfo
          store.commit('user/SET_NAME', name)
          // if (getStorage('rolesInfo').type == 0) {
          //   store.dispatch('user/setUserAdmin')
          // }

          // const accessRoutes = await store.dispatch('permission/getAsyncRoutes')
          // let list = []
          // if(accessRoutes.some(item => {
          //   return item.name == 'home'
          // })) {
          //   for(let i=0; i<accessRoutes.length; i++) {
          //     if(accessRoutes[i].name == 'home') {
          //       list.push(accessRoutes[i])
          //       break
          //     }
          //   }
          // } else {
          //   list.push(accessRoutes[0])
          // }
          // router.addRoutes(list)

          const accessRoutes = await store.dispatch('permission/getAsyncRoutes')
          // 如果无法拿到异步路由
          // if (!accessRoutes) {
          //   console.error('无法拿取异步路由')
          //   next('/404')
          // }
          router.addRoutes(accessRoutes)
          console.log('--------------------',accessRoutes)
          store.dispatch('source/getSource')
          store.dispatch('orderNum/getOrderCount')
          // store.dispatch('orderNum/getOrderSecCount')
          // store.dispatch('orderNum/getOrderInCount')

          // TODO 待优化（是否必须在这里加载） 这里加载内容为所需上传的图片尺寸
          store.dispatch('imgSize/getCurrentTemplate')

          console.log(store.state.permission.addRoutes)
          console.log('routes:', store.state.permission.routes)
          if (store.state.permission.addRoutes.some(item => {
            return item.name == 'home'
          })) {
            next({ ...to, replace: true })
          } else {
            // 注释掉该行代码，解决页面刷新跳转首页问题
            // const path = store.state.permission.addRoutes[0].redirect
            // const path = to.path === '/home' ? store.state.permission.addRoutes[0].redirect : to.path
            // next(path)
            if (to.path === '/home') {
              next(store.state.permission.addRoutes[0].redirect)
            } else {
              // 判断路由是否存在目标页面，解决更改权限后刷新页面不存在导致的空白
              var check = checkPath(store.state.permission.routes, to.path, '')
              if (check) {
                next({ ...to, replace: true })
              } else {
                Message.warning('当前页面无权限，已跳转到其他页面')
                next(store.state.permission.addRoutes[0].redirect)
              }
            }
          }
        } catch (error) {
          console.log('异常')
          console.log(error)
          // 删除token并转到登录页面重新登录
          await store.dispatch('user/resetToken')
          // Message.error(error || 'Has Error')
          next(`/login?redirect=${to.path}`)
          NProgress.done()
        }
      }
    }
  } else {
    /* 没有token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // 直接去免登录白名单
      next()
    } else {
      // 其他没有访问权限的页面被重定向到登录页面。
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})

const state = {
    showCropper: false,
    cropperWidth: 0,
    cropperHeight: 0,
    cropperImg: '',
    cropperImgName: '',
}
const mutations = {
    TOGGLE_CROPPER: (state, obj) => {
        state.showCropper = obj.show
        state.cropperImg = obj.img
        state.cropperImgName = obj.name
    },
    SET_SIZE:(state, val)=>{
        state.cropperHeight = val.cropperHeight
        state.cropperWidth = val.cropperWidth
    }
}

export default {
    namespaced: true,
    state,
    mutations
}
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "l-upload" },
    [
      _vm._l(_vm.fileList, function (url, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "img-item",
            style: {
              width: _vm.size + "px",
              height: _vm.size + "px",
              marginRight: _vm.marginRight,
            },
          },
          [
            _c("img", {
              style: { width: _vm.size + "px", height: _vm.size + "px" },
              attrs: { src: url, alt: "" },
            }),
            !_vm.disabled
              ? _c("div", { staticClass: "actions" }, [
                  _c("i", {
                    staticClass: "el-icon-delete",
                    on: {
                      click: function ($event) {
                        return _vm.removeImg(index)
                      },
                    },
                  }),
                  index != 0 && _vm.mainImg
                    ? _c(
                        "i",
                        {
                          staticClass: "set-main",
                          on: {
                            click: function ($event) {
                              return _vm.setMain(index)
                            },
                          },
                        },
                        [_vm._v("设为主图")]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            index == 0 && _vm.mainImg
              ? _c("i", { staticClass: "img-main" }, [_vm._v("主图")])
              : _vm._e(),
          ]
        )
      }),
      _vm.fileList.length < _vm.limit
        ? _c(
            "div",
            {
              staticClass: "upload",
              style: {
                width: _vm.size + "px",
                height: _vm.size + "px",
                marginRight: _vm.marginRight,
              },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = true
                },
              },
            },
            [_c("i", { staticClass: "el-icon-plus" })]
          )
        : _vm._e(),
      _c("div", { staticClass: "desc" }, [
        _vm._v(" " + _vm._s(_vm.text) + " "),
      ]),
      _vm.dialogVisible
        ? _c("uDialog", {
            attrs: { "group-type": _vm.groupType, "group-id": _vm.groupId },
            on: {
              getPicD: _vm.handleGetPicD,
              getPic: _vm.handleGetPic,
              close: _vm.close,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
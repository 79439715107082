var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "student-form-box" }, [
    _c("div", { staticClass: "sign-out", on: { click: _vm.signOut } }, [
      _c("img", {
        attrs: {
          src: "https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/195/1/20230317/1636683116472623104.png",
        },
      }),
      _c("span", [_vm._v("退出")]),
    ]),
    _vm._m(0),
    _c(
      "div",
      { staticClass: "student" },
      [
        _c(
          "el-form",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            ref: "ruleForm",
            attrs: {
              model: _vm.studentForm,
              "label-width": "230px",
              "label-position": "right",
            },
          },
          [
            _c("div", { staticClass: "item" }, [
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "姓名",
                        prop: "name",
                        rules: [
                          {
                            required: true,
                            message: "请输入姓名",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.isDisabled },
                        model: {
                          value: _vm.studentForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.studentForm, "name", $$v)
                          },
                          expression: "studentForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "性别",
                        prop: "gender",
                        rules: [
                          {
                            required: true,
                            message: "请选择性别",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: _vm.isDisabled },
                          model: {
                            value: _vm.studentForm.gender,
                            callback: function ($$v) {
                              _vm.$set(_vm.studentForm, "gender", $$v)
                            },
                            expression: "studentForm.gender",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("男"),
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("女"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "民族",
                        prop: "nation",
                        rules: [
                          {
                            required: true,
                            message: "请输入民族",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.isDisabled },
                        model: {
                          value: _vm.studentForm.nation,
                          callback: function ($$v) {
                            _vm.$set(_vm.studentForm, "nation", $$v)
                          },
                          expression: "studentForm.nation",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "出生年月",
                        prop: "date_of_birth",
                        rules: [
                          {
                            required: true,
                            message: "请选择出生年月",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          disabled: _vm.isDisabled,
                          type: "date",
                          "value-format": "yyyy-MM-dd",
                          placeholder: "选择日期",
                        },
                        model: {
                          value: _vm.studentForm.date_of_birth,
                          callback: function ($$v) {
                            _vm.$set(_vm.studentForm, "date_of_birth", $$v)
                          },
                          expression: "studentForm.date_of_birth",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "政治面貌",
                        prop: "politics_status",
                        rules: [
                          {
                            required: true,
                            message: "请输入政治面貌",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.isDisabled },
                        model: {
                          value: _vm.studentForm.politics_status,
                          callback: function ($$v) {
                            _vm.$set(_vm.studentForm, "politics_status", $$v)
                          },
                          expression: "studentForm.politics_status",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "最高学历",
                        prop: "highest_education",
                        rules: [
                          {
                            required: true,
                            message: "请选择最高学历",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            disabled: _vm.isDisabled,
                          },
                          model: {
                            value: _vm.studentForm.highest_education,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.studentForm,
                                "highest_education",
                                $$v
                              )
                            },
                            expression: "studentForm.highest_education",
                          },
                        },
                        _vm._l(_vm.options, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "最高学位",
                        prop: "highest_offering",
                        rules: [
                          {
                            required: true,
                            message: "请选择最高学位",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            disabled: _vm.isDisabled,
                          },
                          model: {
                            value: _vm.studentForm.highest_offering,
                            callback: function ($$v) {
                              _vm.$set(_vm.studentForm, "highest_offering", $$v)
                            },
                            expression: "studentForm.highest_offering",
                          },
                        },
                        _vm._l(_vm.degreeList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "专业技术职务",
                        prop: "professional_technical_posts",
                        rules: [
                          {
                            required: true,
                            message: "请输入专业技术职务",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.isDisabled },
                        model: {
                          value: _vm.studentForm.professional_technical_posts,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.studentForm,
                              "professional_technical_posts",
                              $$v
                            )
                          },
                          expression:
                            "studentForm.professional_technical_posts",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "参加工作时间(年)",
                        prop: "work_time",
                        rules: [
                          {
                            required: true,
                            message: "请输入参加工作时间",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.isDisabled },
                        model: {
                          value: _vm.studentForm.work_time,
                          callback: function ($$v) {
                            _vm.$set(_vm.studentForm, "work_time", $$v)
                          },
                          expression: "studentForm.work_time",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "基层工作时间(年)",
                        prop: "substratum_work_time",
                        rules: [
                          {
                            required: true,
                            message: "请输入基层工作时间",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.isDisabled },
                        model: {
                          value: _vm.studentForm.substratum_work_time,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.studentForm,
                              "substratum_work_time",
                              $$v
                            )
                          },
                          expression: "studentForm.substratum_work_time",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "固定电话",
                        prop: "landline",
                        rules: [
                          {
                            required: true,
                            message: "请输入固定电话",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.isDisabled },
                        model: {
                          value: _vm.studentForm.landline,
                          callback: function ($$v) {
                            _vm.$set(_vm.studentForm, "landline", $$v)
                          },
                          expression: "studentForm.landline",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "移动电话",
                        prop: "mobile",
                        rules: [
                          {
                            required: true,
                            message: "请输入移动电话",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.isDisabled },
                        model: {
                          value: _vm.studentForm.mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.studentForm, "mobile", $$v)
                          },
                          expression: "studentForm.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "电子邮箱",
                        prop: "email",
                        rules: [
                          {
                            required: true,
                            message: "请输入电子邮箱",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.isDisabled },
                        model: {
                          value: _vm.studentForm.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.studentForm, "email", $$v)
                          },
                          expression: "studentForm.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "邮编",
                        prop: "postcode",
                        rules: [
                          {
                            required: true,
                            message: "请输入邮编",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.isDisabled },
                        model: {
                          value: _vm.studentForm.postcode,
                          callback: function ($$v) {
                            _vm.$set(_vm.studentForm, "postcode", $$v)
                          },
                          expression: "studentForm.postcode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "照片",
                        prop: "picture",
                        rules: [
                          {
                            required: true,
                            message: "请上传照片",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            action: "",
                            "show-file-list": false,
                            "http-request": _vm.customRequestPic,
                          },
                          model: {
                            value: _vm.studentForm.picture,
                            callback: function ($$v) {
                              _vm.$set(_vm.studentForm, "picture", $$v)
                            },
                            expression: "studentForm.picture",
                          },
                        },
                        [
                          _vm.studentForm.picture
                            ? _c("img", {
                                staticClass: "avatar",
                                attrs: { src: _vm.studentForm.picture },
                              })
                            : _c("i", {
                                staticClass:
                                  "el-icon-plus avatar-uploader-icon",
                              }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "工作单位",
                        prop: "work_unit",
                        rules: [
                          {
                            required: true,
                            message: "请输入工作单位",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.isDisabled },
                        model: {
                          value: _vm.studentForm.work_unit,
                          callback: function ($$v) {
                            _vm.$set(_vm.studentForm, "work_unit", $$v)
                          },
                          expression: "studentForm.work_unit",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "工作部门及职务",
                        prop: "department",
                        rules: [
                          {
                            required: true,
                            message: "请输入工作部门及职务",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.isDisabled },
                        model: {
                          value: _vm.studentForm.department,
                          callback: function ($$v) {
                            _vm.$set(_vm.studentForm, "department", $$v)
                          },
                          expression: "studentForm.department",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "身份证号",
                        prop: "identity_card",
                        rules: [
                          {
                            required: true,
                            message: "请输入身份证号",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.isDisabled },
                        model: {
                          value: _vm.studentForm.identity_card,
                          callback: function ($$v) {
                            _vm.$set(_vm.studentForm, "identity_card", $$v)
                          },
                          expression: "studentForm.identity_card",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "通讯地址",
                        prop: "address",
                        rules: [
                          {
                            required: true,
                            message: "请输入通讯地址",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.isDisabled },
                        model: {
                          value: _vm.studentForm.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.studentForm, "address", $$v)
                          },
                          expression: "studentForm.address",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "item" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _vm._l(_vm.studentForm.cultivateList, function (item, idx) {
                    return _c("div", { key: idx, staticClass: "learn-item" }, [
                      _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "起止时间",
                                prop: "cultivateList." + idx + ".cultivateTime",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入起止时间",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "daterange",
                                  disabled: _vm.isDisabled,
                                  "value-format": "yyyy-MM-dd",
                                  "range-separator": "至",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                },
                                model: {
                                  value: item.cultivateTime,
                                  callback: function ($$v) {
                                    _vm.$set(item, "cultivateTime", $$v)
                                  },
                                  expression: "item.cultivateTime",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "学校/培训单位",
                                prop:
                                  "cultivateList." + idx + ".cultivateSchool",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入学校/培训单位",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: _vm.isDisabled },
                                model: {
                                  value: item.cultivateSchool,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      item,
                                      "cultivateSchool",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "item.cultivateSchool",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "所学专业/培训项目",
                                prop:
                                  "cultivateList." + idx + ".cultivateMajor",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入所学专业/培训项目",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: _vm.isDisabled },
                                model: {
                                  value: item.cultivateMajor,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      item,
                                      "cultivateMajor",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "item.cultivateMajor",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("p", { staticClass: "line" }),
                      idx !== 0
                        ? _c(
                            "span",
                            {
                              staticClass: "del-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.delLearn(idx)
                                },
                              },
                            },
                            [_vm._v("x")]
                          )
                        : _vm._e(),
                    ])
                  }),
                  _c(
                    "div",
                    { staticClass: "add-btn", on: { click: _vm.addLearn } },
                    [
                      _c("p", [_vm._v("+")]),
                      _c("p", [_vm._v("添加主要学习培训经历")]),
                    ]
                  ),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "item" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _vm._l(
                    _vm.studentForm.workExperienceList,
                    function (item, idx) {
                      return _c(
                        "div",
                        { key: idx, staticClass: "learn-item" },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "起止时间",
                                    prop:
                                      "workExperienceList." +
                                      idx +
                                      ".workExperienceTime",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请输入起止时间",
                                        trigger: "blur",
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "daterange",
                                      disabled: _vm.isDisabled,
                                      "value-format": "yyyy-MM-dd",
                                      "range-separator": "至",
                                      "start-placeholder": "开始日期",
                                      "end-placeholder": "结束日期",
                                    },
                                    model: {
                                      value: item.workExperienceTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          item,
                                          "workExperienceTime",
                                          $$v
                                        )
                                      },
                                      expression: "item.workExperienceTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "工作单位",
                                    prop:
                                      "workExperienceList." +
                                      idx +
                                      ".workExperienceUnit",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请输入工作单位",
                                        trigger: "blur",
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: _vm.isDisabled },
                                    model: {
                                      value: item.workExperienceUnit,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          item,
                                          "workExperienceUnit",
                                          $$v
                                        )
                                      },
                                      expression: "item.workExperienceUnit",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "部门、职务(岗位)",
                                    prop:
                                      "workExperienceList." +
                                      idx +
                                      ".workExperiencePost",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请输入部门、职务(岗位)",
                                        trigger: "blur",
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: _vm.isDisabled },
                                    model: {
                                      value: item.workExperiencePost,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          item,
                                          "workExperiencePost",
                                          $$v
                                        )
                                      },
                                      expression: "item.workExperiencePost",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("p", { staticClass: "line" }),
                          idx !== 0
                            ? _c(
                                "span",
                                {
                                  staticClass: "del-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.delWork(idx)
                                    },
                                  },
                                },
                                [_vm._v("x")]
                              )
                            : _vm._e(),
                        ]
                      )
                    }
                  ),
                  _c(
                    "div",
                    { staticClass: "add-btn", on: { click: _vm.addWork } },
                    [
                      _c("p", [_vm._v("+")]),
                      _c("p", [_vm._v("添加主要工作经历")]),
                    ]
                  ),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "item" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _vm._l(_vm.studentForm.awardList, function (item, idx) {
                    return _c("div", { key: idx, staticClass: "learn-item" }, [
                      _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "荣誉名称",
                                prop: "awardList." + idx + ".awardName",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入荣誉名称",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: _vm.isDisabled },
                                model: {
                                  value: item.awardName,
                                  callback: function ($$v) {
                                    _vm.$set(item, "awardName", $$v)
                                  },
                                  expression: "item.awardName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "颁奖部门",
                                prop: "awardList." + idx + ".awardBranch",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入颁奖部门",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: _vm.isDisabled },
                                model: {
                                  value: item.awardBranch,
                                  callback: function ($$v) {
                                    _vm.$set(item, "awardBranch", $$v)
                                  },
                                  expression: "item.awardBranch",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "颁奖时间",
                                prop: "awardList." + idx + ".awardAdTime",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入颁奖时间",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  disabled: _vm.isDisabled,
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "选择日期",
                                },
                                model: {
                                  value: item.awardAdTime,
                                  callback: function ($$v) {
                                    _vm.$set(item, "awardAdTime", $$v)
                                  },
                                  expression: "item.awardAdTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("p", { staticClass: "line" }),
                      idx !== 0
                        ? _c(
                            "span",
                            {
                              staticClass: "del-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.delAward(idx)
                                },
                              },
                            },
                            [_vm._v("x")]
                          )
                        : _vm._e(),
                    ])
                  }),
                  _c(
                    "div",
                    { staticClass: "add-btn", on: { click: _vm.addAward } },
                    [
                      _c("p", [_vm._v("+")]),
                      _c("p", [_vm._v("添加表彰奖励情况")]),
                    ]
                  ),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "item" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "50px",
                        prop: "influence",
                        rules: [
                          {
                            required: true,
                            message: "请输入主要贡献、业绩和社会影响",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("p", { staticClass: "influence" }, [
                        _vm._v("（限1000字以内）"),
                      ]),
                      _c("cus-edit", {
                        attrs: { content: _vm.studentForm.influence },
                        on: {
                          "update:content": function ($event) {
                            return _vm.$set(
                              _vm.studentForm,
                              "influence",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "item" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "意见表上传",
                        "label-width": "180px",
                        prop: "feedback_file",
                        rules: [
                          {
                            required: true,
                            message: "请上传意见表",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("commonUpload", {
                        attrs: { fileList: _vm.feedbackFileList },
                        on: {
                          handlePreview: function ($event) {
                            return _vm.handlePreviews($event)
                          },
                          deleteFileItem: function ($event) {
                            return _vm.handleRemove("feedback_file", $event)
                          },
                          customRequest: function ($event) {
                            return _vm.customRequest("feedback_file", $event)
                          },
                        },
                        model: {
                          value: _vm.studentForm.feedback_file,
                          callback: function ($$v) {
                            _vm.$set(_vm.studentForm, "feedback_file", $$v)
                          },
                          expression: "studentForm.feedback_file",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "item" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "资料文件上传", "label-width": "200px" },
                    },
                    [
                      _c("commonUpload", {
                        attrs: { fileList: _vm.fileList },
                        on: {
                          handlePreview: function ($event) {
                            return _vm.handlePreviews($event)
                          },
                          deleteFileItem: function ($event) {
                            return _vm.handleRemove("material_file", $event)
                          },
                          customRequest: function ($event) {
                            return _vm.customRequest("material_file", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c("p", { staticClass: "annex-txt" }, [
                    _vm._v(
                      "个人详细事迹材料WORD版和PDF版（需加盖公章）各1份，支撑材料1份（凸显推荐人选工作特质的图片jpg/jpeg格式电子版5幅，毕业证书、学位证书、获奖证书等证明材料扫描件）"
                    ),
                  ]),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "footer-button" }, [
              !_vm.isDisabled
                ? _c(
                    "div",
                    {
                      staticClass: "submit-btn",
                      on: {
                        click: function ($event) {
                          return _vm.submitForm(1)
                        },
                      },
                    },
                    [_vm._v("提交")]
                  )
                : _vm._e(),
              !_vm.isDisabled
                ? _c(
                    "div",
                    {
                      staticClass: "footer-save submit-btn",
                      on: {
                        click: function ($event) {
                          return _vm.submitForm(0)
                        },
                      },
                    },
                    [_vm._v("保存")]
                  )
                : _vm._e(),
              _vm.isDisabled
                ? _c(
                    "div",
                    {
                      staticClass: "footer-export submit-btn",
                      on: { click: _vm.exportInfo },
                    },
                    [_vm._v("导出")]
                  )
                : _vm._e(),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top" }, [
      _c("img", {
        attrs: {
          src: "https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230316/1636196768506634240.png",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="dialog-container">
    <el-footer height="72px">
      <el-row
        type="flex"
        justify="space-between"
        align="middle"
        style="height: 100%"
      >
        <div v-if="false">
          <el-row v-if="selectList.length" type="flex" style="height: 40px">
            <div style="margin-right: 20px">
              <div class="footer-left-info">
                <el-row align="top" type="flex">
                  <el-col>
                    <h4 style="line-height: 12px">
                      已选{{ selectList.length }}个
                    </h4>
                  </el-col>
                </el-row>
                <el-row align="bottom">
                  <el-col>
                    <el-link
                      :underline="false"
                      type="danger"
                      style="line-height: 12px"
                      @click="$emit('clear')"
                    >清空
                    </el-link>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div>
              <el-image
                v-for="(item, index) of selectList"
                :key="index"
                style="width: 40px; height: 40px; margin-right: 8px"
                :class="{ 'image-checked': index === selectKey }"
                :src="item.url"
              />
            </div>
          </el-row>
        </div>
        <div class="upload-wrapper">
          <!-- <el-button @click="toShowCropper" icon="el-icon-upload" type="primary">点击上传</el-button> -->
          <!-- <el-upload
            v-if="
              (groupType == 0 || (groupType == 1 && canEdit)) && groupId != -2
            "
            class="upload-drag"
            drag
            :action="actionUrl"
            name="file"
            multiple
            :data="uploadData"
            :show-file-list="false"
            :before-upload="handleBeforeUpload"
            :on-success="handleUploadSuccess"
            :on-change="getUploadFile"
            accept="image/*"
          > -->
          <el-upload
            v-if="
              ((groupType == 0 || (groupType == 1 && canEdit)) && groupId != -2)&&(!(groupType==0&&roleType==0))
            "
            class="upload-drag"
            drag
            name="file"
            multiple
            :action="actionUrl"
            :data="uploadData"
            :show-file-list="false"
            :on-change="getUploadFile"
            accept="image/*"
            :auto-upload="false"
          >
            <i class="el-icon-upload"/>
            <div class="el-upload__text">
              点击上传
            </div>
            <div
              v-if="maxSize"
              slot="tip"
              class="el-upload__tip"
              style="text-align: center"
            >
              最大上传文件大小：{{ maxSizeText }}
            </div>
          </el-upload>
        </div>
        <div>
          <div style="display: flex; flex-direction: row-reverse">
            <div>
              <el-button
                v-if="canEdit"
                :disabled="selectList.length <= 0"
                @click="openDialog"
              >移动至分类
              </el-button>
              <el-button
                type="primary"
                :disabled="selectList.length <= 0"
                @click="$emit('add')"
              >确定添加
              </el-button>
            </div>
          </div>
        </div>
      </el-row>

      <el-dialog
        title="移动分类"
        :visible.sync="innerVisible"
        append-to-body
        width="30%"
      >
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="80px"
        >
          <el-form-item label="分类名称" prop="group_id">
            <el-select v-model="ruleForm.group_id">
              <el-option
                v-for="(item, index) in classify_list"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button
            @click="
              () => {
                this.innerVisible = false;
                this.ruleForm.group_id = '';
              }
            "
          >取 消
          </el-button>
          <el-button type="primary" @click="onMoveClassify">确 定</el-button>
        </div>
      </el-dialog>
    </el-footer>
  </div>
</template>

<script>
import Upload from '@/packages/apis/Upload'
import Config from '../../apis/Config'
import {conver} from '../../utils/utils'
import {getStorage} from '@/utils/storage'
import axios from "axios";

const actionUrl = Config.baseUrl

const api = new Upload()

export default {
  name: 'DialogContainerFooter',
  inject: ['indexCom'],
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    groupType: {
      type: Number
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    checkedKey: {
      type: [Number, null],
      default: null
    },
    groupId: {
      type: Number
    }
  },
  data() {
    return {
      innerVisible: false,
      classify_list: [],
      ruleForm: {
        group_id: '',
        data: ''
      },
      rules: {
        group_id: [{required: true, message: '请选择分类', trigger: 'blur'}]
      },
      actionUrl,
      roleType: 0
    }
  },
  computed: {
    selectList() {
      console.log('selectList', this.list)
      return this.list.filter((item) => item.checked)
    },
    selectKey() {
      console.log('selectKey', this.checkedKey)
      return this.selectList.findIndex(
        (item) => item.id === this.list[this.checkedKey].id
      )
    },
    uploadData() {
      {
        return {
          api: 'resources.file.uploadFiles',
          storeId:
            this.$route.path.split('/')[1] == 'Platform'
              ? 0
              : getStorage('laike_admin_userInfo').storeId,
          groupId: this.groupId,
          // mchId: getStorage('laike_admin_userInfo').mchId,
          uploadType: 2,
          accessId: this.$store.getters.token,
          type: this.groupType
        }
      }
    },
    maxSize() {
      return this.indexCom.maxSize
    },
    maxSizeText() {
      return conver(this.indexCom.maxSize)
    }
  },
  watch: {
    list: {
      handler(val) {
      },
      immediate: true,
      deep: true
    },
    groupType: {
      handler(newVal, oldVal) {
      }
    }
  },
  created() {
    this.roleType = getStorage('rolesInfo') ? getStorage('rolesInfo').type : 1
  },
  methods: {
    async openDialog() {
      console.log('openDialog')
      this.innerVisible = true
      await this.getClassifys()
    },
    async getClassifys() {
      console.log('getClassifys:::', this.groupType)
      await api.getClassify(this.groupType).then((res) => {
        const objDef = [{id: -1, name: '默认'}]
        objDef.push(...res.data.data.list)
        this.classify_list = objDef
      })
    },
    onMoveClassify() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (!valid) return false
        await api.moveClassify({
          groupId: this.ruleForm.group_id,
          id: this.list[this.checkedKey].id
        })
        this.$message.success('移动成功')
        this.$emit('move')
        this.innerVisible = false
      })
    },
    getUploadFile(file,fileList){
      var files = file.raw;
      this.fileinfo = file; // 保存下当前文件的一些基本信息
      let reader = new FileReader(); // 创建文件读取对象
      reader.onload = async e => {
        let data;
        if (typeof e.target.result === 'object') {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        console.log(this.$store.state)
        // 判断是否需要裁切
        if(this.$store.state.upload.cropperWidth && this.$store.state.upload.cropperHeight){
          this.$store.commit('upload/TOGGLE_CROPPER', {show: true, img: data, name: file.name})
        }else{
          this.finish(file)
        }
      };
      reader.readAsArrayBuffer(files);
    },
    // 上传之前的处理
    handleBeforeUpload(file) {
      // console.log(this.maxSize)
      // console.log(file.size)
      // if (file.size > 5242880) {
      //   this.$message.error("超出最大上传大小，图片不能大于5MB");
      //   return false;
      // }
      // const isLt3M = file.size / 1024 / 1024 < 3
      // if (!isLt3M) {
      //   return this.$message.error('上传图片大小不能超过 3MB!')
      // }
      // if (this.maxSize) {
      //   if (file.size > this.maxSize) {
      //     this.$message.error("超出最大上传大小");
      //     return false;
      //   }
      // }
      // this.$store.commit('upload/TOGGLE_CROPPER', {show: true, img: file})

      // return false
    },
    // 上传成功
    handleUploadSuccess(res) {
      // console.log(res)
      // if (res.code == '200') {
      //   const img = res.data.filesRecordSave
      //   if (img) {
      //     img['url'] = res.data.imgUrls[0]
      //     // 显示裁切框
      //     this.$store.commit('upload/TOGGLE_CROPPER', {show: true, img: img.url})

      //     // this.$emit('onUploadSuccess', img)
      //   }
      // } else {
      //   this.$message.error(res.message);
      // }
      // // this.$emit('getNewList')
    },
    // 不走裁切时上传
    finish(file) {
      const loading = this.$loading({
        lock: true,
        text: '上传中...',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let formData = new FormData();
      formData.append("file", file.raw, file.name); // 第一个file 后台接收的参数名
      axios({
        url: actionUrl, // 上传路径
        method: "POST",
        params: {
          api: "resources.file.uploadFiles",
          storeId:
            this.$route.path.split("/")[1] == "Platform"
              ? 0
              : getStorage("laike_admin_userInfo").storeId,
          groupId: this.groupId,
          // mchId: getStorage('laike_admin_userInfo').mchId,
          uploadType: 2,
          type: 0,
          accessId: this.$store.getters.token,
        },
        data: formData,
      }).then((result) => {
        if (result.data.code == 200) {
          this.$message.success(result.data.message);
          this.$emit('getNewList')
        } else {
          this.$message.error(result.data.message);
        }
        loading.close();
      });
    },
  }
}
</script>

<style scoped lang="less">
.dialog-container {
  z-index: 99999999 !important;

  .el-footer {
    .footer-left-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 40px;
    }

    .image-checked {
      height: 43px;
      width: 43px;
      padding: 1px;
      border: 2px solid #06c377;
    }

    .upload-wrapper {
      flex: 1;
      display: flex;
      justify-content: center;
    }

    /deep/ .el-upload-dragger {
      height: 44px;
      background: #06c377;
      border: 0;
      display: flex;
      align-content: center;
      justify-content: center;
      width: 180px;

      .el-icon-upload {
        color: #fff;
        font-size: 28px;
        margin: 0 10px 0 0;
        line-height: 1.75rem;
        height: 1.75rem;
        align-self: center;
        // animation: mymove 1s ease-in-out infinite reverse forwards;
      }

      .el-upload__text {
        color: #fff;
        align-self: center;
        em {
          color: #fff;
        }
      }
    }
  }
}

@keyframes mymove {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-3px);
  }
}
</style>

<template>
  <div class="wxthird-header">
    <ul>
      <li>{{ name }}</li>
      <li class="sign-out" @click="signOut">
        <el-icon class="el-icon-switch-button" title="退出" />
      </li>
    </ul>
  </div>
</template>

<script>
import { loginOut } from '@/api/layout/information'
import { getStorage } from '@/utils/storage'

export default {
  data() {
    return {
      name: ''
    }
  },
  created() {
    this.name = getStorage('laike_admin_userInfo').name
  },
  methods: {
    // 退出登录
    signOut() {
      loginOut({
        api: 'admin.saas.user.logout'
      }).then(res => {
        window.localStorage.clear()
        this.$router.push('/login')
        location.reload()
      })
    }
  }
}
</script>

<style scoped lang="less">
.wxthird-header {
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  border-bottom: 1px solid #ddd;
  width: 100%;
  padding: 10px 20px;
  text-align: right;
  font-size: 14px;
  z-index: 2;
  ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .sign-out {
    margin-left: 10px;
    font-size: 14px;
    cursor: pointer;
  }
}
</style>

import request from "@/api/https";
import imgSizeData from '@/config/imgSize'
import Vue from 'vue'

const state = {
  imgSize: {},
};
const mutations = {
  SET_IMGSIZE: (state, obj)=>{
    state.imgSize = imgSizeData[obj.env][obj.mpType][obj.mpMini][obj.mpSkin]
    Vue.prototype.IMGSIZE = state.imgSize
  }
};
const actions = {
  getCurrentTemplate({ commit }) {
    request({
        method: 'post',
        params:{
            api: "admin.diy.indexByCustomerId",
        }
    }).then((res) => {
      res = res.data
      if(res.code == 200){
        // 模板类型
        let mpType = res.data.currentTemp.tempType === 1 ? 'ZT' : 'JG' // 1 展厅 2 机构
        let mpMini = res.data.currentTemp.miniAppId.trim()  // 模板 值为appid
        let mpSkin = res.data.skinInfo ? res.data.skinInfo.wxFileName : 'DEFAULT' // 皮肤
        let env = process.env.NODE_ENV === 'production' ? 'PROD' : 'DEV'  // 开发环境
        commit('SET_IMGSIZE',{env, mpType, mpMini, mpSkin})
      }
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};

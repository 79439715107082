import { render, staticRenderFns } from "./dialog-container-footer.vue?vue&type=template&id=f1bb0b10&scoped=true"
import script from "./dialog-container-footer.vue?vue&type=script&lang=js"
export * from "./dialog-container-footer.vue?vue&type=script&lang=js"
import style0 from "./dialog-container-footer.vue?vue&type=style&index=0&id=f1bb0b10&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1bb0b10",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\FE\\ns-saas\\backend-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f1bb0b10')) {
      api.createRecord('f1bb0b10', component.options)
    } else {
      api.reload('f1bb0b10', component.options)
    }
    module.hot.accept("./dialog-container-footer.vue?vue&type=template&id=f1bb0b10&scoped=true", function () {
      api.rerender('f1bb0b10', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/packages/Upload/components/dialog-container-footer.vue"
export default component.exports
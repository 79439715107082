var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sideitem" }, [
    _c(
      "ul",
      { staticClass: "list-item" },
      _vm._l(_vm.item, function (item, index) {
        return _c(
          "li",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: item.meta.is_core == 1,
                expression: "item.meta.is_core == 1",
              },
            ],
            key: index,
          },
          [
            _c(
              "span",
              {
                class: { active: item.name === _vm.$route.path.split("/")[2] },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleSelect(_vm.mainNav + "/" + item.path, item)
                  },
                },
              },
              [
                _vm._v(" " + _vm._s(_vm.generateTitle(item.meta.title)) + " "),
                item.meta.title === "订单列表"
                  ? _c("i", { staticClass: "orderListNum" }, [
                      _vm._v("(" + _vm._s(_vm.orderListNum) + ")"),
                    ])
                  : _vm._e(),
                item.meta.title === "退货列表"
                  ? _c("i", { staticClass: "salesReturnListNum" }, [
                      _vm._v("(" + _vm._s(_vm.refundListNum) + ")"),
                    ])
                  : _vm._e(),
              ]
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
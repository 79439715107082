<template>
  <div class="main" style="position: relative;">
    <div id="fcytMap" style="z-index: 999;position: absolute;height: 100vh;" />
    <img alt="数据看板" class="title" src="@/assets/imgs/board_title.png" @click="getCenter">
    <!--    左边数据-->
    <div class="verticalList showtop" style="position: absolute;left: 0; margin-left: 3.33%;">
      <!--营业额-->
      <div class="verticalItem " style="height: 17rem">
        <div class="graphBar">
          <div>
            <img alt="营业额" class="iconImg" src="@/assets/imgs/ic_turnover.png">
            <span class="graphTitle">营业额</span>
          </div>
          <el-select
            v-model="turnoverIndex"
            class="select-input"
            placeholder="请选择状态"
            @change="onDurationSaleChange"
          >
            <el-option
              v-for="(item,index) in durationSaleOptions"
              :key="index"
              :label="item.lable"
              :value="index"
            />
          </el-select>
        </div>
        <div class="subTitle">
          <div style="color:#D3F5FF; ">累计营业额</div>
          <!--          <div style="color: #43EDF8;font-weight: bold;margin-left: 1rem">{{-->
          <!--              durationSaleOptions[turnoverIndex].total-->
          <!--            }}-->
          <!--          </div>-->
          <div style="color: #43EDF8;font-weight: bold;margin-left: 1rem">{{ z_total }}</div>
        </div>
        <div id="turnoverTJT" class="graph" />
        <div class="container-header" />
        <div class="container-footer" />
      </div>

      <!--订单数-->
      <div class="verticalItem" style="height: 15.0625rem">
        <div class="graphBar">
          <div>
            <img alt="订单数" class="iconImg" src="@/assets/imgs/ic_order.png">
            <span class="graphTitle">订单数</span>
          </div>
          <el-select
            v-model="orderIndex"
            class="select-input"
            placeholder="请选择状态"
            @change="onDurationOrderChange"
          >
            <el-option v-for="(item,index) in durationOptions" :key="index" :label="item.lable" :value="index" />
          </el-select>
        </div>
        <div id="ddTJT" class="graph" />
        <div class="container-header" />
        <div class="container-footer" />
      </div>
      <!--访客数-->
      <div class="verticalItem" style="height: 17.125rem">
        <div class="graphBar">
          <div>
            <img alt="访客数" class="iconImg" src="@/assets/imgs/ic_visitor.png">
            <span class="graphTitle">访客数</span>
          </div>
          <el-select v-model="userIndex" class="select-input" placeholder="请选择状态" @change="onDurationUserChange">
            <el-option v-for="(item,index) in durationUserOptions" :key="index" :label="item.lable" :value="index" />
          </el-select>
        </div>
        <div id="usersPie" class="graph" />
        <div class="container-header" />
        <div class="container-footer" />
      </div>
    </div>
    <!--中间数据-->
    <div v-if="controlBoard" class="horizontalList showtop" style="position: absolute;">
      <div class="horizontalItem" @click="toOrderDetail('0')">
        <div class="itemTitle">待付款</div>
        <div class="itemValue">{{ dataMain.notPay }}</div>
        <div class="container-header" />
        <div class="container-footer" />
      </div>
      <div class="horizontalItem" @click="toOrderDetail('1')">
        <div class="itemTitle">待发货</div>
        <div class="itemValue">{{ dataMain.noSendOrderNum }}</div>
        <div class="container-header" />
        <div class="container-footer" />
      </div>
      <div class="horizontalItem" @click="toOrderDetail('2')">
        <div class="itemTitle">待收货</div>
        <div class="itemValue">{{ dataMain.notDeliverNum }}</div>
        <div class="container-header" />
        <div class="container-footer" />
      </div>
      <div class="horizontalItem" @click="toOrderDetail('5')">
        <div class="itemTitle">待评价</div>
        <div class="itemValue">{{ dataMain.orderNotCommentsNum }}</div>
        <div class="container-header" />
        <div class="container-footer" />
      </div>
      <div class="horizontalItem" @click="toOrderDetail('退货')">
        <div class="itemTitle">待退货</div>
        <div class="itemValue">{{ dataMain.orderReturnWaitNum }}</div>
        <div class="container-header" />
        <div class="container-footer" />
      </div>
    </div>
    <!--右边数据-->
    <div v-if="controlBoard" class="verticalList showtop" style="position: absolute;right: 0; margin-right: 3.33%;">
      <!--通知公告-->
      <div class="verticalItem news" style="height: 10.0625rem">
        <div class="container-header" />
        <div class="container-footer" />
        <div class="graphBar">
          <div>
            <img alt="通知公告" class="iconImg" src="@/assets/imgs/ic_notice.png">
            <span class="graphTitle">通知公告</span>
          </div>
        </div>
        <ul style="margin-top: 0.625rem;height: 7.1875rem;overflow: auto;">
          <infinite-scroll :list="dataMain.sysNotice">
            <li v-for="(item,index) in dataMain.sysNotice" :key="index" @click="toNoticeDetail(item.id)">
              <div class="item">
                <span class="newsTitle">{{ item.title }}</span>
                <span class="newsTime">{{ item.add_time | dateFormat }}</span>
              </div>
            </li>
          </infinite-scroll>

        </ul>
      </div>

      <!--新订单-->
      <div class="verticalItem" style="height: 18.5625rem">
        <div class="container-header" />
        <div class="container-footer" />
        <div class="graphBar">
          <div>
            <img alt="新订单" class="iconImg" src="@/assets/imgs/ic_new.png">
            <span class="graphTitle">新订单</span>
          </div>
        </div>
        <ul style="height: 15.625rem;overflow: hidden">
          <li>
            <div class="newOrderItem" style="height: 1.4375rem;margin-top: 0.6875rem">
              <div class="projectArea">商品名称</div>
              <div class="projectArea">订单金额</div>
              <div class="projectArea">买家信息</div>
              <div class="projectArea">下单时间</div>
            </div>
          </li>
          <infinite-scroll :list="dataMain.newOrders">
            <li v-for="(item,index) in dataMain.newOrders" :key="index">
              <div class="newOrderItem">
                <div class="projectArea name">{{ item.goodsName }}{{ item.goodsName }}</div>
                <div class="projectArea money">￥{{ item.orderPrice }}</div>
                <div class="projectArea user">{{ item.userName }}</div>
                <div class="projectArea time">
                  <div>{{ item.timeDay }}</div>
                  <div>{{ item.timeTime }}</div>
                </div>
              </div>
            </li>
          </infinite-scroll>
        </ul>
      </div>
      <!--销量排行-->
      <div class="verticalItem" style="height: 20.6875rem">
        <div class="graphBar">
          <div>
            <img alt="销量排行" class="iconImg" src="@/assets/imgs/ic_rank.png">
            <span class="graphTitle">销量排行</span>
          </div>
        </div>
        <div id="graphSalesRank" class="graph" />
        <div class="container-header" />
        <div class="container-footer" />
      </div>

    </div>

  </div>
</template>

<script>
// import { getStorage, setStorage, removeStorage } from '@/utils/storage'
import { index } from '@/api/data'
import { getStorage } from '@/utils/storage'
import InfiniteScroll from '@/components/InfiniteScroll'

export default {
  components: { InfiniteScroll },
  data() {
    return {
      centerLocation: [
        { // 中心
          lat: 41.36900862430748,
          lng: 106.26468612500001
        },
        { // 右偏移
          lat: 38.74597712072276, // 34.58848504996957,
          lng: 94.39945175000001// 83.14945175000001
        }
      ],
      orgLocation: {
        lat: 41.36900862430748,
        lng: 106.26468612500001
      },
      dataList: [],
      map: null,
      infoWindow: null,
      // 营业额
      turnoverIndex: 0,
      durationSaleOptions: [{
        lable: '按天', total: 0
      }, {
        lable: '按月', total: 0
      }, {
        lable: '按年', total: 0
      }],
      // 订单数
      orderIndex: 0,
      durationOptions: [{
        value: 2, lable: '按天'
      }, {
        value: 3, lable: '本月'
      }, {
        value: 4, lable: '本年'
      }],
      // 访客
      userIndex: 0,
      durationUserOptions: [{
        lable: '按天'
      }, {
        lable: '本月'
      }, {
        lable: '本年'
      }],

      dataMain: {
        notPay: 0,
        noSendOrderNum: 0,
        notDeliverNum: 0,
        orderNotCommentsNum: 0,
        orderReturnWaitNum: 0,
        /**
         *实时营业额
         */
        // 总消费额
        storeAmtTotal: 0,
        // 销售同比
        dayAmtProportion: 0,
        dayAmtProportionAmt: 0,
        // 今日消费基恩
        toDaySaleTotal: 0,
        // 昨日消费额
        yesterdaySale: 0,
        // 本月消费金额
        monthAmtTotal: 0,
        /**
         * 实时订单数
         */
        orderNumTotal: 0,
        // 本月订单数
        monthOrderNum: 0,
        // 昨日订单
        yesterdayOrderNum: 0,
        // 订单数同比
        yoy_order: 0,
        yoy_order_num: 0,
        /**
         * 实时访客数 今天 昨天 总访问 占比/率
         */
        todayBrowseTotal: 0,
        yesterdayBrowseTotal: 0,
        monthBrowseTotal: 0,
        coustomer_num_z: 0,
        // 同比
        yesterdayBrowseNum: 0,
        yesterdayBrowse: 0,
        /**
         * 公告
         */
        sysNotice: [],
        /**
         * 会员统计
         */
        userTotal: 0,
        newOrders: []
      },
      // 累计订单
      z_num: 0,
      // 累计营业额
      z_total: 0,
      storeId: getStorage('rolesInfo').storeId,
      controlBoard: true,
      colors: [
        '#FF596F',
        '#FCA169',
        '#F382F3',
        '#85A7FF',
        '#FFBC80',
        '#85D1FF',
        '#68E0CF',
        '#BA5EE6',
        '#656EFF',
        '#F97E4A',
        '#38A4FF',
        '#20D2FF'
      ]
    }
  },
  created() {
    document.body.style.overflow = 'hidden'
    this.controlBoard = sessionStorage.getItem('controlBoard') === 'true'
    this.orgLocation = this.controlBoard ? this.centerLocation[0] : this.centerLocation[1]
    this.eventResize()
    window.addEventListener('resize', this.eventResize)
  },
  mounted() {
    this.loadOrganizeInfo()
    this.loadData(this.storeId)
    // 定时刷新
    // this.timer = setInterval(() => {
    //   console.log('setTimeout ')
    //   this.loadData()
    // }, 5000)
  },
  destoryed() {
    this.clearInterval(this.timer)
    window.removeEventListener('resize', this.eventResize)
  },
  methods: {
    eventResize() {
      const fontsize = document.documentElement.style.fontSize
      const size = fontsize.replace(/px/g, '')
      this.fontSizeScale = parseFloat(size) / 16
      this.reDrawGraph()
    },
    getCenter() {
      var center = this.map.getCenter()
      console.log('getCenter:', center)
    },
    async loadOrganizeInfo() {
      const res = await index({
        api: 'admin.home.dataKanbanMap',
        storeId: getStorage('rolesInfo').storeId
      })
      console.log('loadOrganizeInfo:', res)
      this.dataList = res.data.data
      this.initMapScript().then(()=>{
        this.initMap()
      })
    },
    initMapScript () {
      const AK = "4SDBZ-Z6MCK-XPMJB-ATH5E-PM6KS-I6BZ7";
      const QQMap_URL =
        "https://map.qq.com/api/gljs?v=1.exp&key=" +
        AK +
        "&libraries=visualization&callback=onMapCallback";
      return new Promise((resolve, reject) => {
        // 如果已加载直接返回
        if (typeof TMap !== "undefined") {
          resolve(TMap );
          return true;
        }
        window.onMapCallback = function () {
          resolve(TMap);
        };

        // 插入script脚本
        let scriptNode = document.createElement("script");
        scriptNode.setAttribute("type", "text/javascript");
        scriptNode.setAttribute("src", QQMap_URL);
        document.body.appendChild(scriptNode);
      });
    },
    // 地图初始化
    initMap() {
      var list = this.dataList
      // eslint-disable-next-line no-undef
      var center = new TMap.LatLng(
        this.orgLocation.lat, this.orgLocation.lng
      )
      var got = false
      if (list instanceof Array && list.length > 0) {
        var org = list[list.length - 1]
        if (org.lat && org.lng) {
          got = true
          // eslint-disable-next-line no-undef
          center = new TMap.LatLng(
            org.lat,
            org.lng
          )
        }
      }
      // eslint-disable-next-line no-undef
      var map = new TMap.Map(document.getElementById('fcytMap'), {
        center: center,
        zoom: got ? 12 : 5,
        showControl: false,
        pitchable: false,
        rotatable: false,
        mapStyleId: 'style1' //
      })
      this.map = map
      this.addRadiation(this.dataList, map)
      // this.addMarks(this.dataList, map)
      this.fitBounds(this.dataList, map)
    },
    addRadiation(dataList, map) {
      var radiationData = []
      var dotData = []
      dataList.forEach((item, index) => {
        if (item.lat && item.lng) {
          // eslint-disable-next-line no-undef
          var position = new TMap.LatLng(parseFloat(item.lat), parseFloat(item.lng))
          radiationData.push({
            radius: 500 * this.fontSizeScale, // 辐射圈半径
            center: position,
            styleId: 'style1', // 设置辐射圈样式配置id,
            index: index
          })
          dotData.push({
            'lat': parseFloat(item.lat),
            'lng': parseFloat(item.lng),
            'styleId': 'style1',
            'index': index
          })
        }
      })

      // 创建辐射圈
      // eslint-disable-next-line no-undef
      var radiationCircle = new TMap.visualization.Radiation({
        styles: {
          style1: {
            fillColor: 'rgba(29,250,242,0.9)' // 设置辐射圈填充颜色
          },
          style2: {
            fillColor: 'rgba(201,152,52,0.9)' // 设置辐射圈填充颜色
          }
        },
        processAnimation: {
          duration: 2000 // 辐射圈半径从0动态过渡到最大半径的时长，单位为ms
        },
        enableBloom: true // 泛光
      }).addTo(map)

      const _that = this
      // 设置辐射圈数据
      radiationCircle.setData(radiationData)

      // 初始化散点图并添加至map图层
      // eslint-disable-next-line no-undef
      var dot = new TMap.visualization.Dot({
        styles: {
          style1: {
            fillColor: 'rgba(29,250,242,1)', // 圆形填充颜色
            radius: 4 * this.fontSizeScale // 圆形半径
          },
          style2: {
            fillColor: 'rgba(201,152,52,1)', // 圆形填充颜色
            radius: 2 * this.fontSizeScale // 圆形半径
          }
        },
        enableBloom: true // 泛光
      }).addTo(map)
      dot.setData(dotData) // 设置数据
      dot.on('hover', function(evt) {
        if (evt.detail.dot) {
          var item = dataList[evt.detail.dot.index]
          // eslint-disable-next-line no-undef
          var position = new TMap.LatLng(parseFloat(item.lat), parseFloat(item.lng))
          if (_that.infoWindow == null) {
            // eslint-disable-next-line no-undef
            _that.infoWindow = new TMap.InfoWindow({
              map: map,
              position: position, // 设置信息框位置
              content: '提示',
              offset: { x: 0, y: -5 * this.fontSizeScale } // 设置信息窗相对position偏移像素
            })
          }
          _that.infoWindow.open()
          _that.infoWindow.setContent('<div style="text-align:left;white-space:nowrap;">' +
            '<div style="font-weight: bolder;color: red;">' + item.company + '</div>' +
            '<div>' + '地址：' + item.address_xq + '</div>' +
            '<div>' + '联系人：' + item.name + '</div>' +
            '<div>' + '联系电话：' + item.tel + '</div>' +
            '<div>' + '联系邮箱：' + item.email + '</div>' +
            '</div>')
          _that.infoWindow.setPosition(position)
        } else {
          console.log('hover:', false)
          if (_that.infoWindow != null) {
            _that.infoWindow.close()
          }
        }
      })
      dot.on('click', function(evt) {
        console.log('click:', evt)
        if (_that.infoWindow) {
          _that.infoWindow.close()
        }
        if (evt.detail.dot) {
          var item = dataList[evt.detail.dot.index]
          _that.$message({
            type: 'success',
            message: item.company
          })
          _that.loadData(item.store_id)
        }
      })
    },
    fitBounds(dataList, map) {
      // 描述一个矩形的地理坐标范围。
      // eslint-disable-next-line no-undef
      var bounds = new TMap.LatLngBounds()
      dataList.forEach((item, index) => {
        if (item.lat && item.lng) {
          // 若坐标点不在范围内，扩大bounds范围
          // eslint-disable-next-line no-undef
          var position = new TMap.LatLng(parseFloat(item.lat), parseFloat(item.lng))
          if (bounds.isEmpty() || !bounds.contains(position)) {
            bounds.extend(position)
          }
        }
      })
      if (!bounds.isEmpty()) {
        // 设置地图可视范围以显示所有mark
        map.fitBounds(bounds, {
          padding: {
            top: 300 * this.fontSizeScale, bottom: 50 * this.fontSizeScale, right: 550 * this.fontSizeScale, left: 550 * this.fontSizeScale
          } // 自适应边距
        })
      }
    },
    addMarks(dataList, map) {
      var markerArr = []
      dataList.forEach((item, index) => {
        if (!item.lat || !item.lng) {
          return
        }
        // eslint-disable-next-line no-undef
        var center = new TMap.LatLng(
          item.lat,
          item.lng
        )
        markerArr.push({
          id: index,
          styleId: 'marker',
          // 标记位置(纬度，经度，高度)
          position: center,
          'properties': {
            'title': index
          }
        })
      })

      // eslint-disable-next-line no-undef
      var marker = new TMap.MultiMarker({
        id: 'marker-layer',
        map: map,
        styles: {
          // eslint-disable-next-line no-undef
          'marker': new TMap.MarkerStyle({
            'width': 24 * this.fontSizeScale,
            'height': 35 * this.fontSizeScale,
            'anchor': { x: 12 * this.fontSizeScale, y: 35 * this.fontSizeScale },
            'src': 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png'
          })
        },
        geometries: markerArr
      })

      // marker.on('mouseover', (evt) => {
      //   var index = evt.geometry.id
      //   console.log('mouseover:', index)
      // })
      // marker.on('mouseout', (evt) => {
      //   var index = evt.geometry.id
      //   console.log('mouseout:', index)
      // })
      const _that = this
      marker.on('click', (evt) => {
        var index = evt.geometry.id
        var item = dataList[index]
        console.log('click:', index)
        _that.$message({
          type: 'success',
          message: item.company
        })
        _that.loadData(item.store_id)
      })
    },
    async loadData(id) {
      await index({
        api: 'admin.home.index',
        storeId: id
      }).then(data => {
        const main = data.data.data
        const order = main.list_sno
        this.dataMain.notPay = order[1]// 待付款订单数
        this.dataMain.noSendOrderNum = order[2]// 待发货订单数
        this.dataMain.notDeliverNum = order[5]// 待收货订单数
        this.dataMain.orderNotCommentsNum = order[4]// 待评价订单数
        this.dataMain.orderReturnWaitNum = order[3]// 退货订单数
        // 今日营业额
        this.dataMain.toDaySaleTotal = main.toDaySaleTotal
        // 销售同比
        this.dataMain.dayAmtProportion = main.dayAmtProportion
        this.dataMain.dayAmtProportionAmt = main.dayAmtProportionAmt
        // 昨日营业额
        this.dataMain.yesterdaySale = main.yesterdaySale
        // 本月营业额
        this.dataMain.monthAmtTotal = main.monthAmtTotal
        // 累计营业额
        this.z_total = main.z_total

        // 累计营业额
        var z_weekly_turnover = main.z_weekly_turnover// 按天7
        var z_daily_sales = main.z_daily_sales// 按月31
        var z_years_sales = main.z_years_sales// 按年12
        this.durationSaleOptions[0].datas = z_weekly_turnover
        this.durationSaleOptions[1].datas = z_daily_sales
        this.durationSaleOptions[2].datas = z_years_sales
        for (var ite of this.durationSaleOptions) {
          var total = 0
          var turnoverdates = []
          var turnovervalues = []
          for (var item of ite.datas) {
            for (const key in item) {
              turnoverdates.push(key)
              var v = parseFloat(item[key])
              total += v
              turnovervalues.push(v)
            }
          }
          var turnover = {}
          turnover.dates = turnoverdates
          turnover.values = turnovervalues
          ite.turnover = turnover
          ite.total = total
        }
        this.showTunover(this.durationSaleOptions[0].turnover)

        /**
         * 实时订单数
         */
        this.dataMain.today_order_num = order[0]// 今日订单数
        this.dataMain.yoy_order = main.yoy_order // 订单数同比
        this.dataMain.yesterdayOrderNum = main.yesterdayOrderNum// 昨日订单
        this.dataMain.monthOrderNum = main.monthOrderNum// 本月订单数
        this.z_num = data.data.data.z_num// 累计订单

        /**
         * 访客数
         */
        this.durationUserOptions[0].datas = main.week_visitor
        this.durationUserOptions[1].datas = main.moth_visitor
        this.durationUserOptions[2].datas = main.year_visitor
        for (const uite of this.durationUserOptions) {
          var utotal = 0
          var udates = []
          var uvalues = []
          for (var uitem of uite.datas) {
            for (const key in uitem) {
              udates.push(key)
              var uv = parseFloat(uitem[key])
              utotal += uv
              uvalues.push(uv)
            }
          }
          var users = {}
          users.names = udates
          users.values = uvalues
          uite.data = users
          uite.total = utotal
        }
        this.loadUserReport(this.userIndex)

        // 通知公告
        this.dataMain.sysNotice = main.sysNotice

        // 新订单
        this.dataMain.newOrders = main.new_order
          .map((item) => {
            var date = new Date(item.createDate)
            item.timeDay = date.getFullYear() + '.' + date.getMonth() + '.' + date.getDate()
            item.timeTime = (date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()) + ':' + date.getMinutes()
            return item
          })

        // 销量排行榜
        var z_product_salesList = main.z_product_salesList
        z_product_salesList.sort(this.compareRank('volume', true))
        const names = []
        const values = []
        for (let i = 0; i < z_product_salesList.length; i++) {
          names.push(z_product_salesList[i].product_title)
          values.push(z_product_salesList[i].volume)
        }
        const products = {}
        products.names = names
        products.values = values
        this.showProductRank(products)
        // 订单数据
        this.onDurationOrderChange(this.orderIndex)
      })
    },
    // 参数1 是排序用的字段， 参数2 是：是否升序排序 true 为升序，false为降序
    compareRank(attr, rev) {
      // console.log(attr, rev)
      if (rev == undefined) {
        rev = 1
      } else {
        rev = (rev) ? 1 : -1
      }
      return (a, b) => {
        a = a[attr]
        b = b[attr]
        if (a < b) {
          return rev * -1
        }
        if (a > b) {
          return rev * 1
        }
        return 0
      }
    },
    // 营业额
    showTunover(turnover) {
      var option = {
        textStyle: {
          fontSize: 12 * this.fontSizeScale
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          textStyle: {
            fontSize: 12 * this.fontSizeScale
          }
        },
        xAxis: {
          name: '时间',
          nameLocation: 'start',
          nameTextStyle: {
            padding: [25 * this.fontSizeScale, 5 * this.fontSizeScale, 0, 0],
            color: '#D3F5FF'
          },
          data: turnover.dates,
          axisLine: {
            lineStyle: {
              width: 2,
              color: '#384A60',
              showMinLabel: true
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#D3F5FF',
            fontSize: 12 * this.fontSizeScale
          }
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              color: '#33818181',
              type: 'dashed'
            }
          },
          axisLine: {
            lineStyle: {
              width: 2,
              color: '#384A60'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#D3F5FF',
            fontSize: 12 * this.fontSizeScale
          }
        },
        grid: {
          top: 20 * this.fontSizeScale,
          bottom: 20 * this.fontSizeScale,
          left: 50 * this.fontSizeScale,
          right: 20 * this.fontSizeScale
        },
        series: [{
          name: '营业额',
          type: 'bar',
          barWidth: (140 / turnover.dates.length) * this.fontSizeScale, // 数据个数越多，柱状图宽度越小
          label: {
            show: false,
            position: 'top',
            color: '#43EDF8',
            formatter: '{c}'
          },
          itemStyle: {
            barBorderRadius: 10 * this.fontSizeScale,
            color: new this.$echarts.graphic.LinearGradient(
              0, 0, 0, 1,
              [
                { offset: 0, color: '#43EDF8' },
                { offset: 1, color: '#43AEF8' }
              ]
            )
          },
          data: turnover.values
        }]
      }
      this.myChartTunover = this.$echarts.init(document.getElementById('turnoverTJT'))
      this.myChartTunover.setOption(option)
      this.myChartDataTunover = turnover
    },
    /* 访客图*/
    showGraphUser(list) {
      const option = {
        color: this.colors,
        textStyle: {
          fontSize: 12 * this.fontSizeScale
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}{c}人<br/>占比{d}%',
          textStyle: {
            fontSize: 12 * this.fontSizeScale
          }
        },
        legend: {
          right: 'right',
          top: 'top',
          orient: 'vertical',
          itemGap: 10 * this.fontSizeScale,
          itemWidth: 25 * this.fontSizeScale,
          itemHeight: 14 * this.fontSizeScale,
          textStyle: {
            color: '#fff',
            fontSize: 12 * this.fontSizeScale
          },
          formatter: function(name) {
            return name
          }
        },
        grid: {
          containLabel: true
        },
        series: [
          {
            name: '访客',
            type: 'pie',
            // radius: ['40%', '70%'],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              show: true,
              // formatter: '{value|{c}人},占比{d}%',
              formatter: '{c}人({d}%)',
              textStyle: {
                fontSize: 12 * this.fontSizeScale
              },
              rich: {
                name: {
                  fontSize: 12
                },
                value: {
                  fontSize: 12
                }
              }
            },
            data: list
          }
        ]
      }
      this.myChartUser = this.$echarts.init(document.getElementById('usersPie'))
      this.myChartUser.setOption(option)
      this.myChartDataUser = list
    },
    /* 访客图*/
    loadUserReport(index) {
      this.userIndex = index
      const data = this.durationUserOptions[index].data
      if (data) {
        this.showUserGraph(data)
      }
    },
    showUserGraph(data) {
      const orderDate = data.names
      const orderNums = data.values
      const orderOption = {
        textStyle: {
          fontSize: 12 * this.fontSizeScale,
          color: '#D3F5FF'
        },
        xAxis: {
          name: '时间',
          nameLocation: 'start',
          nameTextStyle: {
            padding: [25 * this.fontSizeScale, 5 * this.fontSizeScale, 0, 0]
          },
          data: orderDate,
          axisLine: {
            lineStyle: {
              width: 2,
              color: '#384A60'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            fontSize: 12 * this.fontSizeScale
          }
        },
        yAxis: {
          name: '访问量',
          splitLine: {
            show: true,
            lineStyle: {
              color: '#33818181',
              type: 'dashed'
            }
          },
          nameTextStyle: {
            padding: [0, 25 * this.fontSizeScale, 0, 0]
          },
          axisLine: {
            lineStyle: {
              width: 2,
              color: '#384A60'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            fontSize: 12 * this.fontSizeScale
          },
          min: 0,
          minInterval: 1
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: '{b}<br/>{a}:{c}',
          textStyle: {
            fontSize: 12 * this.fontSizeScale
          }
        },
        grid: {
          top: 40 * this.fontSizeScale,
          bottom: 20 * this.fontSizeScale,
          left: 50 * this.fontSizeScale,
          right: 20 * this.fontSizeScale
        },
        color: '#4FCDCC',
        series: {
          name: '访问量',
          data: orderNums,
          type: 'line',
          smooth: true,
          symbol: 'circle',
          label: {
            show: false,
            position: 'top',
            formatter: '{c}',
            color: '#43EDF8'
          },
          areaStyle: {
            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1,
              [
                { offset: 0, color: 'rgba(67,237,248,0.8)' },
                { offset: 1, color: '#43edf81A' }
              ]
            )
          }
        }
      }
      this.myChartUser = this.$echarts.init(document.getElementById('usersPie'))
      this.myChartUser.setOption(orderOption)
      this.myChartDataUser = data
    },
    toOrderDetail(value) {
      if (value == '退货') {
        this.$router.push({
          path: '/order/salesReturn'
        })
      } else {
        this.$router.push({
          path: '/order/orderList/orderLists',
          query: {
            value: value
          }
        })
      }
    },

    toNoticeDetail(id) {
      this.$router.push({
        path: '/mall/systemNotice/noticeDetail',
        query: {
          id: id
        }
      })
    },
    // 营业额周期变化
    onDurationSaleChange(index) {
      this.turnoverIndex = index
      this.showTunover(this.durationSaleOptions[index].turnover)
    },
    // 订单数周期变化
    onDurationOrderChange(index) {
      const orderType = this.durationOptions[index].value
      this.loadOrder(orderType)
    },
    // 访客周期变化
    onDurationUserChange(index) {
      this.userIndex = index
      const data = this.durationUserOptions[index].data
      if (data) {
        this.showUserGraph(data)
      }
    },
    // 获取订单数据
    async loadOrder(orderType) {
      await index({
        api: 'admin.data.getOrderReport',
        type: orderType
      }).then(data => {
        // 报表数据
        const orderList = data.data.data.list
        const orderNums = []
        const orderDate = []
        for (let i = 0; i < orderList.length; i++) {
          orderNums.push(orderList[i].sum)
          orderDate.push(orderList[i].rdate)
        }
        this.showOrderGraph({ names: orderDate, values: orderNums })
      })
    },
    showOrderGraph(data) {
      const orderDate = data.names
      const orderNums = data.values
      const orderOption = {
        textStyle: {
          fontSize: 12 * this.fontSizeScale,
          color: '#D3F5FF'
        },
        xAxis: {
          name: '时间',
          nameLocation: 'start',
          nameTextStyle: {
            padding: [25 * this.fontSizeScale, 5 * this.fontSizeScale, 0, 0]
          },
          data: orderDate,
          axisLine: {
            lineStyle: {
              width: 2,
              color: '#384A60'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            fontSize: 12 * this.fontSizeScale
          }
        },
        yAxis: {
          name: '数量',
          splitLine: {
            show: true,
            lineStyle: {
              color: '#33818181',
              type: 'dashed'
            }
          },
          nameTextStyle: {
            padding: [0, 25 * this.fontSizeScale, 0, 0]
          },
          axisLine: {
            lineStyle: {
              width: 2,
              color: '#384A60'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            fontSize: 12 * this.fontSizeScale
          },
          min: 0,
          minInterval: 1
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: '{b}<br/>{a}:{c}',
          textStyle: {
            fontSize: 12 * this.fontSizeScale
          }
        },
        grid: {
          top: 40 * this.fontSizeScale,
          bottom: 20 * this.fontSizeScale,
          left: 50 * this.fontSizeScale,
          right: 20 * this.fontSizeScale
        },
        color: '#4FCDCC',
        series: {
          name: '订单数量',
          data: orderNums,
          type: 'line',
          smooth: true,
          symbol: 'circle',
          label: {
            show: false,
            position: 'top',
            formatter: '{c}',
            color: '#43EDF8'
          },
          areaStyle: {
            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1,
              [
                { offset: 0, color: 'rgba(67,237,248,0.8)' },
                { offset: 1, color: '#43edf81A' }
              ]
            )
          }
        }
      }
      this.myChartOrder = this.$echarts.init(document.getElementById('ddTJT'))
      this.myChartOrder.setOption(orderOption)
      this.myChartDataOrder = data
    },
    // 销量排行榜
    showProductRank(data) {
      const names = data.names
      const values = data.values
      const option = {
        textStyle: {
          fontSize: 14 * this.fontSizeScale
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          },
          textStyle: {
            fontSize: 14 * this.fontSizeScale
          }
        },
        xAxis: {
          type: 'value',
          show: false
        },
        yAxis: {
          type: 'category',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#BAE8F6',
            formatter: function(value, index) {
              var paramsNameNumber = value.length// 原始标签长度
              var provideNumber = 10// 每行能显示的字的个数
              if (paramsNameNumber > provideNumber) {
                return value.substring(0, provideNumber) + '...'
              } else {
                return value
              }
            },
            fontSize: 14 * this.fontSizeScale
          },
          data: names
        },
        grid: {
          top: 0,
          bottom: 0,
          left: 0,
          containLabel: true
        },
        series: [
          {
            name: '销量',
            type: 'bar',
            barWidth: 14 * this.fontSizeScale,
            itemStyle: {
              barBorderRadius: 6 * this.fontSizeScale,
              color: new this.$echarts.graphic.LinearGradient(
                0, 0, 1, 1,
                [
                  { offset: 0, color: '#43AEF8' },
                  { offset: 1, color: '#43EDF8' }
                ]
              )
            },
            label: {
              show: true,
              position: 'right',
              color: '#43EDF8'
            },
            data: values
          }
        ]
      }
      this.myChartProductRank = this.$echarts.init(document.getElementById('graphSalesRank'))
      this.myChartProductRank.setOption(option)
      this.myChartDataProductRank = data
    },
    // 重绘曲线图
    reDrawGraph() {
      if (this.myChartDataTunover) { // 营业额
        this.showTunover(this.myChartDataTunover)
        this.myChartTunover.resize()
      }
      if (this.myChartDataUser) { // 访客图
        // this.showGraphUser(this.myChartDataUser)
        this.showUserGraph(this.myChartDataUser)
        this.myChartUser.resize()
      }
      if (this.myChartDataOrder) { // 订单数据
        this.showOrderGraph(this.myChartDataOrder)
        this.myChartOrder.resize()
      }
      if (this.myChartDataProductRank) { // 销量排行榜
        this.showProductRank(this.myChartDataProductRank)
        this.myChartProductRank.resize()
      }
    }
  }
}
</script>

<style scoped lang="less">
.main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: #fff;
  position: relative;
}

#fcytMap {
  width: 100%;
  height: 100%;
}

.title {
  width: 51.25rem;
  height: 6.0625rem;
  top: 0;
  position: absolute;
  z-index: 1000;
}

.showtop {
  z-index: 1000;
  top: 6.875rem;
}

.verticalList {
  display: flex;
  flex-direction: column;
  width: 24.8125rem;

  .verticalItem {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 0.0625rem solid rgba(60, 157, 247, 0.6);
    background-color: rgba(9, 22, 47, 0.75);
    box-shadow: 0 0 5px 1px rgba(60, 157, 247, 0.6);

    &:not(:first-child) {
      margin-top: 1.5rem;
    }

    .graphBar {
      display: flex;
      flex-direction: row;
      background-color: rgba(38, 82, 150, 0.2);
      justify-content: space-between;
      padding: 0.6875rem 1.5rem;
      height: 2.875rem;
      align-items: center;

      div {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .graphTitle {
        color: #8ADAFF;
        font-size: 1.25rem;
        margin-left: 0.5rem;
      }

      .btn {
        color: #BAE8F6;
        font-size: 1.125rem;
        margin-right: 0.3125rem;
      }

      .el-icon-arrow-down:before {
        color: #BAE8F6 !important;
      }

    }

    .subTitle {
      font-size: 1.25rem;
      line-height: 1.25rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 0.625rem;
      margin-top: 0.625rem;
    }

    /deep/ .el-select {
      .el-input__inner {
        background-color: transparent !important;
        background: transparent !important;
        color: #BAE8F6 !important;
        border-width: 0rem !important;
        width: 5.625rem;
        font-size: 1.125rem;
      }

      .el-input__icon {
        color: #BAE8F6 !important;
      }
    }
  }
}

.news {
  ul {
    list-style: none;
    padding: 0rem 0.625rem;

    li {
      &:not(:first-child) {
        margin-top: 0.5625rem;
      }

      .item:hover {
        background-color: #ccc;

        .newsTitle {
          color: black;
        }

        .newsTime {
          color: black;
        }
      }
    }

    li .item {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      height: 2.875rem;
      border-radius: 0.25rem;
      padding-left: 0.625rem;
      padding-right: 0.625rem;

      .newsTitle {
        color: white;
        font-size: 0.875rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical
      }

      .newsTime {
        color: white;
        font-size: 0.875rem;
        font-family: "微软雅黑";
        text-align: end;
      }
    }
  }
}

.horizontalList {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  .horizontalItem {
    position: relative;
    width: 10.0625rem;
    height: 10.0625rem;
    display: flex;
    flex-direction: column;
    border: 0.0625rem solid rgba(0, 225, 198, 0.6);
    background-color: rgba(22, 61, 74, 0.75);
    box-shadow: 0 0 5px 1px rgba(0, 225, 198, 0.6);

    &:not(:first-child) {
      margin-left: 2.125rem;
    }

    &:first-child {
      //margin-left: 29px;
    }

    &:last-child {
      //margin-right: 29px;
    }

    .itemTitle {
      color: #62DDC7;
      font-size: 1.25rem;
      font-weight: bold;
      line-height: 1.25rem;
      margin-top: 0.9375rem;
      margin-left: 0.6875rem;
    }

    .itemValue {
      color: white;
      font-size: 2.25rem;
      font-weight: bold;
      width: 100%;
      text-align: center;
      margin: auto;
    }
  }

  .container-header {
    &::after {
      border-color: #00E1C6;
    }
  }

  .container-footer {
    &::before {
      border-color: #00E1C6;
    }
  }
}

.container-header {
  //&::before {
  //  content: '';
  //  // .container 设置了 position: relative 之后，
  //  // 这里的 position: absolute 就是相对于 .container 的了
  //  // 这样之后，top: 0; left: 0; 自然就是 .container 的左上角了
  //  position: absolute;
  //  // 给伪类内容设置宽高，这样边框 border 的宽高也就是这个了
  //  width: 24px;
  //  height: 28px;
  //  top: -4px;
  //  left:-4px;
  //  border-top: 4px solid #40CCDD;
  //  border-left: 4px solid #40CCDD;
  //}
  &::after {
    content: '';
    position: absolute;
    width: 1.5rem;
    height: 1.75rem;
    top: -0.25rem;;
    right: -0.25rem;
    border-top: 0.25rem solid #40CCDD;
    border-right: 0.25rem solid #40CCDD;
    border-radius: 2px;
  }
}

.container-footer {
  &::before {
    content: '';
    position: absolute;
    width: 1.5rem;
    height: 1.75rem;
    bottom: -0.25rem;;
    left: -0.25rem;;
    border-bottom: 0.25rem solid #40CCDD;
    border-left: 0.25rem solid #40CCDD;
    border-radius: 2px;
  }

  //&::after {
  //  content: '';
  //  position: absolute;
  //  width: 24px;
  //  height: 28px;
  //  bottom: -4px;;
  //  right: -4px;;
  //  border-bottom: 4px solid #40CCDD;
  //  border-right: 4px solid #40CCDD;
  //}
}

.graph {
  padding: 0.625rem;
  flex-grow: 1;
}

.iconImg {
  width: 1.5rem;
  height: 1.5rem;
}

.newOrderItem {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 2.625rem;
  margin: 0rem 0.625rem;
  border-radius: 0.25rem;

  .projectArea {
    width: 25%;
    color: white;
    font-size: 0.875rem;
    text-align: center;
  }

  .name {
    color: #5CB0F5;
    font-size: 0.77125rem !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; // 想要超出三行显示 就把这里改成3就好了
  }

  .money {
    color: #ECE269;
    font-size: 0.77125rem
  }

  .user {
    color: #ADF27B;
    font-size: 0.77125rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; // 想要超出三行显示 就把这里改成3就好了
  }

  .time {
    color: #70E7EF;
    font-size: 0.77125rem;
    div {
      line-height: 1.2;
    }
  }
}
</style>

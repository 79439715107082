import Vue from 'vue'
import Router from 'vue-router'
// import store from '../store'
Vue.use(Router)

/* Layout */
import Layout from '@/layout'
import fa from "element-ui/src/locale/lang/fa";
// import { render } from 'less'

// 公共路由
export const constantRoutes = [
  // 登录
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },

  // 打印
  {
    path: '/print',
    component: () => import('@/views/print/index'),
    hidden: true
  },

  // 大屏看板
  {
    name: 'homemap',
    path: '/mall/homemap',
    component: () => import('@/views/home/homeMap'),
    hidden: true
  },
  // 404
  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },
  // 表单渲染
  {
    name: 'formRender',
    path: '/formRender',
    component: () => import('@/views/plug_ins/customForm/formRender'),
    hidden: true
  },
  // 表单渲染
  {
    name: 'poster',
    path: '/poster',
    component: () => import('@/views/plug_ins/selectionContest/poster'),
    hidden: true
  },
  {
    name:'studentform',
    path:'/studentform',
    component:() => import('@/views/plug_ins/selectionContest/studentform')
  },
  // 第三方平台
  {
    path: '/wxthird',
    name: 'wx-third',
    component: () => import('@/views/wxThird'),
    children: [
      {
        path: 'reg',
        name: 'wxThridRegister',
        component: () => import('@/views/wxThird/register'),
        meta: {
          title: '注册小程序'
        }
      },
      {
        path: 'rel',
        name: 'wxThridRelevancy',
        component: () => import('@/views/wxThird/relevancy'),
        meta: {
          title: '关联小程序'
        }
      },
      {
        path: 'wpp',
        name: 'wxThridWPP',
        component: () => import('@/views/wxThird/wpp'),
        meta: {
          title: '复用公众号资质开通小程序'
        }
      }
    ]
  },
  // 首页
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [{
      path: 'dashboard',
      name: 'Dashboard',
      component: () => import('@/views/dashboard/index'),
      meta: { title: '首页', icon: 'dashboard' }
    }]
  }

]

// 动态路由
export const asyncRoutes = [

]

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

router.$addRoutes = (params) => {
  router.matcher = new Router({ mode: 'hash' }).matcher
  router.addRoutes(params)
}
export default router

<template>
  <div class="mainSideBar">
    <div class="left-nav">
      <ul class="side-nav">
        <!-- <li v-for="(route,index) in routes" :key="index" class="side-nav-item" @mouseenter="mouseenter(route)" @mouseleave="mouseleave"> -->
        <li v-for="(route,index) in routes" :key="index" class="side-nav-item">
          <div class="nav-maininfo" :class="{active: activeName === route.name}" @click="change(route)">
            <div class="icon-font" @click="handleSelect(route.name,route.children[0],0)">
              <img :src="(activeName === route.name || toggle === route.name) ? route.meta.icon[1] : route.meta.icon[0]" alt="">
              <span>{{ route.meta.title }}</span>
            </div>
            <i v-show="toggle === route.name" class="icon" />
            <!-- <span v-if="route.meta.title === '订单'" class="red-dot" /> -->
          </div>

          <ul class="nav-info">
            <li v-for="(child,index) in route.children" v-show="child.meta.is_core == 1" :key="index" @click="handleSelect(`${route.name}/${child.path}`,child,1);change(route)">
              <span :class="{ active : child.name === $route.path.split('/')[2]}">
                {{ generateTitle(child.meta.title) }}
                <i v-if="child.meta.title === '订单列表'" class="orderListNum">({{ orderListNum }})</i>
                <i v-if="child.meta.title === '退货列表'" class="salesReturnListNum">({{ refundListNum }})</i>
              </span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="right-nav" :class="classObj">
      <SideItem class="itemBar" :main-nav="mainNav" :class="classobj" :item="navList" />
    </div>
    <hamburger :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />

  </div>
</template>

<script>
import SideItem from './sideItem'
import { getStorage, setStorage, removeStorage } from '@/utils/storage'
import request from '@/api/https'
import { isEmpty } from 'element-ui/src/utils/util'
import Layout from '@/layout/index'
import router from '@/router'
import { orderCount } from '@/api/plug_ins/preSale'
import { mapGetters } from 'vuex'
import Hamburger from '@/components/Hamburger'

export default {
  name: 'MainSideBar',
  components: {
    SideItem,
    Hamburger
  },
  data() {
    return {
      toggle: '',
      navList: [
        { path: 'homeReport', name: 'homeReport', meta: { title: '商城首页', is_core: 1 } }
      ],
      mainNav: 'home',
      activeName: ''
    }
  },

  computed: {
    ...mapGetters([
      'sidebar'
    ]),
    device() {
      return this.$store.state.app.device
    },
    item() {
      return this.routes[0].children
    },
    // activeName() {
    //   return this.$route.path.split('/')[1]
    // },
    routes() {
      return this.$store.getters.permission_routes.filter(item => {
        if (item.meta) {
          return item
        }
      })
    },
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    sidebar() {
      return this.$store.state.app.sidebar
    },
    classObj() {
      return {
        active: this.sidebar.opened,
        actives: !this.sidebar.opened
      }
    },
    classobj() {
      return {
        active: this.sidebar.opened,
        actives: !this.sidebar.opened
      }
    },
    opened() {
      return this.sidebar.opened
    },

    orderListNum() {
      return this.$store.getters.orderListNum
    },

    refundListNum() {
      return this.$store.getters.refundListNum
    }
  },

  watch: {
    opened() {
      if (this.opened) {
        this.toggle = this.activeName
      } else {
        this.toggle = ''
      }
    },

    '$route.path'() {
      let mainPath = this.$route.path.split('/')[1];
      if(this.activeName != mainPath){
        this.routes.some(item => {
          if (item.name == mainPath) {
            this.navList = item.children
            return true
          }
        })
      }
      this.activeName = mainPath
      this.toggle = mainPath
      this.mainNav = mainPath
    }
  },
  created() {
    // this.getAsyncRoutes().then(res => {
    //   console.log(res);
    //   if(getStorage('laike_admin_userInfo').type == 1) {
    //     res = res.filter(item => {
    //       if(item.meta.title !== '平台') {
    //         return item
    //       }
    //     })
    //   }
    //   this.$store.commit('permission/SET_FIRSTROUTES',res)
    //   console.log(router);
    //   router.addRoutes(res)
    // })
    this.activeName = this.$route.path.split('/')[1]
    if (this.$store.state.permission.addRoutes.some(item => {
      return item.name == 'home'
    })) {
      // debugger
      if (this.$route.path.split('/')[1] !== 'home') {
        // this.$router.replace('/home/homeReport')
      }
      this.$store.state.permission.addRoutes.map(item => {
        // if (item.name == 'home') {
        if (item.name == this.activeName) {
          this.mainNav = this.activeName
          this.navList = item.children
        }
      })
    } else {
      // const routes = this.$store.state.permission.addRoutes[0]
      // this.activeName = routes.name
      // this.toggle = routes.name
      // this.navList = routes.children
      // this.mainNav = routes.name
      // if (this.$route.path.split('/')[1] !== routes.name) {
      //   this.$router.replace(routes.redirect)
      // }
      // 解决页面刷新页面跳转首页问题
      this.mainNav = this.$route.matched[0].name
      this.$store.state.permission.addRoutes.map(item => {
        if (item.name == this.$route.matched[0].name) {
          this.navList = item.children
        }
      })
      console.log(this.$route)
      console.log('路由-----------------------',this.navList)
    }
  },

  methods: {
    // 发货订单数量
    async orderCounts() {
      const list = await orderCount({
        api: 'admin.preSellRecord.order.orderCount'

      })
      setStorage('inOrderNum', list.data.data.inOrderNum)
    },
    // 切换菜单栏
    handleSelect(index, child, type) {
      // if(index == 'plug_ins/seckill') {
      //   let token = getStorage('laike_admin_userInfo').token
      //   let storeId = getStorage('rolesInfo').storeId
      //   let type = getStorage('rolesInfo').type
      //   window.location.href = 'http://192.168.0.23:9530/?token='+token + '&storeId=' + storeId + '&type=' + type
      // } else {
      //   this.$router.push('/' + index);
      // }
      // 如果是一级菜单和移动模式 一级菜单点击不跳转
      if (type === 0 && this.device === 'mobile') return

      // if (index == 'mall/homemap') {
      //   this.$openDataBoard()
      //   return
      // }

      this.$router.push('/' + index)
      // 移动端通知关闭菜单
      if (this.device === 'mobile') {
        this.$emit('closeSlidebarByMobile')
      }
      // setStorage('menuId',child.id)
      if (index == 'plug_ins/seckill') {
        this.$store.dispatch('orderNum/getOrderSecCount')
      }
      if (index == 'plug_ins/integralMall') {
        this.$store.dispatch('orderNum/getOrderInCount')
      }
      if (index == 'plug_ins/preSale') {
        this.orderCounts()
      }
    },

    getAsyncRoutes() {
      const res = []
      return request({
        method: 'post',
        params: {
          api: 'saas.role.getAsyncRoutesByRoutes'
        }
      }).then(routes => {
        if (routes.data.code == '200') {
          const route = routes.data.data
          console.log(route, '菜单权限')
          if (route.menu.length !== 0) {
            const list = []
            route.menu.forEach((menu, index) => {
              const icons = []
              if (!isEmpty(menu.image)) {
                icons.push(menu.image)
              }
              if (!isEmpty(menu.image1)) {
                icons.push(menu.image1)
              }
              // 一级菜单
              const topMenu = {
                path: '/' + menu.module,
                component: Layout,
                redirect: '/' + menu.module + '/' + menu.children[0].module,
                name: menu.module,
                meta: { title: menu.title, icon: icons }
              }
              // 递归子菜单
              topMenu.children = this.getMenus(menu.children)

              res.push(topMenu)
              menu.children.forEach((item, i) => {
                list.push(item)
              })
            })
            setStorage('route', list)
          }
          return res
        }
      })
    },
    // 菜单递归
    getMenus(menuList) {
      if (isEmpty(menuList)) {
        return []
      }
      menuList.forEach((currentMenu, index) => {
        const childrenMenu = {
          path: currentMenu.module,
          name: currentMenu.module,
          meta: { title: currentMenu.title, is_core: currentMenu.is_core },
          id: currentMenu.id
        }
        // 是否有子菜单
        if (!currentMenu.isChildren) {
          childrenMenu.component = resolve => require([`@/views${currentMenu.url}`], resolve)
        } else {
          childrenMenu.redirect = currentMenu.url
          childrenMenu.component = {
            render(c) {
              return c('router-view')
            }
          }
          // 继续递归
          childrenMenu.children = this.getMenus(currentMenu.children)
        }
        menuList[index] = childrenMenu
      })
      return menuList
    },

    mouseenter(value) {
      this.toggle = value.name
    },

    mouseleave() {
      this.toggle = this.activeName
      if (!this.opened) {
        this.toggle = ''
      }
    },

    change(value) {
      this.navList = value.children
      this.mainNav = value.name
      this.toggle = value.name
    },

    generateTitle(title) {
      const hasKey = this.$te('route.' + title)
      if (hasKey) {
        const translatedTitle = this.$t('route.' + title)
        return translatedTitle
      }
      return title
    },

    toggleSideBar() {
      if (this.device === 'mobile') {
        this.$emit('toggleSideBar')
      } else {
        this.$store.dispatch('app/toggleSideBar')
      }
    },
  }
}
</script>

<style scoped lang="scss">
.mainSideBar {
  height: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  z-index: 101;
  .left-nav {
    width: 80px;
    height: 100vh;
    // background-color: #343e4c;
    z-index: 100;
    position: relative;
    box-shadow: 0 3px 10px 0 rgba(0,0,0,.06);
    background: #fff;
    // &::after{
    //   content: "︾";
    //   display: block;
    //   color: #fff;
    //   text-align: center;
    //   font-family: serif;
    //   font-size: 24px;
    //   position: absolute;
    //   bottom:10vh;
    //   left:0;
    //   width: 100%;
    //   opacity: .1;
    //   animation: more 1s infinite alternate;
    // }
    .side-nav {
      border-bottom: none !important;
      margin: 0;
      padding: 0;
      overflow-y: auto;
      height: 87vh;
      &::-webkit-scrollbar {
      display: none;
    }

      .side-nav-item {
        width: 70px;
        height: 80px;
        margin: 0 0 10px 0;
        padding: 0;
        // color: #b2bcd1;
        font-size: 14px;
        // &.active .nav-info {
        //   display: block;
        // }
        // &:hover .nav-info {
        //   display: block;
        // }
        .nav-maininfo {
          width: 70px;
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          &:hover {
            opacity: .6;
          }
          &.active {
            // background-color: #161c24;
            color: #06c377;
          }
          .icon-font {
            width: 70px;
            height: 80px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            img {
              width: 28px;
              height: 28px;
              margin-bottom: 6px;
            }
            span {
              // font-size: 16px;
              font-weight: normal;
            }
          }
          i {
            content: "";
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border: 8px solid transparent;
            border-right-color: #ffffff;
          }

          .red-dot {
            display: inline-block;
            width: 10px;
            height: 10px;
            background-color: red;
            border: 1px solid red;
            border-radius: 50%;
            position: absolute;
            top: 17px;
            right: 7px;
          }
        }

        .nav-info {
          width: 150px;
          height: calc(100vh - 100px);
          position: fixed;
          left: 70px;
          top: 60px;
          display: none;
          background-color: #fff;
          color: #414658;
          margin: 0;
          padding: 10px;
          box-shadow: 3px 0px 3px rgba(185, 183, 183, 0.1);
          z-index: 10000;
          li {
            width: 130px;
            height: 40px;
            line-height: 40px;
            margin: 8px 0;
            margin: 8px 0;
            border-radius: 2px;
            &.active span {
              background-color: #e9f4ff;
              color: #06c377;
            }
            &:hover {
              color: #06c377 !important;
            }
            span {
              display: block;
              width: 100%;
              height: 100%;
              padding-left: 6px;
              font-size: 16px;
              &.active {
                background-color: #e9f4ff;
                color: #06c377;
              }
            }

            .orderListNum,
            .salesReturnListNum {
              font-style: normal;
              color: red;
              /* margin-left: 8px; */
            }
          }
        }
      }
    }
  }

  .right-nav {
    width: 150px;
    border-right: 1px solid #f1f2f4;
    // height: calc(100vh - 100px);
    height: 100vh;
    // position: fixed;
    // left: 70px;
    transition: left 0.28s ease;
    z-index: 1;
    &.active {
      // left: 70px;
    }
    &.actives {
      // left: -80px;
      // height: calc(100vh - 100px);
      height: 100vh;
    }
    .itemBar {
      &.active {
        display: block;
      }
      &.actives {
        display: none;
      }
    }
  }
}
@media screen and(max-width:768px) {
  .mainSideBar
    .left-nav
    .side-nav
    .side-nav-item
    .nav-maininfo
    .icon-font
    span {
    font-size: 14px;
  }
  .mainSideBar .left-nav .side-nav .side-nav-item .nav-info li span {
    font-size: 14px;
  }
}
@keyframes more {
  from{opacity: 0.1;}
  to{opacity: 1;}

}
.hamburger-container {
      width: 70px;
      height: 70px;
      line-height: 70px;
      cursor: pointer;
      transition: background 0.3s;
      -webkit-tap-highlight-color: transparent;
      padding: 0 !important;
      position: absolute;
      bottom: 0;
      left:0;
      z-index: 200;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background:rgba(0, 0, 0, 0.025);
      }
    }
</style>

const imgSizeData = {
  // 测试环境
  DEV: {
    // 机构
    JG: {
      // 资讯版
      wxbd6797fe309814ac: {
        skinForRed: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 326],
          FEATURE_NEWSTYPEICON: [80, 80],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [690, 388],
          // 功能模块-底部导航
          FEATURE_TABBAR: [81, 81],
          // 功能模块-UI导航
          FEATURE_UIICON: [80, 80],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 512],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 336],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
        skinForBlue: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 326],
          FEATURE_NEWSTYPEICON: [80, 80],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [690, 388],
          // 功能模块-底部导航
          FEATURE_TABBAR: [81, 81],
          // 功能模块-UI导航
          FEATURE_UIICON: [80, 80],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 512],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 336],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
      },
      // 换肤版
      wxd69136f76bb343b4: {
        // 蓝色版
        skinForBlue: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 326],
          FEATURE_NEWSTYPEICON: [80, 80],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [690, 300],
          // 功能模块-底部导航
          FEATURE_TABBAR: [81, 81],
          // 功能模块-UI导航
          FEATURE_UIICON: [80, 80],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 512],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
        // 绿色版
        skinForGreen: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 326],
          FEATURE_NEWSTYPEICON: [80, 80],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [690, 300],
          // 功能模块-底部导航
          FEATURE_TABBAR: [81, 81],
          // 功能模块-UI导航
          FEATURE_UIICON: [80, 80],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 512],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
      },
      // 农商版
      wx8dc6e917f8ac562f: {
        // 农商
        nsSkinForGreen: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 326],
          FEATURE_NEWSTYPEICON: [80, 80],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [640, 250],
          // 功能模块-底部导航
          FEATURE_TABBAR: [81, 81],
          // 功能模块-UI导航
          FEATURE_UIICON: [80, 80],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 512],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [690, 350],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
      },
      // 活动版
      wx8910f7a2e18cfaeb: {
        skinForCommon: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 326],
          FEATURE_NEWSTYPEICON: [80, 80],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [750, 540],
          // 功能模块-底部导航
          FEATURE_TABBAR: [81, 81],
          // 功能模块-UI导航
          FEATURE_UIICON: [118, 118],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 336],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
      },
      // 分销版
      wxc94f04d339f1114d: {
        // 绿色版
        skinForGreen: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 326],
          FEATURE_NEWSTYPEICON: [80, 80],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [750, 450],
          // 功能模块-底部导航
          FEATURE_TABBAR: [40, 40],
          // 功能模块-UI导航
          FEATURE_UIICON: [80, 80],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 512],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
        // 蓝色版
        skinForBlue: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 326],
          FEATURE_NEWSTYPEICON: [80, 80],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [750, 450],
          // 功能模块-底部导航
          FEATURE_TABBAR: [40, 40],
          // 功能模块-UI导航
          FEATURE_UIICON: [80, 80],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 512],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
        // 医疗版
        skinForMedical: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 326],
          FEATURE_NEWSTYPEICON: [80, 80],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [750, 450],
          // 功能模块-底部导航
          FEATURE_TABBAR: [40, 40],
          // 功能模块-UI导航
          FEATURE_UIICON: [80, 80],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 512],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
      },
    },
    // 展厅
    ZT: {
      // 换肤
      wxb4eaf06f15d99610: {
        skinForRed: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 320],
          FEATURE_NEWSTYPEICON: [80, 80],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [700, 300],
          // 功能模块-底部导航
          FEATURE_TABBAR: [81, 81],
          // 功能模块-UI导航
          FEATURE_UIICON: [60, 60],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 432],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
        skinForBlue: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 320],
          FEATURE_NEWSTYPEICON: [40, 40],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [700, 300],
          // 功能模块-底部导航
          FEATURE_TABBAR: [81, 81],
          // 功能模块-UI导航
          FEATURE_UIICON: [60, 60],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 432],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
        skinForGreen: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 320],
          FEATURE_NEWSTYPEICON: [40, 40],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [700, 300],
          // 功能模块-底部导航
          FEATURE_TABBAR: [81, 81],
          // 功能模块-UI导航
          FEATURE_UIICON: [60, 60],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 432],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
      },
      // 农商
      wx4a6ca07b4342399d: {
        skinForGreen: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 320],
          FEATURE_NEWSTYPEICON: [80, 80],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [690, 300],
          // 功能模块-底部导航
          FEATURE_TABBAR: [81, 81],
          // 功能模块-UI导航
          FEATURE_UIICON: [60, 60],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 432],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [690, 350],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
      },
      // 商业
      wx7fbb868a6c958196: {
        skinForRed: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 326],
          FEATURE_NEWSTYPEICON: [80, 80],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [750, 450],
          // 功能模块-底部导航
          FEATURE_TABBAR: [81, 81],
          // 功能模块-UI导航
          FEATURE_UIICON: [80, 80],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 432],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
        skinForBlue: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 320],
          FEATURE_NEWSTYPEICON: [40, 40],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [750, 450],
          // 功能模块-底部导航
          FEATURE_TABBAR: [81, 81],
          // 功能模块-UI导航
          FEATURE_UIICON: [60, 60],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 432],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
        skinForGreen: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 320],
          FEATURE_NEWSTYPEICON: [40, 40],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [750, 450],
          // 功能模块-底部导航
          FEATURE_TABBAR: [81, 81],
          // 功能模块-UI导航
          FEATURE_UIICON: [60, 60],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 432],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
      },
      // 跨境电商版
      wx9e7ccc9986b782ad: {
        skinForRed: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 326],
          FEATURE_NEWSTYPEICON: [80, 80],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [750, 450],
          // 功能模块-底部导航
          FEATURE_TABBAR: [81, 81],
          // 功能模块-UI导航
          FEATURE_UIICON: [80, 80],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 432],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
        skinForBlue: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 320],
          FEATURE_NEWSTYPEICON: [40, 40],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [700, 300],
          // 功能模块-底部导航
          FEATURE_TABBAR: [81, 81],
          // 功能模块-UI导航
          FEATURE_UIICON: [60, 60],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 432],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
        skinForGreen: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 320],
          FEATURE_NEWSTYPEICON: [40, 40],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [700, 300],
          // 功能模块-底部导航
          FEATURE_TABBAR: [81, 81],
          // 功能模块-UI导航
          FEATURE_UIICON: [60, 60],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 432],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
      },
      // 商务局
      wxd7eff2078f0dd4a8: {
        skinForRed: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 326],
          FEATURE_NEWSTYPEICON: [80, 80],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [750, 560],
          // 功能模块-底部导航
          FEATURE_TABBAR: [162, 162],
          // 功能模块-UI导航
          FEATURE_UIICON: [192, 192],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 572],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [300, 300],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
      },
    },
  },
  // 线上环境
  PROD: {
    // 机构
    JG: {
      // 分销版
      wx4666eb878a070765: {
        // 绿色版
        skinForGreen: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 326],
          FEATURE_NEWSTYPEICON: [80, 80],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [750, 450],
          // 功能模块-底部导航
          FEATURE_TABBAR: [40, 40],
          // 功能模块-UI导航
          FEATURE_UIICON: [80, 80],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 512],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
        // 蓝色版
        skinForBlue: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 326],
          FEATURE_NEWSTYPEICON: [80, 80],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [750, 450],
          // 功能模块-底部导航
          FEATURE_TABBAR: [40, 40],
          // 功能模块-UI导航
          FEATURE_UIICON: [80, 80],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 512],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
        // 医疗版
        skinForMedical: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 326],
          FEATURE_NEWSTYPEICON: [80, 80],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [750, 450],
          // 功能模块-底部导航
          FEATURE_TABBAR: [40, 40],
          // 功能模块-UI导航
          FEATURE_UIICON: [80, 80],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 512],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
      },
      // 资讯版
      wxedcdfdfaf8e44387: {
        skinForRed: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 326],
          FEATURE_NEWSTYPEICON: [80, 80],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [690, 388],
          // 功能模块-底部导航
          FEATURE_TABBAR: [81, 81],
          // 功能模块-UI导航
          FEATURE_UIICON: [80, 80],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 336],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
        skinForBlue: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 326],
          FEATURE_NEWSTYPEICON: [80, 80],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [690, 388],
          // 功能模块-底部导航
          FEATURE_TABBAR: [81, 81],
          // 功能模块-UI导航
          FEATURE_UIICON: [80, 80],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 336],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
      },
      // 设计院版
      wx85b01aa421172986: {
        DEFAULT: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 420],
          FEATURE_NEWSTYPEICON: [80, 80],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [690, 388],
          // 功能模块-底部导航
          FEATURE_TABBAR: [81, 81],
          // 功能模块-UI导航
          FEATURE_UIICON: [80, 80],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 512],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
      },
      // 换肤版
      wxcf9e25e36a7171f2: {
       
        // 蓝色
        skinForBlue: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 326],
          FEATURE_NEWSTYPEICON: [80, 80],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [690, 300],
          // 功能模块-底部导航
          FEATURE_TABBAR: [81, 81],
          // 功能模块-UI导航
          FEATURE_UIICON: [80, 80],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 512],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
        // 绿色
        skinForGreen: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 326],
          FEATURE_NEWSTYPEICON: [80, 80],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [690, 300],
          // 功能模块-底部导航
          FEATURE_TABBAR: [81, 81],
          // 功能模块-UI导航
          FEATURE_UIICON: [80, 80],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 512],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
      },
      // 农商
      wxb996b6c472cddae8: {
        nsSkinForGreen: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 420],
          FEATURE_NEWSTYPEICON: [80, 80],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [690, 300],
          // 功能模块-底部导航
          FEATURE_TABBAR: [81, 81],
          // 功能模块-UI导航
          FEATURE_UIICON: [80, 80],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 512],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [690, 350],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
      },
    },
    // 展厅
    ZT: {
      // 展厅-商业
      wxeb7c500698c689d5: {
        skinForRed: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 320],
          FEATURE_NEWSTYPEICON: [80, 80],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [750, 450],
          // 功能模块-底部导航
          FEATURE_TABBAR: [81, 81],
          // 功能模块-UI导航
          FEATURE_UIICON: [88, 88],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 432],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
        skinForBlue: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 320],
          FEATURE_NEWSTYPEICON: [80, 80],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [750, 450],
          // 功能模块-底部导航
          FEATURE_TABBAR: [81, 81],
          // 功能模块-UI导航
          FEATURE_UIICON: [88, 88],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 432],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
        skinForGreen: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 320],
          FEATURE_NEWSTYPEICON: [80, 80],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [750, 450],
          // 功能模块-底部导航
          FEATURE_TABBAR: [81, 81],
          // 功能模块-UI导航
          FEATURE_UIICON: [88, 88],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 432],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
      },
      // 换肤
      wxc1403d3c10a230dc: {
        skinForRed: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 320],
          FEATURE_NEWSTYPEICON: [80, 80],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [700, 300],
          // 功能模块-底部导航
          FEATURE_TABBAR: [81, 81],
          // 功能模块-UI导航
          FEATURE_UIICON: [88, 88],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 432],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
        skinForBlue: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 320],
          FEATURE_NEWSTYPEICON: [80, 80],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [700, 300],
          // 功能模块-底部导航
          FEATURE_TABBAR: [81, 81],
          // 功能模块-UI导航
          FEATURE_UIICON: [88, 88],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 432],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
        skinForGreen: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 320],
          FEATURE_NEWSTYPEICON: [80, 80],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [700, 300],
          // 功能模块-底部导航
          FEATURE_TABBAR: [81, 81],
          // 功能模块-UI导航
          FEATURE_UIICON: [88, 88],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 432],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
      },
      // 农商
      wx5eb51579d25505aa: {
        skinForGreen: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 320],
          FEATURE_NEWSTYPEICON: [80, 80],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [690, 300],
          // 功能模块-底部导航
          FEATURE_TABBAR: [81, 81],
          // 功能模块-UI导航
          FEATURE_UIICON: [60, 60],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 432],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [150, 150],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [690, 350],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
      },
      // 商务局
      wxa5a19df319f49aa1: {
        skinForRed: {
          // 需求活动-封面图
          FEATURE_DEMANDCOVER: [750, 530],
          // 功能模块-会议活动
          FEATURE_MEETPOSTERS: [690, 406],
          // 功能模块-资讯
          FEATURE_NEWSCOVER: [690, 380],
          FEATURE_NEWSTYPEBANNER: [750, 326],
          FEATURE_NEWSTYPEICON: [80, 80],
          // 功能模块-轮播图
          FEATURE_SWIPPER: [750, 560],
          // 功能模块-底部导航
          FEATURE_TABBAR: [162, 162],
          // 功能模块-UI导航
          FEATURE_UIICON: [192, 192],
          // 功能模块-分享图
          FEATURE_SHAREIMG: [500, 400],
          // 功能模块-沙龙
          FEATURE_SHARONICON: [100, 100],
          FEATURE_SHARONCOVER: [750, 430],
          // 功能模块-店铺
          FEATURE_SHOPLOGO: [200, 200],
          // 功能模块-背景图
          FEATURE_BACKGROUND: [750, 572],
          // 功能模块-服务专区
          FEATURE_SERVICEAREA: [300, 300],
          // 机构
          JG_JGCATELOGO: [80, 80],
          JG_JGLOGO: [200, 200],
          JG_BASIC_LOGO: [100, 100],
          JG_BASIC_HEAD: [100, 100],
          JG_BASIC_ABOUTUS: [750, 530],
          JG_BASIC_PCBG: [864, 910],
          // 产品
          GOODS_CATE_ICON: [24, 24],
          GOODS_BRAND_LOGO: [120, 120],
          GOODS_COVER: [500, 500],
          GOODS_VIEWS: [750, 750],
          // 员工
          AUTHORITY_PHOTO: [200, 200],
          // 广告位管理
          AD_COVER: [630, 690],
          // 学生信息照片
          STUDENT_PHOTO: [295, 413],
          // 学生信息证书
          CERTIFICATE: [690, 390],
        },
      },
    },
  },
};
export default imgSizeData;

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wxthird-header" }, [
    _c("ul", [
      _c("li", [_vm._v(_vm._s(_vm.name))]),
      _c(
        "li",
        { staticClass: "sign-out", on: { click: _vm.signOut } },
        [
          _c("el-icon", {
            staticClass: "el-icon-switch-button",
            attrs: { title: "退出" },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import axios from 'axios'
import ElementUI from 'element-ui'
import { removeUserInfo } from '@/utils/storage'

const http = axios.create({
  timeout: 5000
})

if (process.env.NODE_ENV == 'development') {
  http.defaults.baseURL = 'api'
  // http.defaults.baseURL = 'http://192.168.1.125:18001/gw'
  // http.defaults.baseURL = 'http://power.fcmall.net/gw'
} else {
  http.defaults.baseURL = process.env.VUE_APP_BASE_API
}

// 配置请求拦截器
http.interceptors.request.use(config => {
  return config
}, err => {
  return Promise.reject(err)
})

// 配置响应拦截器
http.interceptors.response.use((res) => {
  if (res.data.code == '200') {
    ElementUI.Message({
      message: '登录成功',
      type: 'success',
      offset: 100
    })
  } else {
    ElementUI.Message({
      message: res.data.message,
      type: 'error',
      offset: 100
    })
  }
  return res
}, (error) => {
  console.error(error)
  ElementUI.Message({
    message: error,
    type: 'error',
    offset: 100
  })
})

export default http

<template>
  <div>
    <vue-editor
      ref="myQuillEditor"
      v-model="content"
      use-custom-image-handler
      :editor-options="editorOptions"
      @image-added="doImageAdded"
      @focus="onSelectionChange"
    />
    <!--视频上传弹窗-->
    <div>
      <el-dialog
        :close-on-click-modal="false"
        width="50%"
        style="margin-top: 1px"
        title="视频上传"
        :visible.sync="videoForm.show"
        append-to-body
      >
        <el-tabs v-model="videoForm.activeName">
          <el-tab-pane label="本地视频上传" name="first">
            <el-upload
              v-loading="loading"
              style="text-align: center"
              drag
              :action="uploadVideoConfig.uploadUrl"
              accept="video/*"
              :name="uploadVideoConfig.name"
              :before-upload="onBeforeUploadVideo"
              :on-success="onSuccessVideo"
              :on-error="onErrorVideo"
              :multiple="false"
              :http-request="doVideoAdded"
              :on-preview="onViewVideo"
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
              <!-- <div slot="tip" class="el-upload__tip">只能上传MP4文件，且不超过{{ uploadVideoConfig.maxSize }}M</div> -->
            </el-upload>
          </el-tab-pane>
          <el-tab-pane label="添加视频链接" name="second">
            <el-input
              v-model="videoForm.videoLink"
              placeholder="请输入视频链接"
              clearable
            />
            <el-button
              type="primary"
              size="small"
              style="margin: 20px 0px 0px 0px"
              @click="insertVideoLink(videoForm.videoLink)"
              >确认
            </el-button>
          </el-tab-pane>
        </el-tabs>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { VueEditor, Quill } from "vue2-editor";
import axios from "axios";
import { getStorage } from "@/utils/storage";
import Config from "@/packages/apis/Config";
import "@/styles/editorOption.css";

let Size = Quill.import("attributors/style/size");
Size.whitelist = [
  false,
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "30px",
  "32px",
  "36px",
  "48px",
  "72px",
];
Quill.register(Size, true);

import Video from "@/utils/editorVideo";
Quill.register(Video, true);

// 行高
const Parchment = Quill.import("parchment");
class lineHeightAttributor extends Parchment.Attributor.Style {}
const lineHeightStyle = new lineHeightAttributor("lineHeight", "line-height", {
  scope: Parchment.Scope.INLINE,
  whitelist: ["1", "1.5", "1.75", "2", "3", "4", "5"],
});
Quill.register({ "formats/lineHeight": lineHeightStyle }, true);

const initPStyle = " margin: 0; font-weight: normal";
const titleConfig = [
  { Choice: ".ql-bold", title: "加粗" },
  { Choice: ".ql-italic", title: "斜体" },
  { Choice: ".ql-underline", title: "下划线" },
  { Choice: ".ql-header", title: "段落格式" },
  { Choice: ".ql-strike", title: "删除线" },
  { Choice: ".ql-blockquote", title: "块引用" },
  { Choice: ".ql-code", title: "插入代码" },
  { Choice: ".ql-code-block", title: "插入代码段" },
  { Choice: ".ql-font", title: "字体" },
  { Choice: ".ql-size", title: "字体大小" },
  { Choice: '.ql-list[value="ordered"]', title: "编号列表" },
  { Choice: '.ql-list[value="bullet"]', title: "项目列表" },
  { Choice: '.ql-list[value="check"]', title: "任务列表" },
  { Choice: ".ql-direction", title: "文本方向" },
  { Choice: '.ql-header[value="1"]', title: "h1标题" },
  { Choice: '.ql-header[value="2"]', title: "h2标题" },
  { Choice: ".ql-align", title: "左对齐" },
  { Choice: ".ql-align[value='center']", title: "中间对齐" },
  { Choice: ".ql-align[value='right']", title: "右对齐" },
  { Choice: ".ql-align[value='justify']", title: "两端对齐" },
  { Choice: ".ql-color", title: "字体颜色" },
  { Choice: ".ql-background", title: "背景颜色" },
  { Choice: ".ql-image", title: "图像" },
  { Choice: ".ql-video", title: "视频" },
  { Choice: ".ql-link", title: "添加链接" },
  { Choice: ".ql-formula", title: "插入公式" },
  { Choice: ".ql-clean", title: "清除字体格式" },
  { Choice: '.ql-script[value="sub"]', title: "下标" },
  { Choice: '.ql-script[value="super"]', title: "上标" },
  { Choice: '.ql-indent[value="-1"]', title: "向左缩进" },
  { Choice: '.ql-indent[value="+1"]', title: "向右缩进" },
  { Choice: ".ql-header .ql-picker-label", title: "标题大小" },
  { Choice: '.ql-header .ql-picker-item[data-value="1"]', title: "标题一" },
  { Choice: '.ql-header .ql-picker-item[data-value="2"]', title: "标题二" },
  { Choice: '.ql-header .ql-picker-item[data-value="3"]', title: "标题三" },
  { Choice: '.ql-header .ql-picker-item[data-value="4"]', title: "标题四" },
  { Choice: '.ql-header .ql-picker-item[data-value="5"]', title: "标题五" },
  { Choice: '.ql-header .ql-picker-item[data-value="6"]', title: "标题六" },
  { Choice: ".ql-header .ql-picker-item:last-child", title: "标准" },
  { Choice: '.ql-size .ql-picker-item[data-value="small"]', title: "小号" },
  { Choice: '.ql-size .ql-picker-item[data-value="large"]', title: "大号" },
  { Choice: '.ql-size .ql-picker-item[data-value="huge"]', title: "超大号" },
  { Choice: ".ql-size .ql-picker-item:nth-child(2)", title: "标准" },
  { Choice: ".ql-align .ql-picker-item:first-child", title: "居左对齐" },
  {
    Choice: '.ql-align .ql-picker-item[data-value="center"]',
    title: "居中对齐",
  },
  {
    Choice: '.ql-align .ql-picker-item[data-value="right"]',
    title: "居右对齐",
  },
  {
    Choice: '.ql-align .ql-picker-item[data-value="justify"]',
    title: "两端对齐",
  },
  {
    Choice: '.ql-format',
    title: "一键排版",
  },
];

export default {
  name: "CusEdit",
  components: {
    VueEditor,
  },
  props: {
    content: {
      type: String,
    },
    // 图片上传配置
    uploadImageConfig: {
      type: Object,
      default() {
        return {
          uploadUrl: Config.baseUrl, // 上传地址
          maxSize: 3, // 上传图片大小限制，默认不超过3M
          name: "file", // 图片上传字段
          params: {
            api: "resources.file.uploadFiles",
            groupId: -1,
            uploadType: 2,
            storeId: getStorage("laike_admin_userInfo").storeId,
            accessId: this.$store.getters.token,
          },
        };
      },
    },
    // 视频上传配置
    uploadVideoConfig: {
      type: Object,
      default() {
        return {
          uploadUrl: Config.baseUrl, // 上传地址
          // maxSize: 20, // 上传视频大小限制，默认不超过20M
          name: "file", // 图片上传字段
          params: {
            api: "resources.file.uploadFiles",
            groupId: -1,
            uploadType: 2,
            storeId: getStorage("laike_admin_userInfo").storeId,
            accessId: this.$store.getters.token,
          },
        };
      },
    },
  },
  data() {
    const _self = this;
    return {
      loading: false, // 加载loading
      editorOptions: {
        modules: {
          toolbar: {
            container: [
              [{ header: [false, 1, 2, 3, 4, 5, 6] }],
              [
                {
                  size: [
                    "12px",
                    "14px",
                    "16px",
                    "18px",
                    "20px",
                    "30px",
                    "32px",
                    "36px",
                    "48px",
                  ],
                },
              ],
              [{ lineHeight: lineHeightStyle.whitelist }],
              ["bold", "italic", "underline", "strike"], // toggled buttons
              [{ color: [] }, { background: [] }], // dropdown with defaults from theme
              [
                { align: "" },
                { align: "center" },
                { align: "right" },
                { align: "justify" },
              ],
              ["blockquote", "code-block"],
              [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
              [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
              ["link", "image", "video"],
              ["clean"], // remove formatting button
              ["format"],
            ],
            handlers: {
              video: () => {
                console.log("show video");
                _self.videoForm.show = true;
              },
              format: () => {
                // 获取富文本编辑区的DOM
                let childDoms =
                  this.$refs.myQuillEditor.$refs.quillContainer.childNodes[0]
                    .childNodes;

                if (childDoms.length > 0) {
                  childDoms.forEach((item) => {
                    // // 处理ul,ol
                    if (item.nodeName == "UL" || item.nodeName == "OL") {
                      item.childNodes.forEach((itemUl) => {
                        itemUl.innerHTML = itemUl.innerText;
                      });
                      return;
                    }

                    // 去除空行
                    if (item.innerText.indexOf("\n") >= 0) {
                      item.remove();
                    }
                    // 富文本编辑的内容默认外层都是由p标签包容，所以直接在p标签上进行设置默认排版的样式
                    if (item.nodeName == "P") {
                      if (item.innerHTML.indexOf("<img src=") < 0) {
                        // 去除空格
                        item.innerHTML = item.innerText
                          .trim()
                          .replace(/\s+/g, "");
                        item.style.cssText = initPStyle;
                      } else {
                        if (item.childNodes.length > 0) {
                          // 遍历找到子元素中存在img标签的内容进行设置默认样式
                          item.childNodes.forEach((child) => {
                            if (child.localName == "img") {
                              child.style.cssText = "margin:0 auto";
                            }
                          });
                        }
                      }
                    }
                    // 处理标题
                    let hArr = ["H1", "H2", "H3", "H4", "H5", "H6", "H7"];
                    if (hArr.includes(item.nodeName)) {
                      item.innerHTML = item.innerText
                        .trim()
                        .replace(/\s+/g, "");
                      console.log(item);
                    }
                  });
                }
              },
            },
          },
        },
      },
      // 视频上传变量
      videoForm: {
        show: false, // 显示插入视频链接弹框的标识
        videoLink: "",
        activeName: "first",
      },
    };
  },
  watch: {
    content(newVal, oldVal) {
      this.$emit("update:content", newVal);
    },
  },
  mounted() {
    this.autoTip();
  },
  methods: {
    // 自定义图片上传
    doImageAdded(file, editor, cursorLocation, resetUploader) {
      console.log("doImageAdded:", editor, cursorLocation);
      const isLt3M = file.size / 1024 / 1024 < 3;
      if (!isLt3M) {
        return this.$message.error("上传图片大小不能超过 3MB!");
      }
      var formData = new FormData();
      formData.append("file", file); // 第一个file 后台接收的参数名
      axios({
        url: this.uploadImageConfig.uploadUrl, // 上传路径
        method: "POST",
        params: this.uploadImageConfig.params,
        data: formData,
      })
        .then((result) => {
          console.log("doImageAdded result:", result);
          if (result.data.code == "200") {
            const url = result.data.data.imgUrls[0]; // 返回给你的图片路径
            editor.insertEmbed(cursorLocation, "image", url);
            // 定位光标
            editor.setSelection(cursorLocation + 1);
            console.log("doImageAdded after:", this.content);
            resetUploader();
          } else {
            this.$message.error(result.data.message);
          }
        })
        .catch((err) => {
          console.log("doImageAdded ERR:", err);
        });
    },
    // 插入视频
    insertVideoLink(videoLink) {
      console.log("insertVideoLink:", videoLink);
      if (!videoLink) return this.$message.error("视频地址不能为空！");
      this.videoForm.show = false;
      const editor = this.$refs["myQuillEditor"].quill;
      // 获取富文本
      // const range = editor.getSelection();
      // 获取光标位置：当编辑器中没有输入文本时，这里获取到的 range 为 null
      // const index = range ? range.index : 0;
      const index = editor.selection.savedRange.index;
      // 在光标所在位置 插入视频
      editor.insertEmbed(index, "video", videoLink);
      // 调整光标到最后
      editor.setSelection(index + 1);
      console.log("insertVideoLink after:", editor, index, this.content);
    },
    // el-文件上传组件
    onBeforeUploadVideo(file) {
      this.loading = true;
      // const acceptArr = ['video/mp4']
      // const isVideo = acceptArr.includes(file.type)
      // // const isLt1M = file.size / 1024 / 1024 < this.uploadVideoConfig.maxSize
      // if (!isVideo) {
      //   this.hideLoading()
      //   this.$message.error('只能上传mp4格式文件!')
      // }
      // if (!isLt1M) {
      //   this.hideLoading()
      //   this.$message.error(`上传文件大小不能超过 ${this.uploadVideoConfig.maxSize}MB!`)
      // }
      // return isLt1M && isVideo
      // return isVideo
    },
    // 自定义视频上传
    doVideoAdded(param) {
      console.log("doVideoAdded:", param);
      const formData = new FormData();
      formData.append("file", param.file);
      axios({
        url: this.uploadImageConfig.uploadUrl, // 上传路径
        method: "POST",
        params: this.uploadImageConfig.params,
        data: formData,
      })
        .then((res) => {
          if (res.data.code == "200") {
            param.onSuccess(res.data.data.imgUrls[0]); // 上传成功的图片会显示绿色的对勾
          } else {
            param.onError(res.data.message);
          }
        })
        .catch((response) => {
          console.log("上传失败", response);
          param.onError();
        });
    },
    hideLoading() {
      this.loading = false;
    },
    // 文件上传成功时的钩子
    onSuccessVideo(val) {
      console.log("onSuccessVideo:", val);
      this.hideLoading();
      this.insertVideoLink(val);
    },
    // 文件上传失败时的钩子
    onErrorVideo(val) {
      console.log("onErrorVideo:", val);
      this.hideLoading();
      this.$message.error(val ? "上传失败:" + val : "上传失败");
    },
    onViewVideo(val) {
      console.log("onViewVideo:", val);
    },
    onSelectionChange(e) {},
    // 给工具栏添加提示
    autoTip() {
      for(let item of titleConfig){
        let tip = document.querySelector('.ql-formats ' + item.Choice)
        if(!tip) continue
        tip.setAttribute('title', item.title)
      }
    },
  },
};
</script>

<style scoped lang="less">
/deep/.ql-editor {
  // text-indent: 2em;
  //
  min-height: auto;
  // p{
  //   text-indent: 2em;
  // }
  p.ql-align-center,p.ql-align-right{
    text-indent: 0;
  }
}

/deep/.ql-editor::-webkit-scrollbar {
  width: 10px;
}
/deep/.ql-editor::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(151, 160, 180, 0.7);
}

/deep/.ql-formats {
  .ql-format {
    background: url(https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/423/1/20221102/1587734068213927936.png)
      no-repeat center;
    background-size: 18px;
    &:hover {
      background: url(https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/423/1/20221102/1587734086920523776.png)
        no-repeat center;
      background-size: 18px;
    }
  }
}
/deep/.ql-editor ol li:not(.ql-direction-rtl),
.ql-editor ul li:not(.ql-direction-rtl) {
  padding: 0;
}
/deep/.quillWrapper .ql-toolbar.ql-snow {
  flex-wrap: wrap;
  .ql-formats {
    display: flex;
    margin-right:0;
  }
}
/deep/.ql-container{
  height: 500px;
  overflow-y: auto;
}
/deep/.quillWrapper .ql-picker-label{
  font-size: 12px;
}
</style>

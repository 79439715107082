var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "list" },
      _vm._l(_vm.orderList, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "orderBox" },
          [
            _c("el-card", { attrs: { bordered: false } }, [
              _c(
                "div",
                {
                  staticClass: "clearfix",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [_c("span", [_vm._v("销售订单")])]
              ),
              _c("div", { staticClass: "grids" }, [
                _c("div", [
                  _c("span", [_vm._v("订单编号:")]),
                  _c("span", [_vm._v(_vm._s(item.list.data.sNo))]),
                ]),
                _c("div", [
                  _c("span", [_vm._v("支付方式:")]),
                  _c("span", [_vm._v(_vm._s(item.list.data.paytype))]),
                ]),
                _c("div", [
                  _c("span", [_vm._v("订单状态:")]),
                  _c("span", [_vm._v(_vm._s(item.list.detail[0].status))]),
                ]),
                _c("div", [
                  _c("span", [_vm._v("快递公司:")]),
                  _c("span", [
                    _vm._v(" " + _vm._s(_vm.getKuaidi(item.list.data)) + " "),
                  ]),
                ]),
                _c("div", [
                  _c("span", [_vm._v("订单来源:")]),
                  item.list.data.source == 1
                    ? _c("span", [_vm._v("微信小程序")])
                    : item.list.data.source == 2
                    ? _c("span", [_vm._v("App")])
                    : item.list.data.source == 3
                    ? _c("span", [_vm._v("支付宝小程序")])
                    : item.list.data.source == 4
                    ? _c("span", [_vm._v("头条小程序")])
                    : item.list.data.source == 5
                    ? _c("span", [_vm._v("百度小程序")])
                    : item.list.data.source == 6
                    ? _c("span", [_vm._v("pc端")])
                    : _c("span", [_vm._v("H5移动端")]),
                ]),
                _c("div", [
                  _c("span", [_vm._v("订单类型:")]),
                  _c("span", [_vm._v(_vm._s(_vm.isOrderType(item.list.data)))]),
                ]),
                _c("div", [
                  _c("span", [_vm._v("配送费用:")]),
                  item.list.z_freight > 0
                    ? _c("span", [_vm._v("￥" + _vm._s(item.list.z_freight))])
                    : _c("span", [_vm._v("免邮")]),
                ]),
                _c("div", [
                  _c("span", [_vm._v("快递单号:")]),
                  _c("span", [
                    _vm._v(_vm._s(_vm.getOrderCode(item.list.data))),
                  ]),
                ]),
                _c("div", [
                  _c("span", [_vm._v("收货人:")]),
                  _c("span", [_vm._v(_vm._s(item.list.data.name))]),
                ]),
                _c("div", [
                  _c("span", [_vm._v("联系电话:")]),
                  _c("span", [_vm._v(_vm._s(item.list.data.mobile))]),
                ]),
                _c("div", [
                  _c("span", [_vm._v("发货时间:")]),
                  item.list.data.deliver_time
                    ? _c("span", [_vm._v(_vm._s(item.list.data.deliver_time))])
                    : _c("span", [_vm._v("暂无")]),
                ]),
                _c("div", [
                  _c("span", [_vm._v("收货地址:")]),
                  _c("span", [_vm._v(_vm._s(item.list.data.address))]),
                ]),
                _c("div", [
                  _c("span", [_vm._v("下单时间:")]),
                  item.list.data.add_time
                    ? _c("span", [_vm._v(_vm._s(item.list.data.add_time))])
                    : _c("span", [_vm._v("暂无")]),
                ]),
                _c("div", [
                  _c("span", [_vm._v("付款时间:")]),
                  item.list.data.pay_time
                    ? _c("span", [_vm._v(_vm._s(item.list.data.pay_time))])
                    : _c("span", [_vm._v("暂无")]),
                ]),
                _c("div", [
                  _c("span", [_vm._v("收货时间:")]),
                  item.list.data.arrive_time
                    ? _c("span", [_vm._v(_vm._s(item.list.data.arrive_time))])
                    : _c("span", [_vm._v("暂无")]),
                ]),
                _c("div", [
                  _c("span", [_vm._v("订单说明:")]),
                  _c("span", [_vm._v(_vm._s(item.list.data.remarks))]),
                ]),
              ]),
              item.list.data.status === "待付款"
                ? _c(
                    "div",
                    { staticClass: "dictionary-list" },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "table",
                          refInFor: true,
                          staticClass: "el-table",
                          staticStyle: { width: "100%" },
                          attrs: { data: item.list.detail, border: "" },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "p_id",
                              label: "商品ID",
                              "min-width": "12.5%",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "p_name",
                              label: "商品名称",
                              "min-width": "12.5%",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "mchName",
                              label: "店铺名称",
                              "min-width": "12.5%",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "size",
                              label: "商品规格",
                              "min-width": "12.5%",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "p_price",
                              label: "单价",
                              "min-width": "12.5%",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          "￥" + _vm._s(scope.row.p_price)
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "num",
                              label: "数量",
                              "min-width": "12.5%",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "freight",
                              label: "运费",
                              "min-width": "12.5%",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "p_price",
                              label: "小计",
                              "min-width": "12.5%",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              item.list.data.status !== "待付款"
                ? _c(
                    "div",
                    { staticClass: "dictionary-list" },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "table",
                          refInFor: true,
                          staticClass: "el-table",
                          staticStyle: { width: "100%" },
                          attrs: { data: item.list.detail, border: "" },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "p_id",
                              label: "商品ID",
                              "min-width": "16%",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "p_name",
                              label: "商品名称",
                              "min-width": "14%",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "mchName",
                              label: "店铺名称",
                              "min-width": "12.5%",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "size",
                              label: "商品规格",
                              "min-width": "14%",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "p_price",
                              label: "单价",
                              "min-width": "14%",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          "￥" + _vm._s(scope.row.p_price)
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "num",
                              label: "数量",
                              "min-width": "14%",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "freight",
                              label: "运费",
                              "min-width": "14%",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "p_price",
                              label: "小计",
                              "min-width": "14%",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "note" }, [
                _c("div", { staticClass: "top" }, [
                  _c("div", [
                    _c("p", [
                      _c("span", [_vm._v("商品总价：")]),
                      _c("span", [
                        _vm._v(
                          "￥" + _vm._s(_vm.getGoodsPrice(item.list.detail))
                        ),
                      ]),
                    ]),
                    _c("p", [
                      _c("span", [_vm._v("运费：")]),
                      _c("span", [
                        _vm._v(
                          "￥" + _vm._s(_vm.getAllFreight(item.list.detail))
                        ),
                      ]),
                    ]),
                    (item.list.data.otype == "GM" ||
                      item.list.data.otype == "") &&
                    Number(item.list.coupon_price) > 0
                      ? _c("p", [
                          _c("span", [_vm._v("店铺优惠：")]),
                          _c("span", [
                            _vm._v("- ￥" + _vm._s(item.list.coupon_price)),
                          ]),
                        ])
                      : _vm._e(),
                    (item.list.data.otype == "GM" ||
                      item.list.data.otype == "") &&
                    item.list.discount_type
                      ? _c("p", [
                          _c("span", [
                            _vm._v(_vm._s(item.list.discount_type) + "优惠："),
                          ]),
                          _c("span", [
                            _vm._v(
                              "- ￥" + _vm._s(item.list.preferential_amount)
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    item.list.data.otype != "GM" && item.list.data.otype != ""
                      ? _c("p", [
                          _c("span", [_vm._v("优惠金额：")]),
                          _c("span", [
                            _vm._v("- ￥" + _vm._s(item.list.coupon_price)),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _c("div", { staticClass: "bottom" }, [
                  _c("p", { staticClass: "bold" }, [
                    _c("span", [_vm._v("合计支付：")]),
                    _c("span", { staticClass: "red" }, [
                      _vm._v("￥" + _vm._s(item.list.detail[0].z_price)),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "footer" }, [
                _c("div", { staticClass: "print-info" }, [
                  _c("span", [_vm._v("打印时间：" + _vm._s(_vm.printTime))]),
                  _c("span", [_vm._v("操作者：" + _vm._s(item.list.operator))]),
                  _c("span"),
                ]),
                _c("div", { staticClass: "copyright" }, [
                  _c("span", [
                    _vm._v(" " + _vm._s(_vm.shopInfo.company) + " "),
                  ]),
                  _c("span", [
                    _vm._v(
                      " 地址：" +
                        _vm._s(
                          _vm.shopInfo.addressInfo &&
                            _vm.shopInfo.addressInfo.address_xq
                        ) +
                        " "
                    ),
                  ]),
                  _c("span", [
                    _vm._v(" 电话：" + _vm._s(_vm.shopInfo.work_mobile) + " "),
                  ]),
                ]),
              ]),
            ]),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
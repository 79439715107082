var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          ref: "upload",
          staticClass: "upload-file",
          attrs: {
            "on-preview": _vm.handlePreview,
            "on-remove": _vm.deleteFileItem,
            "file-list": _vm.fileLists,
            name: "file",
            action: "",
            "before-upload": _vm.beforeUpload,
            "http-request": _vm.customRequest,
          },
        },
        [_c("div", { staticClass: "upload-btn" }, [_vm._v("点击上传")])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router'
import ElementUI from 'element-ui'
// 引入VForm库
// import VForm from 'vform-builds'
// 引入VForm样式
// import 'vform-builds/dist/VFormDesigner.css'
import VForm from '../lib/vform/VFormDesigner.umd.min.js'
import '../lib/vform/VFormDesigner.css'

import '@/icons' // icon
import '@/permission' // permission control
import 'element-ui/lib/theme-chalk/index.css'
import '../theme/index.css'
// import locale from 'element-ui/lib/locale/lang/en' // lang i18n
import '@/styles/index.scss' // global css
import '@/styles/common.scss'
import '@/styles/icon.scss'
import 'normalize.css/normalize.css' // A modern alternative to CSS resets

if (process.env.NODE_ENV === 'production') {
  const { mockXHR } = require('../mock')
  mockXHR()
}

// 引入echarts图表
import echarts from 'echarts'
Vue.prototype.$echarts = echarts

import { openDataBoard, accountFrom } from '@/utils/utils'
Vue.prototype.$openDataBoard = openDataBoard
//节流
import throttle from '@/utils/throttle'
Vue.prototype.$throttle = throttle
//防抖
import debounce from '@/utils/debounce'
Vue.prototype.$debounce = debounce

import { http } from "@/api/plug_ins/plug_ins";
Vue.prototype.$http = http


import i18n from './lang'
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})
// 全局注册VForm(同时注册了v-form-designe、v-form-render等组件)
Vue.use(VForm)
import AsyncComputed from 'vue-async-computed'
Vue.use(AsyncComputed)
// 导入组件库
import lUpload from './packages/index'
import lTransfer from './packages/index'
import LPagination from './packages/index'
// 注册组件库
Vue.use(lUpload)
Vue.use(lTransfer)
Vue.use(LPagination)

// 查看图片
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
})

import '@/common/commonStyle/common.scss'
import publicFunction from '@/common/commonFunction/common'
Vue.use(publicFunction)
Vue.filter('accountFrom', accountFrom)
Vue.filter('dateFormat', function (val) {
  if (val === null || val === '') {
    return '暂无时间'
  }
  const d = new Date(val) // val 为表格内取到的后台时间
  const month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1
  const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate()
  const hours = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours()
  const min = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes()
  const sec = d.getSeconds() < 10 ? `0${d.getSeconds()}` : d.getSeconds()
  const times = `${d.getFullYear()}-${month}-${day} ${hours}:${min}:${sec}`
  return times
})

Vue.filter('dateFormat1', function (val) {
  if (val === null || val === '') {
    return '暂无时间'
  }
  const d = new Date(val) // val 为表格内取到的后台时间
  const month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1
  const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate()
  const times = `${d.getFullYear()}-${month}-${day}`
  return times
})
 // 格林威治时间转换为年月日+时分，解决IOS显示NAN
 Vue.filter('GMTConvertYMDHMS',function  (time) {
  var date = time.substr(0, 10); //年月日
  var hours = time.substring(11, 13);
  var minutes = time.substring(14, 16);
  var seconds = time.substring(17, 19);
  var timeFlag = date + ' ' + hours + ':' + minutes + ':' + seconds;
  timeFlag = timeFlag.replace(/-/g, "/");
  timeFlag = new Date(timeFlag);
  timeFlag = new Date(timeFlag.getTime() + 8 * 3600 * 1000);
  timeFlag = timeFlag.getFullYear() + '-' +
  ((timeFlag.getMonth() + 1) < 10 ? "0" + (timeFlag.getMonth() + 1) : (timeFlag.getMonth() + 1)) + '-' +
  (timeFlag.getDate() < 10 ? "0" + timeFlag.getDate() : timeFlag.getDate()) + ' ' +
  (timeFlag.getHours()< 10 ? '0' + timeFlag.getHours() : timeFlag.getHours()) + ':' +
  (timeFlag.getMinutes() < 10 ? '0' + timeFlag.getMinutes() : timeFlag.getMinutes()) + ':' +
  (timeFlag.getSeconds() < 10 ? "0" + timeFlag.getSeconds() : timeFlag.getSeconds());
  return timeFlag;
})
import './utils/rem'

// iView 和 iView Pro
import ViewUI from 'view-design'
import iViewPro from '@/libs/iview-pro/iview-pro.min.js'
import { modalSure } from '@/utils/public'
import modalForm from '@/utils/modalForm'
import formCreate from '@form-create/iview'
import 'iview/dist/styles/iview.css'
// 路由
import './styles/DIY/index.less'
import './libs/iview-pro/iview-pro.css'

import './assets/iconfont/iconfont.css'
import './assets/iconfont/iconfont2.css'
import './assets/iconfont/iconfont3.css'

// 懒加载
import VueLazyload from 'vue-lazyload'

Vue.config.productionTip = false

Vue.prototype.$modalForm = modalForm
Vue.prototype.$modalSure = modalSure
Vue.use(formCreate)

Vue.use(ViewUI)
Vue.use(iViewPro)
Vue.use(VueLazyload)

Vue.config.productionTip = false

// 总线程
Vue.prototype.$bus = new Vue()

new Vue({
  el: '#app',
  i18n,
  router,
  store,
  render: h => h(App)
})

<template>
  <div class="rel">
    <wxthird-header />
    <div v-if="isErr" class="box" @click="goto">
      <img src="https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20240626/1805809300835975168.png" alt="">
      <div v-html="errorTips"></div>
      <el-button type="primary">下一步</el-button>
    </div>
    <div v-if="isAll" class="download">
      正在关联小程序，请稍后。。。<br>
      <span>{{ num }}</span> 秒倒计时, 请不要关闭当前页面
    </div>
  </div>
</template>

<script>
import wxthirdHeader from '../head.vue'
import baseUrl from '@/utils/baseUrl'
import { bindMini } from '@/api/wxthird'
export default {
  components: {
    wxthirdHeader
  },
  data() {
    return {
      appid: this.$route.query.miniAppId || '',
      appName: this.$route.query.miniName || '',
      headUrl: this.$route.query.miniHeadUrl || '',
      signature: this.$route.query.miniSignature || '',
      principalName: this.$route.query.principalName || '',
      isErr: false,
      num: 3,
      errorTips: '请按照下面步骤完成小程序关联注册',
      isAll: false
    }
  },
  created() {
    console.log(this.$route.query.miniAppId)
    // 跳转授权扫码页面
    if (this.$route.query.miniAppId === undefined) {
      window.location.replace(baseUrl.openUrl)
      return
    }
    if (
      this.appid &&
      this.appName &&
      this.headUrl &&
      this.signature
    ) {
      // 获取到了4个值
      this.isAll = true
      this.download()
    } else {
      this.isErr = true
      this.isAll = false
      // appid 有值，可能无名称，logo，简介
      if (this.appid) {
        this.errorTips = '系统检测到您的小程序基础信息不完整，您需要：<p>1.请先完善基础信息</p><p>2.登录https://mp.weixin.qq.com，设置 > 第三方设置 > 解除授权</p><p>3.重新扫码授权</p>'
      } else {
        // appid 无值 表示 绑定了其他第三方平台
        this.errorTips = '系统检测到您关联了其他第三方平台，请尝试解除绑定后再试！'
      }
    }
  },
  methods: {
    // 绑定小程序
    async toBindMini() {
      const res = await bindMini({
        api: 'customer.wx.bind.mini',
        miniName: this.appName,
        miniAppId: this.appid,
        miniHeadUrl: this.headUrl,
        miniSignature: this.signature,
        principalName: this.principalName
      })
      console.log('绑定结果输出：', res)
      if (res.data.code == 200) {
        // 如果出现203（后端token失效，需要前端处理），处理流程分别在api/https>reponse下和login>merchantsLogin方法
        window.localStorage.getItem('relBindURL') ? window.localStorage.removeItem('relBindURL') : null
        // this.$message.success('关联成功！')
        this.$router.push('/')
      } else {
        this.isErr = true
        this.isAll = false
        this.errorTips = res.data.message || '请按照下面步骤完成小程序关联注册'
        this.appid = ''
        this.appName = ''
        this.headUrl = ''
        this.signature = ''
        this.principalName = ''
      }
    },
    goto() {
      window.open(baseUrl.openUrl)
    },
    download() {
      const djs = setInterval(() => {
        this.num--
        console.log(this.num)
      }, 1000)
      setTimeout(() => {
        clearInterval(djs)
        this.toBindMini()
      }, 3000)
    }
  }
}
</script>

<style scoped lang="less">
.rel {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; 
  background-size: 4px 4px;
  .box {
    line-height: 1.4; 
    text-align: center;
    img{
      width: 360px
    }
    h3 {
      font-size: 24px;
    }
    div{
      margin: 20px;
      font-size: 18px;
      line-height: 1.6;
      text-align: left;
      color: #000;
      p{

      }
    }
  }
  .download {
    text-align: center;
    font-size: 24px;
    span {
      color: #f56c6c;
    }
  }
}
@media screen and (max-width: 768px) {
  .rel {
    .box {
      width: 90%;
    }
  }
}
</style>
<style>
.rel .box div p{
  font-size: 14px;
  margin-top: 10px;
  color: #666;
}
</style>

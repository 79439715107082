var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mainSideBar" },
    [
      _c("div", { staticClass: "left-nav" }, [
        _c(
          "ul",
          { staticClass: "side-nav" },
          _vm._l(_vm.routes, function (route, index) {
            return _c("li", { key: index, staticClass: "side-nav-item" }, [
              _c(
                "div",
                {
                  staticClass: "nav-maininfo",
                  class: { active: _vm.activeName === route.name },
                  on: {
                    click: function ($event) {
                      return _vm.change(route)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "icon-font",
                      on: {
                        click: function ($event) {
                          return _vm.handleSelect(
                            route.name,
                            route.children[0],
                            0
                          )
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src:
                            _vm.activeName === route.name ||
                            _vm.toggle === route.name
                              ? route.meta.icon[1]
                              : route.meta.icon[0],
                          alt: "",
                        },
                      }),
                      _c("span", [_vm._v(_vm._s(route.meta.title))]),
                    ]
                  ),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.toggle === route.name,
                        expression: "toggle === route.name",
                      },
                    ],
                    staticClass: "icon",
                  }),
                ]
              ),
              _c(
                "ul",
                { staticClass: "nav-info" },
                _vm._l(route.children, function (child, index) {
                  return _c(
                    "li",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: child.meta.is_core == 1,
                          expression: "child.meta.is_core == 1",
                        },
                      ],
                      key: index,
                      on: {
                        click: function ($event) {
                          _vm.handleSelect(
                            route.name + "/" + child.path,
                            child,
                            1
                          )
                          _vm.change(route)
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          class: {
                            active:
                              child.name === _vm.$route.path.split("/")[2],
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.generateTitle(child.meta.title)) +
                              " "
                          ),
                          child.meta.title === "订单列表"
                            ? _c("i", { staticClass: "orderListNum" }, [
                                _vm._v("(" + _vm._s(_vm.orderListNum) + ")"),
                              ])
                            : _vm._e(),
                          child.meta.title === "退货列表"
                            ? _c("i", { staticClass: "salesReturnListNum" }, [
                                _vm._v("(" + _vm._s(_vm.refundListNum) + ")"),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]
                  )
                }),
                0
              ),
            ])
          }),
          0
        ),
      ]),
      _c(
        "div",
        { staticClass: "right-nav", class: _vm.classObj },
        [
          _c("SideItem", {
            staticClass: "itemBar",
            class: _vm.classobj,
            attrs: { "main-nav": _vm.mainNav, item: _vm.navList },
          }),
        ],
        1
      ),
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
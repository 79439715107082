var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reg" },
    [
      _c("wxthird-header"),
      _c(
        "div",
        { staticClass: "box" },
        [
          _c(
            "el-steps",
            {
              staticClass: "el-steps-1",
              attrs: { active: _vm.registerCurrent, simple: "" },
            },
            [
              _c("el-step", { attrs: { title: "基本信息" } }),
              _c("el-step", { attrs: { title: "微信认证授权" } }),
              _c("el-step", { attrs: { title: "填写小程序信息" } }),
            ],
            1
          ),
          _vm.registerCurrent == -1 && _vm.errorMes
            ? _c(
                "div",
                { staticClass: "errorTip" },
                [
                  _c("el-result", {
                    attrs: { icon: "error", title: _vm.errorMes },
                  }),
                ],
                1
              )
            : _c(
                "div",
                [
                  _vm.registerCurrent == 0
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form",
                            {
                              ref: "registerRuleForm",
                              staticClass: "form",
                              attrs: {
                                model: _vm.registerParam,
                                rules: _vm.registerRules,
                                "label-width": "150px",
                                disabled: true,
                              },
                            },
                            [
                              _vm.errorMessage
                                ? _c("div", { staticClass: "register-error" }, [
                                    _vm._v("*" + _vm._s(_vm.errorMessage)),
                                  ])
                                : _vm._e(),
                              _c(
                                "el-form-item",
                                {
                                  ref: "legalPersonaWechat",
                                  attrs: {
                                    label: "法人微信号",
                                    prop: "legalPersonaWechat",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: _vm.isDisabled },
                                    model: {
                                      value:
                                        _vm.registerParam.legalPersonaWechat,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.registerParam,
                                          "legalPersonaWechat",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "registerParam.legalPersonaWechat",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  ref: "legalPersonaName",
                                  attrs: {
                                    label: "法人姓名",
                                    prop: "legalPersonaName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: _vm.isDisabled },
                                    model: {
                                      value: _vm.registerParam.legalPersonaName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.registerParam,
                                          "legalPersonaName",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "registerParam.legalPersonaName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  ref: "principalName",
                                  attrs: {
                                    label: "机构名称",
                                    prop: "principalName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: _vm.isDisabled },
                                    model: {
                                      value: _vm.registerParam.principalName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.registerParam,
                                          "principalName",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "registerParam.principalName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  ref: "principalCode",
                                  attrs: {
                                    label: "统一社会信用代码",
                                    prop: "principalCode",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: _vm.isDisabled },
                                    model: {
                                      value: _vm.registerParam.principalCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.registerParam,
                                          "principalCode",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "registerParam.principalCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.registerCurrent == 1
                    ? _c(
                        "div",
                        { staticClass: "el-result" },
                        [
                          _c("el-icon", {
                            staticClass: "el-icon-warning-outline",
                            staticStyle: { "font-size": "36px" },
                          }),
                          _c("div", { staticClass: "title" }, [
                            _vm._v("温馨提示"),
                          ]),
                          _c("div", { staticClass: "sub-title" }, [
                            _vm._v(
                              "请在微信中完成认证授权，授权完成可点击下一步!"
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.registerCurrent == 2
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          staticClass: "step2",
                        },
                        [
                          _c(
                            "el-steps",
                            {
                              staticStyle: { cursor: "pointer" },
                              attrs: {
                                active: _vm.miniConfigActive,
                                "finish-status": "success",
                                direction: "vertical",
                              },
                            },
                            _vm._l(_vm.miniConfigList, function (item, index) {
                              return _c("el-step", {
                                key: index,
                                attrs: { title: item },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.changeMiniConfigActive(index)
                                  },
                                },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-form",
                            {
                              ref: "miniRuleForm",
                              staticClass: "form",
                              attrs: {
                                model: _vm.miniParams,
                                rules: _vm.miniRules,
                                "label-width": "20",
                              },
                            },
                            [
                              _vm.miniConfigActive == 0
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          ref: "miniName",
                                          attrs: {
                                            label: "",
                                            prop: "miniName",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "name-box" },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  disabled:
                                                    _vm.threeStep
                                                      .nickNameAuditstatus == 1,
                                                },
                                                model: {
                                                  value:
                                                    _vm.miniParams.miniName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.miniParams,
                                                      "miniName",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "miniParams.miniName",
                                                },
                                              }),
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "10px",
                                                  },
                                                  attrs: {
                                                    type: "primary",
                                                    disabled:
                                                      _vm.threeStep
                                                        .nickNameAuditstatus ==
                                                      1,
                                                  },
                                                  on: { click: _vm.checkName },
                                                },
                                                [_vm._v("检测名称是否可用")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm.miniNameObj.showMore
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "营业执照或组织代码证",
                                                required: "",
                                                prop: "licenseMediaid",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-upload",
                                                {
                                                  attrs: {
                                                    action: _vm.imgUrl,
                                                    data: {
                                                      appid:
                                                        _vm.threeStep.miniAppId,
                                                      type: "image",
                                                    },
                                                    limit: 1,
                                                    accept:
                                                      "image/png, image/jpeg",
                                                    "before-upload":
                                                      _vm.beforeUpload,
                                                    "file-list":
                                                      _vm.miniNameObj
                                                        .fileListLicense,
                                                    "on-success": function (
                                                      response,
                                                      file,
                                                      fileList
                                                    ) {
                                                      return _vm.getNameFile(
                                                        response,
                                                        file,
                                                        fileList,
                                                        "license"
                                                      )
                                                    },
                                                    "on-remove": function (
                                                      file,
                                                      fileList
                                                    ) {
                                                      return _vm.removeNameFile(
                                                        file,
                                                        fileList,
                                                        "license"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        size: "small",
                                                        type: "primary",
                                                      },
                                                    },
                                                    [_vm._v("点击上传")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "el-upload__tip",
                                                      attrs: { slot: "tip" },
                                                      slot: "tip",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " 只能上传jpg/png文件，且不超过1MB "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      false
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "管理员身份证照片",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-upload",
                                                {
                                                  attrs: {
                                                    action: _vm.imgUrl,
                                                    data: {
                                                      appid:
                                                        _vm.threeStep.miniAppId,
                                                      type: "image",
                                                    },
                                                    limit: 1,
                                                    accept:
                                                      "image/png, image/jpeg",
                                                    "before-upload":
                                                      _vm.beforeUpload,
                                                    "file-list":
                                                      _vm.miniNameObj
                                                        .fileListIdcard,
                                                    "on-success": function (
                                                      response,
                                                      file,
                                                      fileList
                                                    ) {
                                                      return _vm.getNameFile(
                                                        response,
                                                        file,
                                                        fileList,
                                                        "idCard"
                                                      )
                                                    },
                                                    "on-remove": function (
                                                      file,
                                                      fileList
                                                    ) {
                                                      return _vm.removeNameFile(
                                                        file,
                                                        fileList,
                                                        "idCard"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        size: "small",
                                                        type: "primary",
                                                      },
                                                    },
                                                    [_vm._v("点击上传")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "el-upload__tip",
                                                      attrs: { slot: "tip" },
                                                      slot: "tip",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " 只能上传jpg/png文件，且不超过1MB "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.miniNameObj.showMore
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "补充材料" } },
                                            [
                                              _c(
                                                "el-upload",
                                                {
                                                  attrs: {
                                                    action: _vm.imgUrl,
                                                    data: {
                                                      appid:
                                                        _vm.threeStep.miniAppId,
                                                      type: "image",
                                                    },
                                                    limit: 8,
                                                    accept:
                                                      "image/png, image/jpeg",
                                                    "before-upload":
                                                      _vm.beforeUpload,
                                                    "file-list":
                                                      _vm.miniNameObj
                                                        .fileListFJ,
                                                    "on-success": function (
                                                      response,
                                                      file,
                                                      fileList
                                                    ) {
                                                      return _vm.getNameFile(
                                                        response,
                                                        file,
                                                        fileList,
                                                        "FJ"
                                                      )
                                                    },
                                                    "on-remove": function (
                                                      file,
                                                      fileList
                                                    ) {
                                                      return _vm.removeNameFile(
                                                        file,
                                                        fileList,
                                                        "FJ"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        size: "small",
                                                        type: "primary",
                                                      },
                                                    },
                                                    [_vm._v("点击上传")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "el-upload__tip",
                                                      attrs: { slot: "tip" },
                                                      slot: "tip",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " 只能上传jpg/png文件，且不超过1MB "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.miniNameObj.tips
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "name-tips",
                                              class: _vm.miniNameObj.status
                                                ? "suc"
                                                : "err",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.miniNameObj.tips) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "p",
                                            { staticClass: "name-tips" },
                                            [
                                              _vm._v(
                                                " 帐号名称长度为4-30个字符，一个中文字等于2个字符。 "
                                              ),
                                            ]
                                          ),
                                      _vm.threeStep.nickNameAuditstatus != 1 &&
                                      (_vm.miniNameObj.status ||
                                        (_vm.miniNameObj.showMore &&
                                          _vm.miniParams.licenseMediaid))
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "primary" },
                                              on: { click: _vm.submitName },
                                            },
                                            [_vm._v("提交")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.miniConfigActive == 1
                                ? _c(
                                    "el-form-item",
                                    {
                                      ref: "miniHead",
                                      attrs: { label: "", prop: "miniHead" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            position: "relative",
                                            "margin-bottom": "10px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-upload",
                                            {
                                              attrs: {
                                                action: _vm.imgUrl,
                                                data: {
                                                  appid:
                                                    _vm.threeStep.miniAppId,
                                                  type: "image",
                                                },
                                                accept: "image/png, image/jpeg",
                                                "before-upload":
                                                  _vm.beforeUpload,
                                                "show-file-list": false,
                                                "on-success": _vm.getLogoFile,
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    size: "small",
                                                    type: "primary",
                                                  },
                                                },
                                                [_vm._v("点击上传")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "el-upload__tip",
                                                  attrs: { slot: "tip" },
                                                  slot: "tip",
                                                },
                                                [
                                                  _vm._v(
                                                    " 头像不允许涉及政治敏感与色情;图片格式必须为：png,bmp,jpeg,jpg,gif；不可大于1M；建议使用png格式图片，以保持最佳效果；建议图片尺寸为144px*144px "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.miniParams.miniHead
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                padding: "10px",
                                                background: "#f1f1f1",
                                                width: "236px",
                                                "margin-bottom": "10px",
                                              },
                                            },
                                            [
                                              _c("img", {
                                                staticStyle: {
                                                  width: "104px",
                                                  height: "104px",
                                                  "margin-right": "8px",
                                                },
                                                attrs: {
                                                  src: _vm.miniParams.miniHead,
                                                  alt: "",
                                                },
                                              }),
                                              _c("img", {
                                                staticStyle: {
                                                  width: "104px",
                                                  height: "104px",
                                                  "border-radius": "100px",
                                                },
                                                attrs: {
                                                  src: _vm.miniParams.miniHead,
                                                  alt: "",
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.logoId
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "primary" },
                                              on: { click: _vm.submitLogo },
                                            },
                                            [_vm._v("提交")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.miniConfigActive == 2
                                ? _c(
                                    "el-form-item",
                                    {
                                      ref: "miniSignature",
                                      attrs: {
                                        label: "",
                                        prop: "miniSignature",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: {
                                          "margin-bottom": "10px",
                                        },
                                        attrs: { type: "textarea", rows: 3 },
                                        model: {
                                          value: _vm.miniParams.miniSignature,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.miniParams,
                                              "miniSignature",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "miniParams.miniSignature",
                                        },
                                      }),
                                      _c(
                                        "p",
                                        {
                                          style: {
                                            color: _vm.signatureTipsStatus
                                              ? "red"
                                              : "#999",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.signatureTips) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: { click: _vm.submitSignature },
                                        },
                                        [_vm._v("提交介绍 ")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.miniConfigActive == 3
                                ? _c(
                                    "div",
                                    [
                                      _c("el-form-item", [
                                        _c(
                                          "div",
                                          { staticClass: "name-tips" },
                                          [
                                            _vm._v(
                                              "以下4种联系方式至少要填一种"
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "el-form-item",
                                        {
                                          ref: "ownerContactPhone",
                                          attrs: {
                                            label: "电话",
                                            prop: "ownerContactPhone",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value:
                                                _vm.miniParams
                                                  .ownerContactPhone,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.miniParams,
                                                  "ownerContactPhone",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "miniParams.ownerContactPhone",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          ref: "ownerContactEmail",
                                          attrs: {
                                            label: "邮箱",
                                            prop: "ownerContactEmail",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value:
                                                _vm.miniParams
                                                  .ownerContactEmail,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.miniParams,
                                                  "ownerContactEmail",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "miniParams.ownerContactEmail",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          ref: "ownerContactWeixin",
                                          attrs: {
                                            label: "微信",
                                            prop: "ownerContactWeixin",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value:
                                                _vm.miniParams
                                                  .ownerContactWeixin,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.miniParams,
                                                  "ownerContactWeixin",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "miniParams.ownerContactWeixin",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          ref: "ownerContactQq",
                                          attrs: {
                                            label: "QQ",
                                            prop: "ownerContactQq",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value:
                                                _vm.miniParams.ownerContactQq,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.miniParams,
                                                  "ownerContactQq",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "miniParams.ownerContactQq",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: { click: _vm.submitContact },
                                        },
                                        [_vm._v("提交")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.registerCurrent != 2
                    ? _c(
                        "el-button",
                        {
                          staticStyle: {
                            display: "block",
                            margin: "50px auto",
                          },
                          attrs: { type: "primary" },
                          on: { click: _vm.registerNextStep },
                        },
                        [_vm._v("下一步")]
                      )
                    : _vm._e(),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="dialog">
    <el-dialog
      title="选取文件"
      width="70%"
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      :before-close="beforeClose"
      @close="$emit('close')"
    >
      <el-container class="container" v-if="!showCropper">
        <!--        左侧侧边栏-->
        <dialog-left-aside
          ref="dialogLeftAside"
          :currentType="groupType"
          :classifyActive="groupId"
          @changeType="containerType = $event"
          @changeGroup="changeGroupId"
          @dialogAsideReady="onDialogAsideReady"
          @changeGroupType="changeGroupType"
          @changeGroupEdit="changeGroupEdit"
        />
        <!--        从URL添加-->
        <el-container v-if="containerType === 0" class="url-add-container">
          <el-main>
            <el-row>
              <el-col :span="14" :offset="1">
                <el-form
                  ref="ruleForm"
                  :model="ruleForm"
                  :rules="rules"
                  label-width="80px"
                  class="url-form"
                >
                  <el-form-item label="URL" prop="url">
                    <el-input v-model="ruleForm.url" placeholder="http://" />
                  </el-form-item>
                  <el-form-item label="链接文本" prop="text">
                    <el-input v-model="ruleForm.text" />
                  </el-form-item>
                </el-form>
              </el-col>
            </el-row>
          </el-main>
          <el-footer height="72px">
            <el-button type="primary" @click="onHandleAddUrl"
              >确认添加</el-button
            >
          </el-footer>
        </el-container>
        <!--        上传图片和媒体库-->
        <template v-else>
          <dialog-container
            v-if="dialogAsideReady"
            :group-id="groupId"
            :group-type="groupType * 1"
            :can-edit="canEdit"
            v-on="$listeners"
          />
        </template>
      </el-container>
      <div class="cropper" v-else>
        <!-- https://github.com/xyxiao001/vue-cropper -->
        <div class="cropper-div">
          <vue-cropper
            ref="cropper"
            :img="cropperImg"
            :autoCrop="true"
            :autoCropWidth="cropperWidth"
            :autoCropHeight="cropperHeight"
            :fixed="true"
            :fixedNumber="[cropperWidth, cropperHeight]"
            outputType="png"
            @realTime="realTime"
          ></vue-cropper>
          <div class="realTime">
            <div :style="previewStyle2">
              <div :style="previews.div">
                <img :src="previews.url" :style="previews.img" />
              </div>
            </div>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="hideCropperDialog">取 消</el-button>
          <el-button type="primary" @click="finish">确 定</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- <div class="model" v-if="dialogVisible == true" @click="dialogVisible = false"></div> -->
  </div>
</template>

<script>
import Config from "@/packages/apis/Config";
import axios from "axios";
import dialogLeftAside from "./components/dialog-left-aside";
import dialogContainer from "./components/dialog-container";
import Upload from "@/packages/apis/Upload";
import { VueCropper } from "vue-cropper";
import { mapGetters } from "vuex";
import { getStorage } from "@/utils/storage";
const actionUrl = Config.baseUrl;
const api = new Upload();

export default {
  name: "UDialog",
  components: { dialogLeftAside, dialogContainer, VueCropper },
  props: {
    groupId: {
      type: Number,
    },
    groupType: {
      type: Number,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialogVisible: true,
      containerType: 1, // 0 从URL添加，1上传图片和媒体库
      ruleForm: {
        url: "",
        text: "",
      },
      rules: {
        url: [{ required: true, message: "请输入URL", trigger: "blur" }],
      },
      // groupId: -1 // 分组id
      option: {
        img: "https://img.zcool.cn/community/01rplwhbmvwycx1pxvocxr3635.gif",
      },
      previews: {},
      previewStyle2: {},
      dialogAsideReady: false
    };
  },
  watch: {
    dialogVisible() {},
  },
  computed: {
    ...mapGetters([
      "showCropper",
      "cropperWidth",
      "cropperHeight",
      "cropperImg",
      "cropperImgName",
    ]),
  },
  mounted() {},
  methods: {
    onDialogAsideReady(val) {
      console.log('onDialogAsideReady:', val)
      this.groupType = val.groupType
      this.groupId = val.groupId
      this.dialogAsideReady = true
    },
    changeGroupId(groupId) {
      console.log("changeGroupId:", groupId);
      if (groupId) {
        this.groupId = Number.parseInt(groupId);
      } else {
        this.groupId = -1;
      }
    },
    changeGroupType(groupType) {
      console.log("changeGroupType:", groupType);
      this.groupType = groupType;
    },
    changeGroupEdit(groupEdit) {
      console.log("changeGroupEdit:", groupEdit);
      this.canEdit = groupEdit;
    },
    onHandleAddUrl() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (!valid) return false;
        const res = await api.createByURL(
          this.ruleForm.url,
          this.ruleForm.text
        );
        if (res.code === 200) {
          this.$message.success("添加成功");
          if (res.data.url) {
            this.$emit("getPic", {
              url: res.data.url,
            });
          }
          // await this.$refs.dialogLeftAside.onHandleClassifySelect(-1)
          // this.dialogVisible = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 裁切框实时预览
    realTime(data) {
      var h = data.w < 300 ? 1 : 0.5;
      var w = data.w < 300 ? 1 : 0.5;

      this.previewStyle2 = {
        width: data.w + "px",
        height: data.h + "px",
        overflow: "hidden",
        border: "1px solid #ccc",
        margin: "0",
        zoom: w,
      };

      this.previews = data;
    },
    // 关闭之前先关闭裁切框
    beforeClose(done) {
      this.$store.commit("upload/TOGGLE_CROPPER", {
        show: false,
        img: "",
        name: "",
      });
      done();
    },
    // 通过裁切框上传图片
    finish() {
      const loading = this.$loading({
          lock: true,
          text: '上传中...',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      this.$refs.cropper.getCropBlob((data) => {
        // data = new Blob([data],{filename: this.cropperImgName})
        let formData = new FormData();
        formData.append("file", data, this.cropperImgName); // 第一个file 后台接收的参数名
        console.log(this.cropperImgName);
        axios({
          url: actionUrl, // 上传路径
          method: "POST",
          params: {
            api: "resources.file.uploadFiles",
            storeId:
              this.$route.path.split("/")[1] == "Platform"
                ? 0
                : getStorage("laike_admin_userInfo").storeId,
            groupId: this.groupId,
            // mchId: getStorage('laike_admin_userInfo').mchId,
            uploadType: 2,
            type: 0,
            accessId: this.$store.getters.token,
          },
          data: formData,
        }).then((result) => {
          if (result.data.code == 200) {
            this.$message.success(result.data.message);
            this.$store.commit("upload/TOGGLE_CROPPER", {
              show: false,
              img: "",
              name: "",
            });
          } else {
            this.$message.error(result.data.message);
          }
          loading.close();
        });
      });
    },
    // 关闭隐藏裁切框
    hideCropperDialog() {
      this.dialogVisible = false;
      this.$store.commit("upload/TOGGLE_CROPPER", {
        show: false,
        img: "",
        name: "",
      });
    },
  },
};
</script>

<style scoped lang="less">
.dialog {
  .model {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    transform: translate(-50%, -50%);
    opacity: 0.5;
    background: #000;
    z-index: 999999999;
  }
  /deep/ .el-dialog {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto !important;
    min-width: 1200px;
    height: auto !important;
    z-index: 9999999999;
  }

  /deep/ .el-dialog__header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d5dbe8;
    padding: 0 20px;
    height: 60px;
    .el-dialog__headerbtn {
      top: 0;
      height: 60px;
      line-height: 60px;
    }
  }

  /deep/ .el-dialog__body {
    padding: 0;
    .el-form {
      padding: 0 !important;
    }
  }

  .container {
    background: #edf1f5;
    height: 600px;
    width: 100%;
    .url-add-container {
      background: #fff;
      /deep/ .el-footer {
        border-top: 1px solid #e9ecef;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
      }
    }
  }

  /deep/.url-form {
    .el-form-item {
      margin-bottom: 20px;
    }
  }
  .cropper {
    &-div {
      display: flex;
      height: 600px;
      width: 100%;
      .vue-cropper {
        width: 750px;
      }
      .realTime {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .dialog-footer {
      background: #fff;
      padding: 10px;
      text-align: right;
    }
  }
}
</style>

<template>
  <div class="dialog-left-aside">
    <el-aside class="aside" >
      <el-tabs v-model="currentType" :stretch="true" @tab-click="changeRadio" type="border-card" style="height:100%">
        <el-tab-pane v-for="item of groupTypes" :label="item.text" :name="item.type + ''" :key="item.text">
          <el-menu
            ref="fileMenu"
            :default-active="classifyActive"
            @select="onHandleClassifySelect"
          >
            <!-- 我的类型的不可编辑分类-->
            <template v-if="item.type==0">
              <el-menu-item
                v-for="itemMy of objDef"
                :key="itemMy.id"
                :index="itemMy.id.toString()"
              >
                <span slot="title">{{ itemMy.name }}</span>
              </el-menu-item>
            </template>

            <template v-for="(itemBC, index) of bottomClassify">
              <template v-if="canEdit==false">
                <el-menu-item
                  :key="itemBC.id"
                  :index="itemBC.id.toString()"
                >
                  <span slot="title">{{ itemBC.name }}</span>
                </el-menu-item>
              </template>
              <template v-else>
                <dialog-left-aside-menu-item
                  :key="itemBC.id"
                  :index="`${itemBC.id}`"
                  :name="itemBC.name"
                  @edit="onHandleItemEdit($event, index)"
                  @delete="onHandleItemDelete($event, index)"
                >
                  <span slot="title">{{ itemBC.name }}</span>
                </dialog-left-aside-menu-item>
              </template>
            </template>
          </el-menu>

        </el-tab-pane>
      </el-tabs>
      <!--      <div class="gap"></div>-->
      <!--      <el-menu :default-active="urlAddActive" @select="onHandleAddUrlSelect">-->
      <!--        <el-menu-item index="addUrl">-->
      <!--          <span slot="title">从URL添加</span>-->
      <!--        </el-menu-item>-->
      <!--      </el-menu>-->
      <!--      <div class="gap"></div>-->
      <!--      个人-->
      <el-button
        v-if="currentType==0||(currentType==1&&canEdit==true)"
        class="create-classify-btn"
        size="medium"
        type="primary"
        icon="el-icon-circle-plus"
        @click="dialogFormVisible = true"
      >创建分类
      </el-button>
    </el-aside>

    <el-dialog
      title="添加分类"
      :visible.sync="dialogFormVisible"
      append-to-body
      width="30%"
      @submit.native.prevent
    >
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="80px"
        @submit.native.prevent
      >
        <el-form-item label="分类名称" prop="name">
          <el-input v-model="ruleForm.name" @keydown.enter.native="createClassify" maxlength="6"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="
            () => {
              this.dialogFormVisible = false;
              this.ruleForm.name = '';
            }
          "
        >取 消</el-button>
        <el-button type="primary" @click="createClassify">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Upload from '../../apis/Upload'
import DialogLeftAsideMenuItem from '../components/dialog-left-aside-menu-item.jsx'

const api = new Upload()
/**
 * @emit changeType 切换类型
 */
export default {
  name: 'DialogLeftAside',
  components: { DialogLeftAsideMenuItem },
  props: {
  },
  data() {
    return {
      groupTypes: [
        {
          text: '我的',
          type: 0
        },
        {
          text: '公共',
          type: 1
        }
      ],
      objDef: [{
        id: -1,
        name: '默认'
      }],
      canEdit: true,
      currentType: sessionStorage.getItem('uploadAsideTabIndex') || '0', // 当前分组类型
      urlAddActive: '', // addUrl 选中 从URL添加
      classifyActive: sessionStorage.getItem('uploadAsideTabCommonMenuIndex')+''  ||  '-1', // 分类选中的id
      classify_list: [], // 分类的列表
      dialogFormVisible: false, // 显示添加分类表单
      ruleForm: {
        name: ''
      },
      page: 1,
      pid: '-1',
      rules: {
        name: [
          { required: true, message: '请输入分类名称', trigger: 'blur' },
          { min: 1, max: 6, message: '长度在 1 到 6 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    bottomClassify() {
      console.log('bottomClassify')
      const list = []
      for (let i = 0; i < this.classify_list.length; i++) {
        list.push(this.classify_list[i])
      }
      return list
    }
  },
  watch: {
    urlAddActive(val) {
      let type = 1
      if (val === 'addUrl') {
        type = 0
        this.classifyActive = ''
      }
      this.$emit('changeType', type)
    },

    classifyActive(val) {
      console.log('classifyActive：', val)
      sessionStorage.setItem('uploadAsideTabCommonMenuIndex', val)
      this.$emit('changeGroup', val)
    },
    currentType(val) {
      console.log('currentType：', val)
      sessionStorage.removeItem('uploadAsideTabCommonMenuIndex')
      sessionStorage.setItem('uploadAsideTabIndex', val)
      this.$emit('changeGroupType', val)
    },
    canEdit(val) {
      console.log('canEdit：', val)
      this.$emit('changeGroupEdit', val)
    }
  },
  created() {
    this.getClassifys()
    // await this.getFileList();
  },
  methods: {
    async createClassify() {
      this.$refs.ruleForm.validate(async valid => {
        if (!valid) return false
        api.createClassify(this.ruleForm.name, this.currentType).then(res => {
          this.dialogFormVisible = false
          this.ruleForm.name = ''
          this.getClassifys()
          this.$message({
            message: '创建成功',
            type: 'success',
            offset: 100
          })
        })
      })
    },
    /**
     *
     */
    async getClassifys() {
      await api.getClassify(this.currentType).then(res => {
        this.classify_list = res.data.data.list
        if (this.currentType == 0) {
          if (this.classify_list.length == 0) {
            this.classifyActive = '-1'
          } else {
            this.classifyActive = sessionStorage.getItem('uploadAsideTabCommonMenuIndex') || -1
          }
          this.canEdit = true
        } else if (this.currentType == 1) {
          this.canEdit = res.data.data.canEdit
          if (this.classify_list.length == 0) {
            this.classifyActive = '-2'// 表示没有分组数据
          } else {
            this.classifyActive = sessionStorage.getItem('uploadAsideTabCommonMenuIndex') || this.classify_list[0].id
          }
        }
        console.log('getClassifys:', this.currentType, this.canEdit, this.classifyActive )
        this.$emit('dialogAsideReady',{groupType:this.currentType,groupId:this.classifyActive})
      })
    },

    changeRadio(e) {
      this.getClassifys()
    },

    /**
     * 切换到从URL添加
     * @param e
     */
    onHandleAddUrlSelect(e) {
      this.urlAddActive = e
    },
    async getFileList() {
      const res = await api.getFileList({
        page: this.page,
        pid: this.pid,
        type: this.groupType
      })
    },
    /**
     * 切换分类
     * @param e
     */
    async onHandleClassifySelect(e) {
      console.log('onHandleClassifySelect', e)
      this.classifyActive = e
      this.urlAddActive = ''
      sessionStorage.setItem('uploadAsideTabCommonMenuIndex', e)
      // await this.getFileList()
    },
    /**
     * 分类编辑事件
     * @param e
     * @param index
     */
    async onHandleItemEdit(e, index) {
      const item = this.bottomClassify[index]
      item.name = e
      // this.$set(this.classify_list, index, item);
      await this.editClassify(item.id, item.name)
    },
    async editClassify(id, name) {
      await api.editClassify(id, name, this.currentType).then(res => {
      })
      await this.getClassifys()
    },
    /**
     * 处理删除事件
     * @param e
     * @param index
     */
    async onHandleItemDelete(e, index) {
      const name = this.bottomClassify[index].name
      try {
        await this.$confirm('是否删除此分类?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        await this.deleteClassify(e)
      } catch (e) {
      }
    },
    async deleteClassify(id) {
      await api.deleteClassify(id).then(res => {
        if (res.data.code == 200) {
          this.$message.success('删除成功')
          if (this.classifyActive == id) {
            sessionStorage.removeItem('uploadAsideTabCommonMenuIndex')
          }
          this.getClassifys()
        } else {
          this.$message.warning(res.data.message)
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.dialog-left-aside {
  border-right: solid 2px #eee;
  overflow: hidden auto;
  background: #fff;

  > .aside {
    width: 180px !important;

    /deep/ .el-menu {
      background: none;
      border-right: none;
    }
  }

  .gap {
    width: 160px;
    height: 1px;
    background: #d5dbe8;
    margin: 10px auto;
  }

  .create-classify-btn {
    margin: 10px auto;
    display: block;
  }

  .classify-menu {
    max-height: 448px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  /deep/ .el-tabs--border-card{
    box-shadow: none;
    border: 0;
  }
}
</style>

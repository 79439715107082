import { asyncRoutes, constantRoutes } from '@/router'
import { getStorage, setStorage, removeStorage } from '@/utils/storage'
import request from '@/api/https'
import Layout from '@/layout/index'
import { isEmpty } from 'element-ui/src/utils/util'

/**
 * 使用 meta.role 确定当前用户是否有权限
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.includes(route.meta.roles)
  } else {
    return true
  }
}

/**
 * 通过递归过滤异步路由表
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  const res = []

  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = tmp.children.filter(route => {
          if (hasPermission(roles, route)) {
            return route
          }
        })
      }
      res.push(tmp)
    }
  })

  return res
}

const state = {
  routes: [],
  addRoutes: []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  }
}

const actions = {
  getAsyncRoutes({ commit }) {
    const res = []
    return request({
      method: 'post',
      params: {
        api: 'saas.role.getAsyncRoutesByRoutesBetter'
      }
    }).then(routes => {
      console.log('获取机构路由', routes)
      if (routes.data.code == '200') {
        const route = routes.data.data
        console.log(route, '菜单权限')
        if (route.menu && route.menu.length !== 0) {
          const list = []
          route.menu.forEach((menu, index) => {
            const icons = []
            if (!isEmpty(menu.image)) {
              icons.push(menu.image)
            }
            if (!isEmpty(menu.image1)) {
              icons.push(menu.image1)
            }
            const modules = !isEmpty(menu.children) ? menu.children[0].module : ''
            // 一级菜单
            const topMenu = {
              path: '/' + menu.module,
              component: Layout,
              redirect: '/' + menu.module + '/' + modules,
              name: menu.module,
              meta: { title: menu.title, icon: icons }
            }
            // 递归子菜单
            topMenu.children = actions.getMenus(menu.children)

            res.push(topMenu)
            menu.children.forEach((item, i) => {
              list.push(item)
            })
          })
          setStorage('route', list)
          return actions.generateRoutes(commit, res)

        }else{
          this._vm.$message.error(routes.data.data.message)
          removeStorage('laike_admin_userInfo')
          return false
        }
      }
    })
  },
  // 菜单递归
  getMenus(menuList) {
    if (isEmpty(menuList)) {
      return []
    }

    menuList.forEach((currentMenu, index) => {
      const childrenMenu = {
        path: currentMenu.module,
        name: currentMenu.module,
        meta: { title: currentMenu.title, is_core: currentMenu.is_core },
        id: currentMenu.id
      }
      // 是否有子菜单
      if (!currentMenu.haveChidren) {
        childrenMenu.component = resolve => require([`@/views${currentMenu.url}`], resolve)
      } else {
        // 校验父级导航url指向的child是否存在，如果不存在修改redirect指向children的第一个，防止页面空白
        var urls = currentMenu.url.split('/')
        var have = false
        for (let i = 0; i < currentMenu.children.length; i++) {
          var item = currentMenu.children[i]
          if (item.module == urls[urls.length - 1]) {
            have = true
            break
          }
        }
        if (have) {
          childrenMenu.redirect = currentMenu.url
        } else {
          urls[urls.length - 1] = currentMenu.children[0].module
          childrenMenu.redirect = urls.join('/')
        }
        // ------------------------------------
        childrenMenu.component = {
          render(c) {
            return c('router-view')
          }
        }
        // 继续递归
        childrenMenu.children = actions.getMenus(currentMenu.children)
      }
      menuList[index] = childrenMenu
    })
    return menuList
  },
  generateRoutes(commit, authorizationList) {
    return new Promise(resolve => {
      let authorizationLists = authorizationList
      if (getStorage('laike_admin_userInfo').type != 0) {
        authorizationLists = authorizationLists.filter(item => {
          if (item.meta.title !== '平台') {
            return item
          }
        })
      }
      commit('SET_ROUTES', authorizationLists)
      resolve(authorizationList)
    })
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

<template>
  <div class="container">
    <!-- <div class="title-form">{{ titleForm }}</div> -->
    <!--表单填写-->
    <template v-if="pageType == 1">
      <el-image :src="titleImg" class="head-img" fit="fill" alt="表单顶图" />
      <v-form-render
        ref="vFormRef"
        :form-json="formJson"
        :form-data="formData"
      />
      <div class="btns">
        <el-button @click="handleCurrentChange(1)">
          查看历史记录
        </el-button>
        <el-button
          type="primary"
          :disabled="sumitDisabled"
          @click="submitForm"
          >提交</el-button
        >
      </div>
      <div style="height: 100px; width: 100%; flex-shrink: 0;"></div>
    </template>
    <!--表单详情-->
    <template v-else-if="pageType == 3">
      <el-image :src="titleImg3" class="head-img" fit="fill" alt="表单顶图" />
      <v-form-render
        ref="vFormRef3"
        :form-json="formJson3"
        :form-data="formData3"
      />
      <div>
        <el-button
          style="width: 15rem; margin-bottom: 1rem"
          type="primary"
          @click="backHistory(2)"
          >返回</el-button
        >
      </div>
    </template>
    <!--表单记录-->
    <template v-else>
      <div
      class="my-list"
      v-loading="page.loading"
      element-loading-text="拼命加载中..."
    >
      <custom-form-history-list :list="page.tableData">
        <template slot-scope="rows" slot="btns">
            <el-button
              icon="file-text"
              size="mini"
              @click="viewDetail(rows.item.id)"
            >
              查看
            </el-button>
          </template>
      </custom-form-history-list>

    </div>
      <el-table
      v-if="false"
        v-loading="page.loading"
        :show-header="false"
        stripe
        element-loading-text="拼命加载中..."
        :data="page.tableData"
      >
        <el-table-column
          width="50"
          align="center"
          type="index"
          :index="indexMethod"
        />
        <el-table-column prop="applyDate" align="center" label="时间">
          <template slot-scope="scope">
            <!-- <span v-if="!scope.row.grade_add">暂无</span> -->
            <div>{{ scope.row.applyDate.split(' ')[0]}}</div>
            <div>{{ scope.row.applyDate.split(' ')[1]}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" align="center" label="电话">
        </el-table-column>
        <el-table-column width="100" align="center">
          <template slot-scope="scope">
            <el-button
              icon="file-text"
              size="mini"
              @click="viewDetail(scope.row.id)"
            >
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagesBox">
        <el-pagination
          layout="prev,pager,next"
          :page-sizes="page.pagesizes"
          :total="page.total"
          :pager-count="5"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
        <div class="back-btn">
          <el-button @click="backHistory(1)"> 返回</el-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { post } from "@/api/common";
import { Message } from "element-ui";
import { postFormDataWx, postWx } from "@/api/wechat";
import pageData from "@/api/constant/page";
import customFormHistoryList from "@/components/customFormHistoryList";

export default {
  name: "FormRender",
  // mixins: [mixinstest],
  data() {
    return {
      page: pageData.data(),
      titleImg: "",
      titleForm: "",
      description: "",
      formJson: {},
      formData: {},

      titleImg3: "",
      formJson3: {},
      formData3: {},

      linkList: [],
      copyright_information: "",
      record_information: "",
      // 小程序访问页面携带的参数
      title: "",
      formId: "",
      access_id: null,
      store_type: null,
      store_id: null,
      rewrite: null,
      sumitDisabled: true,
      pageType: 1, // 1 提交页面 2 历史记录 3 记录详情
      fromType: 1,
      apiType: 0, // 1 我的对接
      timeArr:['']
    };
  },
  created() {
    this.jweixin();
    console.log("formRender created:", JSON.stringify(this.$route.query));
    this.access_id = this.$route.query.access_id;
    this.formId = this.$route.query.formId;
    this.apiType = this.$route.query.apiType;
    this.demandId =
      this.$route.query.demandId == "undefined"
        ? ""
        : this.$route.query.demandId;
    this.fromType = this.pageType = this.$route.query.pageType
      ? this.$route.query.pageType
      : 1;
    if (this.access_id) {
      // 小程序调用该页面并自带token
      this.title = this.$route.query.title;
      this.store_id = this.$route.query.store_id;
      this.store_type = this.$route.query.store_type;
    } else {
      // web调用该页面,用来查看表单效果
      this.store_id = null;
      this.store_type = null;
      this.access_id = null;
    }
    if (this.pageType == 1) {
      this.loadData();
    } else if (this.pageType == 2) {
      this.loadHistory();
    } else {
    }
  },
  components:{
    customFormHistoryList
  },
  mounted() {
    document.title = this.$route.query.title;
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == "formList" && this.$route.name == "formRender") {
      to.params.dictionaryNum = this.$route.query.dictionaryNum;
      to.params.pageSize = this.$route.query.pageSize;
    }
    next();
  },

  methods: {
    jweixin() {
      // 插入script脚本
      const scriptNode = document.createElement("script");
      scriptNode.setAttribute("type", "text/javascript");
      scriptNode.setAttribute(
        "src",
        "https://res.wx.qq.com/open/js/jweixin-1.3.2.js"
      );
      document.body.appendChild(scriptNode);
    },
    loadData() {
      console.log("accessId:", this.access_id);
      this.loadingRequest = this.$loading({
        lock: true,
        text: "请求中...",
        background: "rgba(0, 0, 0, 0.7)",
      });
      //获取表单信息展示表单给用户填写
      if (this.access_id) {
        postWx({
          store_id: this.store_id,
          store_type: this.store_type,
          access_id: this.access_id,
          api: "app.sdform.getinfo",
          id: this.formId,
        }).then((res) => {
          this.loadingRequest.close();
          console.log("getinfo:", res);
          if (res.status == 200) {
            if (res.data.code == 200) {
              var data = res.data.data.info;
              this.titleForm = data.name;
              this.titleImg = data.titleImg;
              this.rewrite = data.rewrite;
              this.description = data.description;
              if (data.content) {
                this.sumitDisabled = false || !this.access_id;
                this.formJson = JSON.parse(data.content);
                if (this.formJson) {
                  if (this.formJson.widgetList) {
                    this.formJson.widgetList.forEach((item) => {
                      //图片/文件上传组件上传请求配置参数token修改为传入的token
                      if(item.type == 'picture-upload' || item.type == 'file-upload'){
                        item.options.uploadData.access_id = this.access_id
                      }
                    });
                  }
                }
                console.log("formJson:", JSON.stringify(this.formJson));
                this.$refs.vFormRef.setFormJson(this.formJson);
                if (data.contentData) {
                  // 已提交过数据
                  this.$nextTick(() => {
                    this.formData = JSON.parse(data.contentData);
                    console.log("formData:", JSON.stringify(this.formData));
                    this.$refs.vFormRef.setFormData(this.formData);
                  });
                }
              } else {
                Message.error("表单不可用");
                this.sumitDisabled = true;
              }
            } else if (res.data.code == 203) {
              setTimeout(()=>{
                window.wx.miniProgram.postMessage({data: {needLogin: true}})
                window.wx.miniProgram.navigateBack()
              },1000)
              Message.error('登录失效，请重新登录');
            } else {
              Message.error(res.data.message);
            }
          } else {
            Message.error(res.statusText);
          }
        });
      } else {
        post({
          api: "saas.sdform.edit",
          id: this.formId,
        }).then((res) => {
          this.loadingRequest.close();
          console.log("typeForm:", res.data);
          if (res.status == 200) {
            var data = res.data.data;
            this.titleForm = data.name;
            this.titleImg = data.titleImg;
            this.description = data.description;
            if (data.content) {
              this.formJson1 = JSON.parse(data.content);
              this.$refs.vFormRef.setFormJson(this.formJson1);
              // this.$nextTick(() => {
              //   this.$refs.vFormRef.setFormData(newFormData)
              // })
            } else {
              Message.error("表单不可用");
            }
          }
        });
      }
    },
    submitForm() {
      const _this = this;
      this.$refs.vFormRef
        .getFormData()
        .then(function (formData) {
          console.log("submitForm:", JSON.stringify(formData));
          _this.loadingRequest = _this.$loading({
            lock: true,
            text: "提交中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          _this.formData = formData;
          postFormDataWx({
            store_id: _this.store_id,
            store_type: _this.store_type,
            access_id: _this.access_id,
            api: "app.sdform.add",
            formId: _this.formId,
            demandId: _this.demandId,
            name: _this.titleForm,
            rewrite: _this.rewrite,
            description: _this.description,
            titleImg: _this.titleImg,
            content: JSON.stringify(_this.formJson),
            contentData: JSON.stringify(_this.formData),
          }).then((res) => {
            console.log("submitForm:", res);
            _this.loadingRequest.close();
            if (res.status == 200) {
              const data = res.data;
              if (data.code == 200) {
                Message.success(data.message);
                try {
                  setTimeout(() => {
                    wx.miniProgram.navigateBack();
                  },1000)
                } catch (e) {
                  console.error("wx.miniProgram catch:", e);
                  Message.error(e.msg);
                }
              } else {
                Message.error(data.message);
              }
            } else {
              Message.error(res.statusText);
            }
          });
        })
        .catch(function (error) {
          // Form Validation Failed
          alert(error);
        });
    },
    loadHistory() {
      const _this = this;
      _this.page.loading = true;
      postWx({
        store_id: _this.store_id,
        store_type: _this.store_type,
        access_id: _this.access_id,
        api:
          _this.demandId && _this.demandId != "undefined" && _this.apiType != 1
            ? "app.sdform.getAllApplylist"
            : "app.sdform.getApplylist",
        id: _this.formId,
        demandId:
          _this.demandId && _this.demandId != "undefined"
            ? _this.demandId
            : null,
        pageNo: this.page.dictionaryNum,
        pageSize: this.page.pageSize,
      }).then((res) => {
        console.log("loadHistory:", res);
        _this.page.loading = false;
        if (res.status == 200) {
          const data = res.data;
          if (data.code == 200) {
            if (data.data.total == 0) {
              Message.warning("暂无历史记录");
            } else {
              _this.pageType = 2;
              // data.data.datalist.forEach(item=>{
              //   item.applyDate = this.format(item.applyDate)
              // })
              this.$nextTick(()=>{
                _this.page.tableData = data.data.datalist;
                _this.page.total = data.data.total;
              })
            }
          } else {
            Message.error(data.message);
          }
        } else {
          Message.error(res.statusText);
        }
      });
    },
    indexMethod(index) {
      return index + 1;
    },
    // 选择一页多少条
    handleSizeChange(e) {
      this.page.loading = true;
      this.page.pageSize = this.page.current_num = e;
      this.loadHistory();
    },
    // 点击上一页，下一页
    handleCurrentChange(e) {
      this.page.loading = true;
      this.page.dictionaryNum = this.page.pagination.page = e;
      this.page.currpage = (e - 1) * this.page.pageSize + 1;
      console.log("handleCurrentChange:", e, this.page);
      this.loadHistory();
    },
    //查看已填写的表单
    viewDetail(id) {
      this.pageType = 3;
      console.log("viewDetail", id);
      post({
        store_id: this.store_id,
        store_type: this.store_type,
        access_id: this.access_id,
        api: "app.sdform.getApplyDetail",
        id: id,
      }).then((res) => {
        console.log("viewDetail:", res);
        if (res.status == 200) {
          if (res.data.code == 200) {
            var data = res.data.data;
            this.titleImg3 = data.titleImg;
            this.titleForm = data.name;
            if (data.content) {
              this.formJson3 = JSON.parse(data.content);
              console.log("formJson:", JSON.stringify(this.formJson3));
              this.$refs.vFormRef3.setFormJson(this.formJson3);
              // 查看表单详情时禁用表单填写
              if (this.formJson3) {
                if (this.formJson3.widgetList) {
                  this.formJson3.widgetList.map((item) => {
                    if (item.options) {
                      item.options.disabled = true;//禁用表单填写
                    }
                    return item;
                  });
                }
              }
              console.log("formData:", data.contentData);
              if (data.contentData) {
                // 已提交过数据
                this.$nextTick(() => {
                  this.formData3 = JSON.parse(data.contentData);
                  console.log("formData:", JSON.stringify(this.formData3));
                  this.$refs.vFormRef3.setFormData(this.formData3);
                });
              }
            } else {
              Message.error("表单不可用");
            }
          } else {
            Message.error(res.data.message);
          }
        } else {
          Message.error(res.statusText);
        }
      });
    },
    backHistory(type) {
      if (type < this.fromType) {
        try {
          // eslint-disable-next-line no-undef
          wx.miniProgram.navigateBack();
        } catch (e) {
          console.error("wx.miniProgram catch:", e);
        }
        return;
      }
      this.pageType = type;
      if (type == 1) {
        if (this.formJson1) {
          if (this.formJson1.widgetList) {
            this.formJson1.widgetList.map((item) => {
              if (item.options) {
                item.options.disabled = false;
              }
              return item;
            });
          }
        }
        this.$nextTick(() => {
          this.$refs.vFormRef.resetForm();
        });
      } else if (type == 2) {
        this.formJson3 = {};
        this.formData3 = {};
        this.handleCurrentChange(1);
      }
    },
    format(time){
      var date = time.substr(0, 10); //年月日
      var hours = time.substring(11, 13);
      var minutes = time.substring(14, 16);
      var seconds = time.substring(17, 19);
      var timeFlag = date + ' ' + hours + ':' + minutes + ':' + seconds;
      timeFlag = timeFlag.replace(/-/g, "/");
      timeFlag = new Date(timeFlag);
      timeFlag = new Date(timeFlag.getTime() + 8 * 3600 * 1000);
      timeFlag = timeFlag.getFullYear() + '-' +
      ((timeFlag.getMonth() + 1) < 10 ? "0" + (timeFlag.getMonth() + 1) : (timeFlag.getMonth() + 1)) + '-' +
      (timeFlag.getDate() < 10 ? "0" + timeFlag.getDate() : timeFlag.getDate()) + ' ' +
      (timeFlag.getHours()< 10 ? '0' + timeFlag.getHours() : timeFlag.getHours()) + ':' +
      (timeFlag.getMinutes() < 10 ? '0' + timeFlag.getMinutes() : timeFlag.getMinutes()) + ':' +
      (timeFlag.getSeconds() < 10 ? "0" + timeFlag.getSeconds() : timeFlag.getSeconds());
      return timeFlag;
    }
  },
};
</script>

<style scoped lang="less">
.container {
  background-size: cover;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  height: 100%;
  .title-form {
    font-weight: bolder;
    color: black;
    margin-top: 20px;
    font-size: 20px;
  }
}
/deep/ .el-table {
  margin-bottom: 76px;
}
/deep/ .pagesBox {
  z-index: 999;
  width: 100%;
  bottom: 0;
  position: fixed;
  padding: 0 20px;
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-top: 1px solid #edf1f5;
  .pageLeftText {
    font-size: 14px;
    font-weight: 400;
    color: #6a7076;
  }
  .el-pagination {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: center;
  }
  .pageRightText {
    margin-right: auto;
    font-size: 14px;
    font-weight: 400;
    color: #6a7076;
  }
  .btn-next,
  .btn-prev {
    padding: 0;
    width: 36px !important;
    height: 36px;
    border: 1px solid #d5dbe8;
    border-radius: 2px;
    margin-right: 4px;
  }

  .el-pager li {
    width: 36px;
    height: 36px;
    line-height: 36px;
    border: 1px solid #d5dbe8 !important;
    border-radius: 2px;
    color: #6a7076;
    margin-right: 4px;
    font-weight: normal;
  }
  .el-pager li:hover {
    border: 1px solid #06c377 !important;
    color: #06c377;
  }
  .el-pager li.active {
    border: 1px solid #d5dbe8 !important;
    color: #ffffff !important;
    background: #06c377;
  }
  .back-btn {
    width: 36px;
    height: 36px;
    line-height: 36px;
    button {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.head-img {
  margin: 1rem 1rem 0;
  height: 10.625rem;
  width: 100%;
  padding: 0 1rem;
  overflow: initial;
}
/deep/ .render-form {
  width: 100%;
  padding: 15px;
}
/deep/ .el-button--primary {
  background: #256effff;
  border-radius: 4px;
  color: white;
  height: 45px;
  width: 164px;
  font-size: 16px;
  font-weight: bold;
}
/deep/ .form-widget-container {
  background: none;
}
/deep/.static-content-item {
  background: #f7f7f7;
  padding: 8px;
  margin-bottom: 15px;
  color: #55575c;
  border-radius: 2px;
  div {
    display: flex;
    &::before {
      content: "";
      display: inline-block;
      background: url(https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230218/1626773248915701760.png) no-repeat;
      background-size: 13px;
      width: 13px;
      height: 13px;
      position: relative;
      top: 4px;
      flex: 0 0 18px;
    }
  }
}
/deep/.el-input.is-disabled .el-input__inner{
  background: #f5f7fa;
  color: #c0c4cc;
  opacity: 1;
}
.my-list{
  align-self: flex-start;
  overflow: hidden;
  width: 100%;
  min-height: 100%;
}
.btns{
  justify-content: space-evenly;
  width: 100%;
  display: flex;
  padding: 0 20px;
  box-sizing: border-box;
  .el-button{
    flex: 1;
    height: 100%;
  }
}
</style>

module.exports = {
  topNav: {
    username: '用户名',
    version: '版本',
    changePassword: '修改密码',
    basicInformation: '基本信息',
    Blue: '蓝色(默认)',
    Black: '黑色',
    Green: '绿色',
    Yellow: '黄色',
    Red: '红色',
    Orange: '橙色'
  },

  route: {
    // Demo
    Demo: 'Demo',
    tableExample: '表格示例',
    tableFrom: '基础表单',
    complexTable: '复杂表单示例',
    informationExample: '消息示例',
    universalModule: '通用组件',

    // Goods
    Goods: '商品',
    good1: '商品1',
    good2: '商品2'
  },

  DemoPage: {
    tableExamplePage: {
      TAB1: '标签页一',
      TAB2: '标签页二',
      TAB3: '标签页三',
      TAB4: '标签页四',

      Select_commodity_category: '请选择商品分类',
      Choose_a_Product_Brand: '请选择商品品牌',
      Select_display_location: '请选择显示位置',
      Select_Product_Type: '请选择商品类型',
      Select_commodity_status: '请选择商品状态',
      The_input: '请输入内容',
      demand: '查询',
      reset: '重置',
      export: '导出',
      batchDownload: '批量下载',
      download: '下载',

      time: '时间：',
      Select_Add_Time: '请选择添加时间',
      state: '状态：',
      Selection_state: '请选择状态',
      Valid_time: '有效时间：',
      Select_effective_time: '请选择有效时间',
      Menu_Name: '菜单名称：',
      Enter_menu_name: '请输入菜单名称',
      Commodity_brand: '商品品牌：',

      Add_menu: '添加菜单',
      Batch_delete: '批量删除',
      menu_id: '菜单ID',
      picture: '图片',
      Goods_details: '商品详情',
      menu_name: '菜单名称',
      switch: '开关',
      Belongs_to: '所属ID',
      operation: '操作',

      edit: '编辑',
      delete: '删除',
      copy: '复制',
      look_junior: '查看下级',
      more: '更多',

      add_Guid: '添加引导图'

    },

    tableFromPage: {
      username: '用户名称',
      Enter_user_name: '请输入用户名称',
      Active_area: '活动区域',
      Select_active_area: '选择活动区域',
      Multi_category_display: '多分类展示',
      choose: '请选择',
      superiorID: '上级id',
      Remote_validation_example: '远程校验示例',
      cell_phone_number: '手机号',
      Example_of_mobile_phone_number_verification: '手机号校验示例',
      address: '地址',
      province: '省',
      city: '市',
      county: '县',
      choose_time: '选择时间',
      Select_a_date: '选择日期',
      Range_of_choice: '范围选择',
      Start_date: '开始日期',
      to: '至',
      End_data: '结束日期',
      Instant_delivery: '及时配送',
      Nature_of_the_activities: '活动性质',
      food: '美食/餐厅线上活动',
      To_push_the_activity: '地推活动',
      Offline_Theme_Activities: '线下主题活动',
      Pure_Brand_Exposure: '单纯品牌曝光',
      Special_resources: '特殊资源',
      Online_Brand_Sponsorship: '线上品牌赞助',
      Offline_venue_free: '线下场地免费',
      Activity_form: '活动形式',
      price: '价格',
      Keep_the_number: '保留天数',
      discount: '折扣',
      save: '保存',
      cancel: '取消',
      Upload_photo: '上传单图',
      phote_info: '(最多上传一张，建议上传1：1尺寸的图片)',
      Upload_photos: '上传多图',
      photos_info: '(最多上传三张，建议上传1：1尺寸的图片)',
      saveComment: '发布评论',
      add_Guid: '添加引导图'
    },

    informationExamplePage: {
      message: '消息',
      success: '成功',
      warning: '警告',
      error: '错误',
      Popup_confirm: 'confirm弹窗',
      Popup_dialog: 'dialog弹窗'
    }
  },

  mechanism: {
    dataKanban: {
      pendingPayment: '待付款',
      toBeDelivered: '待发货',
      pendingReceipt: '待收货',
      comment: '待评价',
      returnOfGoods: '退货',
      unit: '个',
      turnover: '营业额',
      turnoverThisWeek: '本周营业额',
      turnoverThisMonth: '本月营业额',
      cumulativeTurnover: '累计营业额',
      productCategory: '产品分类',
      announcement: '公告',
      numberOfVisitors: '访客数',
      numberOfOrder: '订单数',
      salesRanking: '销量排行'
    }
  }
}

<template>
  <div class="sideitem">
    <ul class="list-item">
      <li v-for="(item,index) in item" v-show="item.meta.is_core == 1" :key="index">
        <span :class="{ active : item.name === $route.path.split('/')[2]}" @click.stop="handleSelect(`${mainNav}/${item.path}`,item)">
          {{ generateTitle(item.meta.title) }}
          <i v-if="item.meta.title === '订单列表'" class="orderListNum">({{ orderListNum }})</i>
          <i v-if="item.meta.title === '退货列表'" class="salesReturnListNum">({{ refundListNum }})</i>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import { setStorage, getStorage } from '@/utils/storage'
import { orderCount } from '@/api/plug_ins/preSale'
export default {
  name: 'Sideitem',

  props: {
    item: {
      type: Array,
      required: true
    },
    mainNav: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      navlist: this.item
    }
  },

  computed: {
    navlists() {
      return this.navlist
    },

    orderListNum() {
      return this.$store.getters.orderListNum
    },

    refundListNum() {
      return this.$store.getters.refundListNum
    }
  },

  watch: {
    item(newV, oldV) {
      this.navlist = newV
    }
  },

  methods: {
    // 发货订单数量
    async orderCounts() {
      const list = await orderCount({
        api: 'admin.preSellRecord.order.orderCount'

      })
      setStorage('inOrderNum', list.data.data.inOrderNum)
    },
    resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath
      }
      if (isExternal(this.basePath)) {
        return this.basePath
      }
      return path.resolve(this.basePath, routePath)
    },

    changItem(value) {
      this.item = value
    },

    handleSelect(index, index1) {
      // console.log('handleSelect:', index, JSON.stringify(index1))
      //   if(index == 'plug_ins/seckill') {
      //     window.location.href = 'https://java.admin.houjiemeishi.com/seckill/'
      //   } else {
      //       this.$router.push('/' + index);
      //   }

      // if (index == 'mall/homemap') {
      //   this.$openDataBoard()
      //   return
      // }

      this.$router.push('/' + index)
      setStorage('menuId', index1.id)
      if (index == 'plug_ins/seckill') {
        this.$store.dispatch('orderNum/getOrderSecCount')
      } else if (index == 'plug_ins/integralMall') {
        this.$store.dispatch('orderNum/getOrderInCount')
      } else if (index == 'plug_ins/preSale') {
        this.orderCounts()
      }
    },

    generateTitle(title) {
      const hasKey = this.$te('route.' + title)
      if (hasKey) {
        const translatedTitle = this.$t('route.' + title)
        return translatedTitle
      }
      return title
    }
  }
}
</script>

<style scoped lang="scss">
.list-item {
  width: 150px;
  // height: calc(100vh - 100px);
  height: 100vh;
  background-color: #fff;
  // background-color: #161c24;
  // background-color: #343e4c;

  // box-shadow: 3px 0px 3px rgba(185, 183, 183, 0.1);
  margin: 0;
  padding: 10px !important;
  li {
    width: 130px;
    height: 40px;
    line-height: 40px;
    margin: 8px 0;
    // color: #b2bcd1;
    font-size: 14px;
    border-radius: 2px;


    span {
      width: 100%;
      height: 100%;
      display: block;
      // padding-left: 6px;
      // color: #414658;
      font-size: 14px;
      border-radius: 12px;
      text-align: center;
      &.active {
        background-color: #e9f4ff;
        color: #06c377;
        // color: #FFF;
        font-weight: bold;
      }
    }

    .orderListNum,
    .salesReturnListNum {
      font-style: normal;
      color: red;
      // margin-left: 8px;
    }
  }
}
</style>

<template>
  <div
    class="my-table"
    :style="{ height: tableData.length > 0 ? 'calc(100% - 76px)' : '100%'}"
  >
    <table
      width="100%"
      :style="{ height: tableData.length > 0 ? 'auto' : '100%', width: isMobile ? `${120*(tableDataColumn[0].length+4)}px` : '100%',}"
    >
      <thead>
        <tr>
          <td>序号</td>
          <td v-for="(item, index) in tableDataColumn[0]" :key="index">
            {{ item.label }}
          </td>
          <td>联系方法</td>
          <td>时间</td>
          <td>操作</td>
        </tr>
      </thead>
      <tbody v-if="tableData.length > 0">
        <tr v-for="(item, index) in tableData" :key="index">
          <td align="center">{{ index + 1 }}</td>
          <td v-for="(a, aindex) in tableDataColumn[index]" :key="aindex">
            <!-- 图片 -->
            <div v-if="a.type === 'picture-upload'">
              <el-image
                v-for="imgItem in a.value"
                :key="imgItem"
                :src="imgItem"
                alt=""
                srcset=""
                style="width: 60px; margin: 0 5px"
                :preview-src-list="[imgItem]"
              ></el-image>
            </div>
            <!-- 附件 -->
            <div v-else-if="a.type === 'file-upload'">
              <a
                :href="aItem"
                v-for="(aItem, aIndex) in a.value"
                :title="aItem"
                :key="aItem"
                >{{ "附件" + (aIndex + 1) }}</a
              >
            </div>
            <div v-else>{{ a.value }}</div>
          </td>
          <td>{{ item.mobile }}</td>
          <td align="center">
            <div>{{ item.applyDate | GMTConvertYMDHMS }}</div>
          </td>
          <td align="center">
            <slot name="btns" :item="item"></slot>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="4" class="nodata-td">暂无数据</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      // 列表数据
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      tableData: [],
      tableDataColumn: []
    };
  },
  watch: {
    list: function (v) {
      // 整理列表数据
      let tableDataColumn = [];
      v.forEach((item) => {
        // 获取列表数据label
        item.content = JSON.parse(item.content);
        // 获取列表数据value
        item.contentData = JSON.parse(item.contentData);
        // 组装列表
        let arr = [];
        // 移除文字说明
        item.content.widgetList = item.content.widgetList.filter(
          (lt) => lt.type !== "link-text"
        );
        item.content.widgetList.forEach((a, aindex) => {
          // 单选/下拉菜单
          let val = item.contentData[a.id];
          if (a.type == "radio" || a.type == "select") {
            a.options.optionItems.forEach((b) => {
              if (b.value == item.contentData[a.id]) {
                val = b.label;
              }
            });
          }
          // 多选
          if (a.type == "checkbox") {
            let checkboxArr = [];
            a.options.optionItems.forEach((b) => {
              if (item.contentData[a.id].includes(b.value)) {
                checkboxArr.push(b.label);
              }
            });
            val = checkboxArr.join(",");
          }
          // 图片
          if (
            (a.type == "picture-upload" && item.contentData[a.id]) ||
            (a.type == "file-upload" && item.contentData[a.id])
          ) {
            val = item.contentData[a.id].map((b) => {
              return b.url;
            });
          }
          // 级联选择器
          if (a.type == "cascader") {
            let cascaderArr = [];
            item.contentData[a.id].forEach((b) => {
              a.options.optionItems.forEach((c) => {
                cascaderArr.push(this.factorial(c, b));
              });
            });
            cascaderArr = cascaderArr.filter((d) => d);
            val = cascaderArr.join(",");
          }
          // 地址
          if (a.type == "address") {
            let addressArr = [];
            a.options.optionItems.forEach((b) => {
              if (b.value === item.contentData[a.id][0]) {
                b.children.forEach((b2) => {
                  if (b2.value === item.contentData[a.id][1]) {
                    b2.children.forEach((b3) => {
                      if (b3.value === item.contentData[a.id][2]) {
                        addressArr.push(b3.label);
                      }
                    });
                    addressArr.push(b2.label);
                  }
                });
                addressArr.push(b.label);
                return false;
              }
            });
            val = addressArr.reverse().join("-");
          }
          arr.push({
            label: a.options.label,
            value: val,
            type: a.type,
          });
        });
        tableDataColumn.push(arr);
      });
      console.log("原始数据：", v);
      console.log("组织的数据：", tableDataColumn);
      this.tableData = v;
      this.tableDataColumn = tableDataColumn;
    }
  },
  computed:{
    isMobile() {
        console.log(window.screen.width,'屏幕宽度')
      return !!(window.screen.width < 768)
    },
  },
  methods: {
    /*
     * 处理递归
     * @params {Array} arr 数据选项
     * @params {Object} val 数据选项
     * @returns {Array}
     */

    factorial(o1, val) {
      if (!o1) {
        return;
      }
      if (o1.value == val) {
        return o1.label;
      }
      return this.factorial(o1.child, val);
    },
  },
};
</script>

<style lang="less" scoped>
.my-table {
  width: 100%;
  height: calc(100% - 76px);
  overflow-x: auto;
  background: #fff;
  table {
    table-layout: fixed;
    td {
      padding: 10px 5px;
      // white-space: nowrap;
      border-bottom: 1px solid #ebeef5;
      text-align: center;
    }
  }
  thead {
    td {
      font-weight: bold;
      text-align: center;
    }
  }
  tbody {
    tr:last-child {
      td {
        border: 0;
      }
    }
  }
  .nodata-td {
    color: #97a0b4;
    text-align: center;
    border-bottom: 0;
  }
}
@media screen and (max-width: 768px) {
  .my-table {
    min-height: 100%;
  }
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选取文件",
            width: "70%",
            visible: _vm.dialogVisible,
            "modal-append-to-body": false,
            "before-close": _vm.beforeClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [
          !_vm.showCropper
            ? _c(
                "el-container",
                { staticClass: "container" },
                [
                  _c("dialog-left-aside", {
                    ref: "dialogLeftAside",
                    attrs: {
                      currentType: _vm.groupType,
                      classifyActive: _vm.groupId,
                    },
                    on: {
                      changeType: function ($event) {
                        _vm.containerType = $event
                      },
                      changeGroup: _vm.changeGroupId,
                      dialogAsideReady: _vm.onDialogAsideReady,
                      changeGroupType: _vm.changeGroupType,
                      changeGroupEdit: _vm.changeGroupEdit,
                    },
                  }),
                  _vm.containerType === 0
                    ? _c(
                        "el-container",
                        { staticClass: "url-add-container" },
                        [
                          _c(
                            "el-main",
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 14, offset: 1 } },
                                    [
                                      _c(
                                        "el-form",
                                        {
                                          ref: "ruleForm",
                                          staticClass: "url-form",
                                          attrs: {
                                            model: _vm.ruleForm,
                                            rules: _vm.rules,
                                            "label-width": "80px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "URL",
                                                prop: "url",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "http://",
                                                },
                                                model: {
                                                  value: _vm.ruleForm.url,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.ruleForm,
                                                      "url",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "ruleForm.url",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "链接文本",
                                                prop: "text",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                model: {
                                                  value: _vm.ruleForm.text,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.ruleForm,
                                                      "text",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "ruleForm.text",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-footer",
                            { attrs: { height: "72px" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.onHandleAddUrl },
                                },
                                [_vm._v("确认添加")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : [
                        _vm.dialogAsideReady
                          ? _c(
                              "dialog-container",
                              _vm._g(
                                {
                                  attrs: {
                                    "group-id": _vm.groupId,
                                    "group-type": _vm.groupType * 1,
                                    "can-edit": _vm.canEdit,
                                  },
                                },
                                _vm.$listeners
                              )
                            )
                          : _vm._e(),
                      ],
                ],
                2
              )
            : _c("div", { staticClass: "cropper" }, [
                _c(
                  "div",
                  { staticClass: "cropper-div" },
                  [
                    _c("vue-cropper", {
                      ref: "cropper",
                      attrs: {
                        img: _vm.cropperImg,
                        autoCrop: true,
                        autoCropWidth: _vm.cropperWidth,
                        autoCropHeight: _vm.cropperHeight,
                        fixed: true,
                        fixedNumber: [_vm.cropperWidth, _vm.cropperHeight],
                        outputType: "png",
                      },
                      on: { realTime: _vm.realTime },
                    }),
                    _c("div", { staticClass: "realTime" }, [
                      _c("div", { style: _vm.previewStyle2 }, [
                        _c("div", { style: _vm.previews.div }, [
                          _c("img", {
                            style: _vm.previews.img,
                            attrs: { src: _vm.previews.url },
                          }),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c("el-button", { on: { click: _vm.hideCropperDialog } }, [
                      _vm._v("取 消"),
                    ]),
                    _c(
                      "el-button",
                      { attrs: { type: "primary" }, on: { click: _vm.finish } },
                      [_vm._v("确 定")]
                    ),
                  ],
                  1
                ),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm.pageType == 1
        ? [
            _c("el-image", {
              staticClass: "head-img",
              attrs: { src: _vm.titleImg, fit: "fill", alt: "表单顶图" },
            }),
            _c("v-form-render", {
              ref: "vFormRef",
              attrs: { "form-json": _vm.formJson, "form-data": _vm.formData },
            }),
            _c(
              "div",
              { staticClass: "btns" },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.handleCurrentChange(1)
                      },
                    },
                  },
                  [_vm._v(" 查看历史记录 ")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", disabled: _vm.sumitDisabled },
                    on: { click: _vm.submitForm },
                  },
                  [_vm._v("提交")]
                ),
              ],
              1
            ),
            _c("div", {
              staticStyle: {
                height: "100px",
                width: "100%",
                "flex-shrink": "0",
              },
            }),
          ]
        : _vm.pageType == 3
        ? [
            _c("el-image", {
              staticClass: "head-img",
              attrs: { src: _vm.titleImg3, fit: "fill", alt: "表单顶图" },
            }),
            _c("v-form-render", {
              ref: "vFormRef3",
              attrs: { "form-json": _vm.formJson3, "form-data": _vm.formData3 },
            }),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { width: "15rem", "margin-bottom": "1rem" },
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.backHistory(2)
                      },
                    },
                  },
                  [_vm._v("返回")]
                ),
              ],
              1
            ),
          ]
        : [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.page.loading,
                    expression: "page.loading",
                  },
                ],
                staticClass: "my-list",
                attrs: { "element-loading-text": "拼命加载中..." },
              },
              [
                _c("custom-form-history-list", {
                  attrs: { list: _vm.page.tableData },
                  scopedSlots: _vm._u([
                    {
                      key: "btns",
                      fn: function (rows) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { icon: "file-text", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.viewDetail(rows.item.id)
                                },
                              },
                            },
                            [_vm._v(" 查看 ")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            false
              ? _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.page.loading,
                        expression: "page.loading",
                      },
                    ],
                    attrs: {
                      "show-header": false,
                      stripe: "",
                      "element-loading-text": "拼命加载中...",
                      data: _vm.page.tableData,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        width: "50",
                        align: "center",
                        type: "index",
                        index: _vm.indexMethod,
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "applyDate",
                        align: "center",
                        label: "时间",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(scope.row.applyDate.split(" ")[0])
                                  ),
                                ]),
                                _c("div", [
                                  _vm._v(
                                    _vm._s(scope.row.applyDate.split(" ")[1])
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        82262901
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "mobile", align: "center", label: "电话" },
                    }),
                    _c("el-table-column", {
                      attrs: { width: "100", align: "center" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { icon: "file-text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewDetail(scope.row.id)
                                      },
                                    },
                                  },
                                  [_vm._v(" 查看 ")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4204876904
                      ),
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "pagesBox" },
              [
                _c("el-pagination", {
                  attrs: {
                    layout: "prev,pager,next",
                    "page-sizes": _vm.page.pagesizes,
                    total: _vm.page.total,
                    "pager-count": 5,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange,
                  },
                }),
                _c(
                  "div",
                  { staticClass: "back-btn" },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.backHistory(1)
                          },
                        },
                      },
                      [_vm._v(" 返回")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
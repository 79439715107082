import axios from 'axios'
import ElementUI from 'element-ui'
import { removeStorage, getStorage } from '@/utils/storage'

const request = axios.create({
  // timeout: 10000
})

if (process.env.NODE_ENV == 'development') {
  request.defaults.baseURL = 'api'
  // request.defaults.baseURL = 'http://power.fcmall.net/gw'
  // request.defaults.baseURL = 'http://192.168.1.125:18001/gw'
} else {
  request.defaults.baseURL = process.env.VUE_APP_BASE_API
}

// 配置请求拦截器
request.interceptors.request.use(config => {
  // console.log('request.config:', config)
  if (config.method === 'post') {
    const userInfo = getStorage('laike_admin_userInfo')
    config.params = {
      // 商城id
      storeId: userInfo ? userInfo.storeId : null,
      // 来源
      storeType: 8,
      ...config.params,
      // token
      accessId: userInfo ? userInfo.token : null
    }
    if (config.params.exportType && config.params.exportType == 1) {
      config.headers = {
        'Content-Type': 'application/octet-stream;charset=utf-8'
      }
      config.responseType = 'blob'
    }
  }
  return config
}, err => {
  console.log('请求未到服务器，失败')
  return Promise.reject(err)
})

// 配置响应拦截器
request.interceptors.response.use((res) => {
  // debugger
  // if (res.request.responseURL.indexOf('api=customer.wx.bind.mini') >= 0) {
  //   res.data.code = 203
  // }

  if (res.data.code == '203') {
    // 单独处理 机构关联时 后端返回203
    if (res.request.responseURL.indexOf('api=customer.wx.bind.mini') >= 0) {
      // debugger
      localStorage.setItem('relBindURL', res.request.responseURL)
      ElementUI.Message({
        message: '关联完成，请重新登录',
        type: 'success',
        offset: 100
      })
    }

    window.localStorage.clear()
    // 单独处理 机构关联时 后端返回203
    if (res.request.responseURL.indexOf('api=customer.wx.bind.mini') >= 0) {
      // debugger
      localStorage.setItem('relBindURL', res.request.responseURL)
      ElementUI.Message({
        message: '关联完成，请重新登录',
        type: 'success',
        offset: 100
      })
    }

    window.location.href = '/login'
    location.reload()
  }
  if (res.data instanceof Blob) {
    return res
  }
  if (res.status && res.data.code == '200') {
    return res
  } else {
    ElementUI.Message({
      message: res.data.message,
      type: 'error',
      offset: 100
    })
    return res
  }
}, (error) => {
  console.error(error)
  ElementUI.Message({
    message: error,
    type: 'error',
    offset: 100
  })
})

export default request

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wpp" },
    [
      _c("wxthird-header"),
      !_vm.isErr
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "box",
            },
            [
              _c(
                "el-steps",
                {
                  staticStyle: { "margin-bottom": "100px", cursor: "pointer" },
                  attrs: { active: _vm.active, "finish-status": "success" },
                },
                _vm._l(_vm.stepList, function (item, index) {
                  return _c("el-step", {
                    key: index,
                    attrs: { title: item },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.changeStep(index)
                      },
                    },
                  })
                }),
                1
              ),
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.param,
                    rules: _vm.rules,
                    "label-width": "180px",
                    "close-on-click-modal": false,
                  },
                },
                [
                  _vm.active == 0
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              ref: "miniName",
                              attrs: { label: "小程序名称", prop: "miniName" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "name-item",
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: {
                                      width: "50%",
                                      "margin-right": "10px",
                                    },
                                    attrs: {
                                      disabled:
                                        _vm.miniNameObj.nickNameAuditstatus ==
                                        1,
                                    },
                                    model: {
                                      value: _vm.param.miniName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.param, "miniName", $$v)
                                      },
                                      expression: "param.miniName",
                                    },
                                  }),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        disabled:
                                          _vm.miniNameObj.nickNameAuditstatus ==
                                          1,
                                      },
                                      on: { click: _vm.checkName },
                                      model: {
                                        value: _vm.param.miniName,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.param, "miniName", $$v)
                                        },
                                        expression: "param.miniName",
                                      },
                                    },
                                    [_vm._v("检测名称是否可用")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm.miniNameObj.showMore
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "营业执照或组织代码证",
                                    required: "",
                                    prop: "licenseMediaid",
                                    rules: {
                                      required: true,
                                      message: "营业执照或组织代码证不能为空",
                                      trigger: "change",
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      attrs: {
                                        action: _vm.imgUrl,
                                        data: {
                                          appid: _vm.appid,
                                          type: "image",
                                        },
                                        limit: 1,
                                        accept: "image/png, image/jpeg",
                                        "before-upload": _vm.beforeUpload,
                                        "file-list":
                                          _vm.miniNameObj.fileListLicense,
                                        "on-success": function (
                                          response,
                                          file,
                                          fileList
                                        ) {
                                          return _vm.getNameFile(
                                            response,
                                            file,
                                            fileList,
                                            "license"
                                          )
                                        },
                                        "on-remove": function (file, fileList) {
                                          return _vm.removeNameFile(
                                            file,
                                            fileList,
                                            "license"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "primary",
                                          },
                                        },
                                        [_vm._v("点击上传")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "el-upload__tip",
                                          attrs: { slot: "tip" },
                                          slot: "tip",
                                        },
                                        [
                                          _vm._v(
                                            " 只能上传jpg/png文件，且不超过1MB "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          false
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "管理员身份证照片" } },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      attrs: {
                                        action: _vm.imgUrl,
                                        data: {
                                          appid: _vm.appid,
                                          type: "image",
                                        },
                                        limit: 1,
                                        accept: "image/png, image/jpeg",
                                        "before-upload": _vm.beforeUpload,
                                        "file-list":
                                          _vm.miniNameObj.fileListIdcard,
                                        "on-success": function (
                                          response,
                                          file,
                                          fileList
                                        ) {
                                          return _vm.getNameFile(
                                            response,
                                            file,
                                            fileList,
                                            "idCard"
                                          )
                                        },
                                        "on-remove": function (file, fileList) {
                                          return _vm.removeNameFile(
                                            file,
                                            fileList,
                                            "idCard"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "primary",
                                          },
                                        },
                                        [_vm._v("点击上传")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "el-upload__tip",
                                          attrs: { slot: "tip" },
                                          slot: "tip",
                                        },
                                        [
                                          _vm._v(
                                            " 只能上传jpg/png文件，且不超过1MB "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.miniNameObj.showMore
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "补充材料" } },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      attrs: {
                                        action: _vm.imgUrl,
                                        data: {
                                          appid: _vm.appid,
                                          type: "image",
                                        },
                                        limit: 8,
                                        accept: "image/png, image/jpeg",
                                        "before-upload": _vm.beforeUpload,
                                        "file-list": _vm.miniNameObj.fileListFJ,
                                        "on-success": function (
                                          response,
                                          file,
                                          fileList
                                        ) {
                                          return _vm.getNameFile(
                                            response,
                                            file,
                                            fileList,
                                            "FJ"
                                          )
                                        },
                                        "on-remove": function (file, fileList) {
                                          return _vm.removeNameFile(
                                            file,
                                            fileList,
                                            "FJ"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "primary",
                                          },
                                        },
                                        [_vm._v("点击上传")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "el-upload__tip",
                                          attrs: { slot: "tip" },
                                          slot: "tip",
                                        },
                                        [
                                          _vm._v(
                                            " 只能上传jpg/png文件，且不超过1MB "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("el-form-item", [
                            _vm.miniNameObj.tips
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "name-tips",
                                    class: _vm.miniNameObj.status
                                      ? "suc"
                                      : "err",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.miniNameObj.tips) + " "
                                    ),
                                  ]
                                )
                              : _c("div", { staticClass: "name-tips" }, [
                                  _vm._v(
                                    " 帐号名称长度为4-30个字符，一个中文字等于2个字符。 "
                                  ),
                                ]),
                          ]),
                          _c(
                            "el-form-item",
                            [
                              _vm.miniNameObj.status ||
                              (_vm.miniNameObj.showMore &&
                                _vm.param.licenseMediaid)
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: { click: _vm.submitName },
                                    },
                                    [_vm._v("提交")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.active == 1
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              ref: "miniHead",
                              attrs: { label: "小程序logo", prop: "miniHead" },
                            },
                            [
                              _c(
                                "el-upload",
                                {
                                  attrs: {
                                    action: _vm.imgUrl,
                                    data: { appid: _vm.appid, type: "image" },
                                    accept: "image/png, image/jpeg",
                                    "before-upload": _vm.beforeUpload,
                                    "show-file-list": false,
                                    "on-success": _vm.getLogoFile,
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "small", type: "primary" },
                                    },
                                    [_vm._v("点击上传")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "el-upload__tip",
                                      attrs: { slot: "tip" },
                                      slot: "tip",
                                    },
                                    [
                                      _vm._v(
                                        " 头像不允许涉及政治敏感与色情;图片格式必须为：png,bmp,jpeg,jpg,gif；不可大于1M；建议使用png格式图片，以保持最佳效果；建议图片尺寸为144px*144px "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm.param.miniHead
                                ? _c(
                                    "div",
                                    { staticClass: "upload-image-show" },
                                    [
                                      _c("img", {
                                        staticClass: "upload-image-show1",
                                        attrs: {
                                          src: _vm.param.miniHead,
                                          alt: "",
                                        },
                                      }),
                                      _c("img", {
                                        staticClass: "upload-image-show2",
                                        attrs: {
                                          src: _vm.param.miniHead,
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            [
                              _vm.logoId
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: { click: _vm.submitLogo },
                                    },
                                    [_vm._v("提交")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.active == 2
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              ref: "miniSignature",
                              attrs: {
                                label: "小程序介绍",
                                prop: "miniSignature",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  "auto-size": { minRows: 2, maxRows: 6 },
                                },
                                model: {
                                  value: _vm.param.miniSignature,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.param, "miniSignature", $$v)
                                  },
                                  expression: "param.miniSignature",
                                },
                              }),
                              _c("p", { staticClass: "gary" }, [
                                _vm._v(
                                  " 请确认介绍内容不含国家相关法律法规禁止内容,介绍字数为4~120个字 "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.submitSignature },
                                },
                                [_vm._v("提交")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.active == 3
                    ? _c(
                        "div",
                        [
                          _c("el-form-item", [
                            _c("div", { staticClass: "name-tips" }, [
                              _vm._v("以下4种联系方式至少要填一种"),
                            ]),
                          ]),
                          _c(
                            "el-form-item",
                            {
                              ref: "ownerContactPhone",
                              attrs: {
                                label: "电话",
                                prop: "ownerContactPhone",
                              },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.param.ownerContactPhone,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.param,
                                      "ownerContactPhone",
                                      $$v
                                    )
                                  },
                                  expression: "param.ownerContactPhone",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              ref: "ownerContactEmail",
                              attrs: {
                                label: "邮箱",
                                prop: "ownerContactEmail",
                              },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.param.ownerContactEmail,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.param,
                                      "ownerContactEmail",
                                      $$v
                                    )
                                  },
                                  expression: "param.ownerContactEmail",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              ref: "ownerContactWeixin",
                              attrs: {
                                label: "微信",
                                prop: "ownerContactWeixin",
                              },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.param.ownerContactWeixin,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.param,
                                      "ownerContactWeixin",
                                      $$v
                                    )
                                  },
                                  expression: "param.ownerContactWeixin",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              ref: "ownerContactQq",
                              attrs: { label: "QQ", prop: "ownerContactQq" },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.param.ownerContactQq,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.param, "ownerContactQq", $$v)
                                  },
                                  expression: "param.ownerContactQq",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.submitContact },
                                },
                                [_vm._v("提交")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "box" },
            [
              _c(
                "el-result",
                { attrs: { title: "注册错误", subTitle: _vm.isErr } },
                [
                  _c(
                    "template",
                    { slot: "icon" },
                    [
                      _c("el-image", {
                        staticStyle: { width: "400px" },
                        attrs: {
                          src: "https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/460/1/20240805/1820305289252704256.png",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "extra" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "medium" },
                          on: { click: _vm.relogin },
                        },
                        [_vm._v("重新登录，再试一次")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
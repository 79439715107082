import axios from 'axios'
// 独立请求
export const postFormDataWx = (data, params) => {
  return axios({
    method: 'post',
    url: process.env.VUE_APP_BASE_API,
    data: data,
    params: params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    transformRequest: [
      function(data) {
        let ret = ''
        for (const it in data) {
          ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
        }
        ret = ret.substring(0, ret.lastIndexOf('&'))
        return ret
      }
    ] })
}
export const postWx = (params) => {
  return axios({
    method: 'post',
    url: process.env.VUE_APP_BASE_API,
    params: params })
}
export const getWx = (params) => {
  return axios({
    method: 'get',
    url: process.env.VUE_APP_BASE_API,
    params: params })
}

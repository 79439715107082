var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "navHeader", staticClass: "navbar" }, [
    _c("div", { staticClass: "left-control" }, [
      _vm.$store.getters.merchants_Logo
        ? _c("img", {
            attrs: { src: _vm.$store.getters.merchants_Logo, alt: "" },
          })
        : _vm._e(),
      _c("div", { staticClass: "merchantName" }, [
        _vm._v(_vm._s(_vm.merchantName)),
      ]),
    ]),
    _c("div", { staticClass: "right-block" }, [
      _c("ul", { staticClass: "Hui-userbar" }, [
        _c("li", { staticClass: "dropDown" }, [
          _c("div", { staticClass: "top-dropDown" }, [
            _c("img", { attrs: { src: _vm.$store.getters.head_img, alt: "" } }),
            _c("span", [_vm._v(_vm._s(_vm.username))]),
            _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
          ]),
          _c("ul", { staticClass: "dropDown-menus" }, [
            _c("li", { on: { click: _vm.dialogShow } }, [
              _c("i", { staticClass: "el-icon-lock" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("topNav.changePassword")))]),
            ]),
            _c("li", { on: { click: _vm.dialogShow2 } }, [
              _c("i", { staticClass: "el-icon-user" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("topNav.basicInformation")))]),
            ]),
          ]),
        ]),
        _vm.type == 0
          ? _c("li", { staticClass: "changeStore" }, [
              _c("div", { staticClass: "top-changeStore" }, [
                _c("img", {
                  staticClass: "shanghu",
                  attrs: {
                    src: require("@/assets/imgs/dark/jigou.png"),
                    alt: "",
                  },
                }),
                _c("span", [_vm._v(_vm._s(_vm.defaultVersion))]),
              ]),
              _c(
                "ul",
                { staticClass: "changeStore-menus" },
                _vm._l(_vm.versionList, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      on: {
                        click: function ($event) {
                          return _vm.enterSystem(item)
                        },
                      },
                    },
                    [
                      _c("span", [_vm._v(_vm._s(item.name))]),
                      _c("img", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.id === _vm.showImg,
                            expression: "item.id === showImg",
                          },
                        ],
                        attrs: {
                          src: require("../../assets/imgs/gouxuan.png"),
                          alt: "",
                        },
                      }),
                    ]
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
        _c("li", { staticClass: "headerLi" }, [
          _c(
            "div",
            { staticClass: "top-headerLi" },
            [
              _vm.allNum
                ? _c("el-badge", {
                    staticClass: "totle-item",
                    attrs: { value: _vm.allNum, max: 99 },
                  })
                : _vm._e(),
              _c("img", {
                attrs: {
                  src: require("@/assets/imgs/dark/notification.png"),
                  alt: "",
                },
              }),
            ],
            1
          ),
          _vm.allNum
            ? _c("ul", { staticClass: "headerLi-menus" }, [
                _c("li", { staticClass: "leveone" }, [
                  _c(
                    "div",
                    {
                      staticClass: "topmenus",
                      on: {
                        click: function ($event) {
                          _vm.orderShow = !_vm.orderShow
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "order-remind" }, [
                        _vm._v("订单提醒"),
                      ]),
                      _c("div", { staticClass: "read" }, [
                        _c(
                          "div",
                          { staticClass: "operation" },
                          [
                            _vm.orderInfo.total
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "operation-read",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.orderAllRead($event)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "laiketui laiketui-biaojiweiyidu",
                                    }),
                                    _c(
                                      "span",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.noticeReads(
                                              "1,2,3,4,5,6"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("一键已读")]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c("el-badge", {
                              staticClass: "item",
                              attrs: { value: _vm.orderInfo.total, max: 99 },
                            }),
                            _c("i", {
                              class: [
                                _vm.orderShow
                                  ? "el-icon-arrow-down"
                                  : "el-icon-arrow-right",
                              ],
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "ul",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.orderShow,
                          expression: "orderShow",
                        },
                      ],
                      staticClass: "detail-menus",
                    },
                    [
                      _vm.orderInfo.list
                        ? _c("li", { staticClass: "levetwo" }, [
                            _c(
                              "div",
                              {
                                staticClass: "submenu",
                                on: {
                                  click: function ($event) {
                                    _vm.notificationDelivery =
                                      !_vm.notificationDelivery
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "order-remind" }, [
                                  _vm._v("待发货通知"),
                                ]),
                                _c("div", { staticClass: "read" }, [
                                  _c("div", { staticClass: "operation" }, [
                                    _vm.orderInfo.list[0].total
                                      ? _c(
                                          "div",
                                          { staticClass: "operation-read" },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "laiketui laiketui-biaojiweiyidu",
                                            }),
                                            _c(
                                              "span",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.noticeReads(1)
                                                  },
                                                },
                                              },
                                              [_vm._v("一键已读")]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("span", [
                                      _vm._v(
                                        "（" +
                                          _vm._s(_vm.orderInfo.list[0].total) +
                                          "）"
                                      ),
                                    ]),
                                    _c("i", {
                                      class: [
                                        _vm.notificationDelivery
                                          ? "el-icon-arrow-down"
                                          : "el-icon-arrow-right",
                                      ],
                                    }),
                                  ]),
                                ]),
                              ]
                            ),
                            _vm.orderInfo.list[0]
                              ? _c(
                                  "ul",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.notificationDelivery,
                                        expression: "notificationDelivery",
                                      },
                                    ],
                                    staticClass: "order-details",
                                  },
                                  _vm._l(
                                    _vm.orderInfo.list[0].list,
                                    function (item, index) {
                                      return _c(
                                        "li",
                                        {
                                          key: index,
                                          on: {
                                            click: function ($event) {
                                              return _vm.goOrderList(
                                                item.parameter
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "left-icon" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.getMessageLogo,
                                                  alt: "",
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "right-info" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "top-date" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "orderInfo",
                                                    },
                                                    [_vm._v("订单消息")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "date" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.add_date)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "bottom-detail",
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      _vm._s(item.content)
                                                    ),
                                                  ]),
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "javascript:;",
                                                      },
                                                    },
                                                    [_vm._v("查看")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm.orderInfo.list
                        ? _c("li", { staticClass: "levetwo" }, [
                            _c(
                              "div",
                              {
                                staticClass: "submenu",
                                on: {
                                  click: function ($event) {
                                    _vm.afterSales = !_vm.afterSales
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "order-remind" }, [
                                  _vm._v("售后待处理通知"),
                                ]),
                                _c("div", { staticClass: "read" }, [
                                  _c("div", { staticClass: "operation" }, [
                                    _vm.orderInfo.list[1].total
                                      ? _c(
                                          "div",
                                          { staticClass: "operation-read" },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "laiketui laiketui-biaojiweiyidu",
                                            }),
                                            _c(
                                              "span",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.noticeReads(2)
                                                  },
                                                },
                                              },
                                              [_vm._v("一键已读")]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("span", [
                                      _vm._v(
                                        "（" +
                                          _vm._s(_vm.orderInfo.list[1].total) +
                                          "）"
                                      ),
                                    ]),
                                    _c("i", {
                                      class: [
                                        _vm.afterSales
                                          ? "el-icon-arrow-down"
                                          : "el-icon-arrow-right",
                                      ],
                                    }),
                                  ]),
                                ]),
                              ]
                            ),
                            _vm.orderInfo.list[1]
                              ? _c(
                                  "ul",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.afterSales,
                                        expression: "afterSales",
                                      },
                                    ],
                                    staticClass: "order-details",
                                  },
                                  _vm._l(
                                    _vm.orderInfo.list[1].list,
                                    function (item, index) {
                                      return _c(
                                        "li",
                                        {
                                          key: index,
                                          on: {
                                            click: function ($event) {
                                              return _vm.salesReturnDetails(
                                                item.id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "left-icon" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.getMessageLogo,
                                                  alt: "",
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "right-info" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "top-date" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "orderInfo",
                                                    },
                                                    [_vm._v("订单消息")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "date" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.add_date)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "bottom-detail",
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      _vm._s(item.content)
                                                    ),
                                                  ]),
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "javascript:;",
                                                      },
                                                    },
                                                    [_vm._v("查看")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm.orderInfo.list
                        ? _c("li", { staticClass: "levetwo" }, [
                            _c(
                              "div",
                              {
                                staticClass: "submenu",
                                on: {
                                  click: function ($event) {
                                    _vm.shipmentRemind = !_vm.shipmentRemind
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "order-remind" }, [
                                  _vm._v("发货提醒通知"),
                                ]),
                                _c("div", { staticClass: "read" }, [
                                  _c("div", { staticClass: "operation" }, [
                                    _vm.orderInfo.list[2].total
                                      ? _c(
                                          "div",
                                          { staticClass: "operation-read" },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "laiketui laiketui-biaojiweiyidu",
                                            }),
                                            _c(
                                              "span",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.noticeReads(3)
                                                  },
                                                },
                                              },
                                              [_vm._v("一键已读")]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("span", [
                                      _vm._v(
                                        "（" +
                                          _vm._s(_vm.orderInfo.list[2].total) +
                                          "）"
                                      ),
                                    ]),
                                    _c("i", {
                                      class: [
                                        _vm.shipmentRemind
                                          ? "el-icon-arrow-down"
                                          : "el-icon-arrow-right",
                                      ],
                                    }),
                                  ]),
                                ]),
                              ]
                            ),
                            _vm.orderInfo.list[2]
                              ? _c(
                                  "ul",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.shipmentRemind,
                                        expression: "shipmentRemind",
                                      },
                                    ],
                                    staticClass: "order-details",
                                  },
                                  _vm._l(
                                    _vm.orderInfo.list[2].list,
                                    function (item, index) {
                                      return _c(
                                        "li",
                                        {
                                          key: index,
                                          on: {
                                            click: function ($event) {
                                              return _vm.goOrderList(
                                                item.parameter
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "left-icon" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.getMessageLogo,
                                                  alt: "",
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "right-info" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "top-date" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "orderInfo",
                                                    },
                                                    [_vm._v("订单消息")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "date" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.add_date)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "bottom-detail",
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      _vm._s(item.content)
                                                    ),
                                                  ]),
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "javascript:;",
                                                      },
                                                    },
                                                    [_vm._v("查看")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm.orderInfo.list
                        ? _c("li", { staticClass: "levetwo" }, [
                            _c(
                              "div",
                              {
                                staticClass: "submenu",
                                on: {
                                  click: function ($event) {
                                    _vm.orderDown = !_vm.orderDown
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "order-remind" }, [
                                  _vm._v("订单关闭通知"),
                                ]),
                                _c("div", { staticClass: "read" }, [
                                  _c("div", { staticClass: "operation" }, [
                                    _vm.orderInfo.list[3].total
                                      ? _c(
                                          "div",
                                          { staticClass: "operation-read" },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "laiketui laiketui-biaojiweiyidu",
                                            }),
                                            _c(
                                              "span",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.noticeReads(4)
                                                  },
                                                },
                                              },
                                              [_vm._v("一键已读")]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("span", [
                                      _vm._v(
                                        "（" +
                                          _vm._s(_vm.orderInfo.list[3].total) +
                                          "）"
                                      ),
                                    ]),
                                    _c("i", {
                                      class: [
                                        _vm.orderDown
                                          ? "el-icon-arrow-down"
                                          : "el-icon-arrow-right",
                                      ],
                                    }),
                                  ]),
                                ]),
                              ]
                            ),
                            _vm.orderInfo.list[3]
                              ? _c(
                                  "ul",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.orderDown,
                                        expression: "orderDown",
                                      },
                                    ],
                                    staticClass: "order-details",
                                  },
                                  _vm._l(
                                    _vm.orderInfo.list[3].list,
                                    function (item, index) {
                                      return _c(
                                        "li",
                                        {
                                          key: index,
                                          on: {
                                            click: function ($event) {
                                              return _vm.goOrderList(
                                                item.parameter
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "left-icon" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.getMessageLogo,
                                                  alt: "",
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "right-info" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "top-date" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "orderInfo",
                                                    },
                                                    [_vm._v("订单消息")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "date" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.add_date)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "bottom-detail",
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      _vm._s(item.content)
                                                    ),
                                                  ]),
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "javascript:;",
                                                      },
                                                    },
                                                    [_vm._v("查看")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm.orderInfo.list
                        ? _c("li", { staticClass: "levetwo" }, [
                            _c(
                              "div",
                              {
                                staticClass: "submenu",
                                on: {
                                  click: function ($event) {
                                    _vm.newOrder = !_vm.newOrder
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "order-remind" }, [
                                  _vm._v("新订单通知"),
                                ]),
                                _c("div", { staticClass: "read" }, [
                                  _c("div", { staticClass: "operation" }, [
                                    _vm.orderInfo.list[4].total
                                      ? _c(
                                          "div",
                                          { staticClass: "operation-read" },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "laiketui laiketui-biaojiweiyidu",
                                            }),
                                            _c(
                                              "span",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.noticeReads(5)
                                                  },
                                                },
                                              },
                                              [_vm._v("一键已读")]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("span", [
                                      _vm._v(
                                        "（" +
                                          _vm._s(_vm.orderInfo.list[4].total) +
                                          "）"
                                      ),
                                    ]),
                                    _c("i", {
                                      class: [
                                        _vm.newOrder
                                          ? "el-icon-arrow-down"
                                          : "el-icon-arrow-right",
                                      ],
                                    }),
                                  ]),
                                ]),
                              ]
                            ),
                            _vm.orderInfo.list[4]
                              ? _c(
                                  "ul",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.newOrder,
                                        expression: "newOrder",
                                      },
                                    ],
                                    staticClass: "order-details",
                                  },
                                  _vm._l(
                                    _vm.orderInfo.list[4].list,
                                    function (item, index) {
                                      return _c(
                                        "li",
                                        {
                                          key: index,
                                          on: {
                                            click: function ($event) {
                                              return _vm.goOrderList(
                                                item.parameter
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "left-icon" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.getMessageLogo,
                                                  alt: "",
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "right-info" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "top-date" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "orderInfo",
                                                    },
                                                    [_vm._v("订单消息")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "date" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.add_date)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "bottom-detail",
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      _vm._s(item.content)
                                                    ),
                                                  ]),
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "javascript:;",
                                                      },
                                                    },
                                                    [_vm._v("查看")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm.orderInfo.list
                        ? _c("li", { staticClass: "levetwo" }, [
                            _c(
                              "div",
                              {
                                staticClass: "submenu",
                                on: {
                                  click: function ($event) {
                                    _vm.orderGoods = !_vm.orderGoods
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "order-remind" }, [
                                  _vm._v("订单收货通知"),
                                ]),
                                _c("div", { staticClass: "read" }, [
                                  _c("div", { staticClass: "operation" }, [
                                    _vm.orderInfo.list[5].total
                                      ? _c(
                                          "div",
                                          { staticClass: "operation-read" },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "laiketui laiketui-biaojiweiyidu",
                                            }),
                                            _c(
                                              "span",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.noticeReads(6)
                                                  },
                                                },
                                              },
                                              [_vm._v("一键已读")]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("span", [
                                      _vm._v(
                                        "（" +
                                          _vm._s(_vm.orderInfo.list[5].total) +
                                          "）"
                                      ),
                                    ]),
                                    _c("i", {
                                      class: [
                                        _vm.orderGoods
                                          ? "el-icon-arrow-down"
                                          : "el-icon-arrow-right",
                                      ],
                                    }),
                                  ]),
                                ]),
                              ]
                            ),
                            _vm.orderInfo.list[5]
                              ? _c(
                                  "ul",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.orderGoods,
                                        expression: "orderGoods",
                                      },
                                    ],
                                    staticClass: "order-details",
                                  },
                                  _vm._l(
                                    _vm.orderInfo.list[5].list,
                                    function (item, index) {
                                      return _c(
                                        "li",
                                        {
                                          key: index,
                                          on: {
                                            click: function ($event) {
                                              return _vm.goOrderList(
                                                item.parameter
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "left-icon" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.getMessageLogo,
                                                  alt: "",
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "right-info" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "top-date" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "orderInfo",
                                                    },
                                                    [_vm._v("订单消息")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "date" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.add_date)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "bottom-detail",
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      _vm._s(item.content)
                                                    ),
                                                  ]),
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "javascript:;",
                                                      },
                                                    },
                                                    [_vm._v("查看")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]),
                _c("li", { staticClass: "leveone" }, [
                  _c(
                    "div",
                    {
                      staticClass: "topmenus",
                      on: {
                        click: function ($event) {
                          _vm.goodsShow = !_vm.goodsShow
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "goods-remind" }, [
                        _vm._v("商品提醒"),
                      ]),
                      _c("div", { staticClass: "read" }, [
                        _c(
                          "div",
                          { staticClass: "operation" },
                          [
                            _vm.goodsInfo.total
                              ? _c("div", { staticClass: "operation-read" }, [
                                  _c("i", {
                                    staticClass:
                                      "laiketui laiketui-biaojiweiyidu",
                                  }),
                                  _c(
                                    "span",
                                    {
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.noticeReads("7,9")
                                        },
                                      },
                                    },
                                    [_vm._v("一键已读")]
                                  ),
                                ])
                              : _vm._e(),
                            _c("el-badge", {
                              staticClass: "item",
                              attrs: { value: _vm.goodsInfo.total, max: 99 },
                            }),
                            _c("i", {
                              class: [
                                _vm.goodsShow
                                  ? "el-icon-arrow-down"
                                  : "el-icon-arrow-right",
                              ],
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "ul",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.goodsShow,
                          expression: "goodsShow",
                        },
                      ],
                      staticClass: "detail-menus",
                    },
                    [
                      _vm.goodsInfo.list
                        ? _c("li", { staticClass: "levetwo" }, [
                            _c(
                              "div",
                              {
                                staticClass: "submenu",
                                on: {
                                  click: function ($event) {
                                    _vm.goodsAudit = !_vm.goodsAudit
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "order-remind" }, [
                                  _vm._v("商品审核通知"),
                                ]),
                                _c("div", { staticClass: "read" }, [
                                  _c("div", { staticClass: "operation" }, [
                                    _vm.goodsInfo.list[0].total
                                      ? _c(
                                          "div",
                                          { staticClass: "operation-read" },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "laiketui laiketui-biaojiweiyidu",
                                            }),
                                            _c(
                                              "span",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.noticeReads(7)
                                                  },
                                                },
                                              },
                                              [_vm._v("一键已读")]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("span", [
                                      _vm._v(
                                        "（" +
                                          _vm._s(_vm.goodsInfo.list[0].total) +
                                          "）"
                                      ),
                                    ]),
                                    _c("i", {
                                      class: [
                                        _vm.goodsAudit
                                          ? "el-icon-arrow-down"
                                          : "el-icon-arrow-right",
                                      ],
                                    }),
                                  ]),
                                ]),
                              ]
                            ),
                            _vm.goodsInfo.list[0]
                              ? _c(
                                  "ul",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.goodsAudit,
                                        expression: "goodsAudit",
                                      },
                                    ],
                                    staticClass: "order-details",
                                  },
                                  _vm._l(
                                    _vm.goodsInfo.list[0].list,
                                    function (item, index) {
                                      return _c(
                                        "li",
                                        {
                                          key: index,
                                          on: {
                                            click: function ($event) {
                                              return _vm.goGoodsAudit(
                                                item.content
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "left-icon" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.getMessageLogo,
                                                  alt: "",
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "right-info" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "top-date" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "orderInfo",
                                                    },
                                                    [_vm._v("订单消息")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "date" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.add_date)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "bottom-detail",
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      _vm._s(item.content)
                                                    ),
                                                  ]),
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "javascript:;",
                                                      },
                                                    },
                                                    [_vm._v("查看")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm.goodsInfo.list
                        ? _c("li", { staticClass: "levetwo" }, [
                            _c(
                              "div",
                              {
                                staticClass: "submenu",
                                on: {
                                  click: function ($event) {
                                    _vm.goodsReplenishment =
                                      !_vm.goodsReplenishment
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "order-remind" }, [
                                  _vm._v("商品补货通知"),
                                ]),
                                _c("div", { staticClass: "read" }, [
                                  _c("div", { staticClass: "operation" }, [
                                    _vm.goodsInfo.list[1].total
                                      ? _c(
                                          "div",
                                          { staticClass: "operation-read" },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "laiketui laiketui-biaojiweiyidu",
                                            }),
                                            _c(
                                              "span",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.noticeReads(9)
                                                  },
                                                },
                                              },
                                              [_vm._v("一键已读")]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("span", [
                                      _vm._v(
                                        "（" +
                                          _vm._s(_vm.goodsInfo.list[1].total) +
                                          "）"
                                      ),
                                    ]),
                                    _c("i", {
                                      class: [
                                        _vm.goodsReplenishment
                                          ? "el-icon-arrow-down"
                                          : "el-icon-arrow-right",
                                      ],
                                    }),
                                  ]),
                                ]),
                              ]
                            ),
                            _vm.goodsInfo.list[1]
                              ? _c(
                                  "ul",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.goodsReplenishment,
                                        expression: "goodsReplenishment",
                                      },
                                    ],
                                    staticClass: "order-details",
                                  },
                                  _vm._l(
                                    _vm.goodsInfo.list[1].list,
                                    function (item, index) {
                                      return _c(
                                        "li",
                                        {
                                          key: index,
                                          on: {
                                            click: function ($event) {
                                              return _vm.goInventoryList(
                                                item.content
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "left-icon" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.getMessageLogo,
                                                  alt: "",
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "right-info" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "top-date" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "orderInfo",
                                                    },
                                                    [_vm._v("订单消息")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "date" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.add_date)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "bottom-detail",
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      _vm._s(item.content)
                                                    ),
                                                  ]),
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "javascript:;",
                                                      },
                                                    },
                                                    [_vm._v("查看")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
        _c(
          "li",
          {
            staticClass: "sign-out",
            attrs: { title: _vm.message },
            on: { click: _vm.signOut },
          },
          [
            _c("img", {
              attrs: { src: require("@/assets/imgs/dark/logout.png"), alt: "" },
            }),
          ]
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "dialog-block" },
      [
        _c(
          "el-dialog",
          {
            attrs: {
              title: "修改密码",
              visible: _vm.dialogVisible,
              "before-close": _vm.handleClose,
              "modal-append-to-body": false,
            },
            on: {
              "update:visible": function ($event) {
                _vm.dialogVisible = $event
              },
            },
          },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-ruleForm",
                attrs: {
                  model: _vm.ruleForm,
                  rules: _vm.rules,
                  "label-width": "100px",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "pass-input" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "原密码：", prop: "oldPassword" } },
                      [
                        _c("el-input", {
                          attrs: { "show-password": "" },
                          model: {
                            value: _vm.ruleForm.oldPassword,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "oldPassword", $$v)
                            },
                            expression: "ruleForm.oldPassword",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "新密码：", prop: "newPassword" } },
                      [
                        _c("el-input", {
                          attrs: { "show-password": "" },
                          model: {
                            value: _vm.ruleForm.newPassword,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "newPassword", $$v)
                            },
                            expression: "ruleForm.newPassword",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "确认密码：", prop: "confirmPassword" },
                      },
                      [
                        _c("el-input", {
                          attrs: { "show-password": "" },
                          model: {
                            value: _vm.ruleForm.confirmPassword,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "confirmPassword", $$v)
                            },
                            expression: "ruleForm.confirmPassword",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-footer" },
                  [
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "qxcolor",
                            on: {
                              click: function ($event) {
                                _vm.dialogVisible = false
                              },
                            },
                          },
                          [_vm._v("取 消")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "qdcolor",
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.determine("ruleForm")
                              },
                            },
                          },
                          [_vm._v("确 定")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "dialog-info" },
      [
        _c(
          "el-dialog",
          {
            attrs: {
              title: "基本信息",
              visible: _vm.dialogVisible2,
              "before-close": _vm.handleClose2,
              "modal-append-to-body": false,
            },
            on: {
              "update:visible": function ($event) {
                _vm.dialogVisible2 = $event
              },
            },
          },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm2",
                staticClass: "demo-ruleForm",
                attrs: { model: _vm.ruleForm, "label-width": "100px" },
              },
              [
                _c(
                  "div",
                  { staticClass: "pass-input" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "upload-headimg",
                        attrs: { label: "头像：" },
                      },
                      [
                        _c(
                          "el-upload",
                          {
                            staticClass: "avatar-uploader",
                            attrs: {
                              action: _vm.actionUrl,
                              data: _vm.uploadData,
                              "show-file-list": false,
                              "on-success": _vm.handleAvatarSuccess,
                              "before-upload": _vm.beforeAvatarUpload,
                            },
                          },
                          [
                            _vm.ruleForm2.headImg
                              ? _c("img", {
                                  staticClass: "avatar",
                                  attrs: { src: _vm.ruleForm2.headImg },
                                })
                              : _vm._e(),
                            _vm.ruleForm2.headImg
                              ? _c("img", {
                                  staticClass: "removeImg",
                                  attrs: { src: _vm.removeImg, alt: "" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.removeImgs($event)
                                    },
                                  },
                                })
                              : _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon",
                                }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "昵称：" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.ruleForm2.nickname,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm2, "nickname", $$v)
                            },
                            expression: "ruleForm2.nickname",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "生日：" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "date",
                            "value-format": "yyyy-MM-dd",
                            placeholder: "选择日期",
                          },
                          model: {
                            value: _vm.ruleForm2.birthday,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm2, "birthday", $$v)
                            },
                            expression: "ruleForm2.birthday",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "性别：" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.ruleForm2.gender,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm2, "gender", $$v)
                              },
                              expression: "ruleForm2.gender",
                            },
                          },
                          _vm._l(_vm.genderList, function (item) {
                            return _c(
                              "el-radio",
                              { key: item.value, attrs: { label: item.value } },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "手机号码：" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.ruleForm2.phone,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm2, "phone", $$v)
                            },
                            expression: "ruleForm2.phone",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-footer" },
                  [
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "qxcolor",
                            on: {
                              click: function ($event) {
                                _vm.dialogVisible2 = false
                              },
                            },
                          },
                          [_vm._v("取 消")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "qdcolor",
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.determine2("ruleForm2")
                              },
                            },
                          },
                          [_vm._v("确 定")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "l-pagination" },
    [
      _vm._v(" 显示 "),
      _c(
        "el-pagination",
        {
          attrs: {
            background: "",
            "current-page": _vm.pagination.currentPage,
            "pager-count": 5,
            "page-sizes": [10, 20, 50, 100],
            "page-size": _vm.pagination.pagesize,
            layout: "sizes, slot, prev, pager, next",
            "prev-text": "上一页",
            "next-text": "下一页",
            total: _vm.pagination.total,
          },
          on: {
            "size-change": _vm.handleSizeChange,
            "current-change": _vm.handleCurrentChange,
          },
        },
        [
          _vm._t("default", [
            _vm._v(
              " 当前显示" +
                _vm._s(_vm.showItem) +
                "条，共 " +
                _vm._s(_vm.pagination.total) +
                " 条记录 "
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "poster" }, [
    _c("header", [
      _c("img", {
        attrs: {
          src: "https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/195/1/20230322/1638464141599105024.png",
          alt: "",
        },
      }),
      _vm.isLogin
        ? _c("div", { staticClass: "sign-out", on: { click: _vm.signOut } }, [
            _c("img", {
              attrs: {
                src: "https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/195/1/20230317/1636679562362019840.png",
              },
            }),
            _c("span", [_vm._v("退出")]),
          ])
        : _vm._e(),
    ]),
    _vm._m(0),
    _c("div", { class: [_vm.showBtn ? "show" : "", "btns"] }, [
      _c("div", { staticClass: "btns-login", on: { click: _vm.login } }, [
        _vm._v(" " + _vm._s(_vm.btnName) + " "),
      ]),
      !_vm.isLogin
        ? _c("div", { staticClass: "btns-ps", on: { click: _vm.go } }, [
            _vm._v("去评审"),
          ])
        : _vm._e(),
    ]),
    _vm.boxShow
      ? _c("div", { staticClass: "login-box" }, [
          _c(
            "div",
            { staticClass: "login" },
            [
              _vm._m(1),
              _c(
                "el-form",
                {
                  ref: "merchantsForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.merchantsForm,
                    "status-icon": "",
                    rules: _vm.rules2,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "merchants-relative",
                      attrs: { prop: "customerNumber" },
                    },
                    [
                      false
                        ? _c("el-input", {
                            model: {
                              value: _vm.merchantsForm.customerNumber,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.merchantsForm,
                                  "customerNumber",
                                  $$v
                                )
                              },
                              expression: "merchantsForm.customerNumber",
                            },
                          })
                        : _vm._e(),
                      _c(
                        "el-input",
                        {
                          ref: "userNum",
                          attrs: {
                            placeholder: "请输入机构名称",
                            type: "text",
                            autocomplete: "on",
                            "prefix-icon": "el-icon-my-shop",
                          },
                          on: { input: _vm.getListByCompany },
                          model: {
                            value: _vm.company,
                            callback: function ($$v) {
                              _vm.company = $$v
                            },
                            expression: "company",
                          },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticClass: "my-icon",
                              attrs: { slot: "prefix" },
                              slot: "prefix",
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230310/1634090404913274880.png",
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _vm.isShowList
                        ? _c(
                            "ul",
                            { staticClass: "merchants-list" },
                            _vm._l(_vm.companyList, function (item, idx) {
                              return _c(
                                "li",
                                {
                                  key: idx,
                                  on: {
                                    click: function ($event) {
                                      return _vm.getNumber(item)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(item.company))]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "userName" } },
                    [
                      _c(
                        "el-input",
                        {
                          ref: "userName",
                          attrs: {
                            placeholder: "请输入账号",
                            type: "text",
                            autocomplete: "on",
                            "prefix-icon": "el-icon-my-user",
                          },
                          model: {
                            value: _vm.merchantsForm.userName,
                            callback: function ($$v) {
                              _vm.$set(_vm.merchantsForm, "userName", $$v)
                            },
                            expression: "merchantsForm.userName",
                          },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticClass: "my-icon",
                              attrs: { slot: "prefix" },
                              slot: "prefix",
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230310/1634090390921076736.png",
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "pwd" } },
                    [
                      _c(
                        "el-input",
                        {
                          ref: "pwd",
                          attrs: {
                            placeholder: "请输入密码",
                            type: "password",
                            autocomplete: "on",
                          },
                          model: {
                            value: _vm.merchantsForm.pwd,
                            callback: function ($$v) {
                              _vm.$set(_vm.merchantsForm, "pwd", $$v)
                            },
                            expression: "merchantsForm.pwd",
                          },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticClass: "my-icon",
                              attrs: { slot: "prefix" },
                              slot: "prefix",
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230310/1634090348889956352.png",
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                loading: _vm.loading,
                                "native-type": "submit",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.merchantsLogin("merchantsForm")
                                },
                              },
                            },
                            [_vm._v("登录")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "close",
                  on: {
                    click: function ($event) {
                      _vm.boxShow = false
                    },
                  },
                },
                [_c("i", { staticClass: "el-icon-close" })]
              ),
            ],
            1
          ),
          _c("div", {
            staticClass: "mask",
            on: {
              click: function ($event) {
                _vm.boxShow = false
              },
            },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "body" }, [
      _c("div", { staticClass: "top" }, [
        _vm._v(
          " 各省、自治区、直辖市林业和草原主管部门，内蒙古森工集团，新疆生产建设兵团林业和草原主管部门，大兴安岭林业集团，各有关高校： 为认真贯彻党的二十大精神，落实中共中央办公厅、国务院办公厅《关于进一步引导和鼓励高校毕业生到基层工作的意见》《关于做好当前和今后一段时期就业创业工作的意见》，进一步增强广大林草行业工作者的使命感、荣誉感，激励广大林草院校毕业生积极投身林草事业，扎根基层建功立业，为推进林草事业高质量发展和建设生态文明、美丽中国贡献力量，我局决定开展第三届“扎根基层工作、献身林草事业”林草学科优秀毕业生学习宣传活动。本活动分为两个阶段，第一阶段组织推荐30名第三届“扎根基层工作、献身林草事业”林草学科优秀毕业生，第二阶段组织优秀学子进校园宣传学习交流活动（通知另发）。现将第一阶段有关事项通知如下： "
        ),
      ]),
      _c("div", { staticClass: "title" }, [
        _c("img", {
          attrs: {
            src: "https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230310/1634027024772947968.png",
            alt: "",
          },
        }),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "index" }, [
          _c("img", {
            attrs: {
              src: "https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230310/1634027868994068480.png",
              alt: "",
            },
          }),
        ]),
        _c("div", { staticClass: "cont" }, [
          _vm._v(
            " 扎根林草基层工作，服务林草事业发展，为推动基层林草改革和事业发展作出贡献的林草学科大中专毕业生。 "
          ),
        ]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "index" }, [
          _c("img", {
            attrs: {
              src: "https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230310/1634028095431958528.png",
              alt: "",
            },
          }),
        ]),
        _c("div", { staticClass: "cont" }, [
          _c("p", [
            _c("strong", [
              _vm._v("林业和草原建设一线工作的基层人员且符合下列条件。"),
            ]),
          ]),
          _c("p", [
            _vm._v(
              "（一）自觉拥护党的路线、方针、政策，忠于党和国家事业，品行端正，无违法犯罪和党纪政纪处分记录。"
            ),
          ]),
          _c("p", [_vm._v("（二）林草学科专业毕业，中专及以上学历。")]),
          _c("p", [
            _vm._v(
              "（三）热爱林草事业，扎根林草基层（原则上为县级及以下林草机构或艰苦边远地区林草工作第一线）工作，具有强烈的事业心和责任感。"
            ),
          ]),
          _c("p", [
            _vm._v(
              "（四）精通业务工作，爱岗敬业、勇于创新、甘于奉献，工作期间取得较为突出的业绩成效和社会贡献。"
            ),
          ]),
          _c("p", [
            _vm._v(
              "（五）连续在林草基层工作5年或累计工作10年以上（计算时间截至2023年3月31日），且现仍在林草基层工作。事迹特别突出的对应减少一年的时间限制，但须由所在地县级以上林业和草原主管部门出具先进事迹说明函。"
            ),
          ]),
          _c("p", [
            _vm._v(
              "（六）工作成绩突出，事迹感人。既可以是立足本职工作，在开展国土绿化、国家公园保护地体系建设、深化集体林权制度改革、生物多样性保护、生态系统保护和修复、草原森林河流湖泊湿地修养生息、建立生态产品价值实现机制、树立大食物观和发展乡村特色产业、森林草原防火防虫等某个方面开拓进取，取得了重大成绩的个人；也可以是在艰苦边远地区长期默默奉献，任劳任怨，不计个人得失，为服务林区（牧区）经济发展、社会稳定作出贡献的典型等。"
            ),
          ]),
          _c("p", [
            _vm._v(
              "（七）年龄原则上在45周岁以下（1978年3月31日以后出生）。事迹特别突出的可适当放宽年龄限制。"
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "index" }, [
          _c("img", {
            attrs: {
              src: "https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230310/1634028167863394304.png",
              alt: "",
            },
          }),
        ]),
        _c("div", { staticClass: "cont" }, [
          _vm._v("在全国范围内推荐30名林草学科优秀毕业生。"),
        ]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "index" }, [
          _c("img", {
            attrs: {
              src: "https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230310/1634028223177875456.png",
              alt: "",
            },
          }),
        ]),
        _c("div", { staticClass: "cont" }, [
          _c("p", [
            _vm._v(
              "推荐工作由我局人事司负责，国家林业和草原局职业教育研究中心（以下简称“林草职教中心”）、中国林业教育学会具体组织实施。推荐办公室设在林草职教中心，负责推荐工作的具体组织实施等。"
            ),
          ]),
          _c("p", [
            _vm._v(
              "（省级林草主管部门和各相关院校在规定时间内组织参选人进行网上材料申报，林草职教中心按程序组织网络推荐和会议推荐，推荐最终结果公示5日后由国家林草局发文向社会公布，获评的优秀毕业生颁发国家林草局荣誉证书。"
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "title" }, [
        _c("img", {
          attrs: {
            src: "https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/195/1/20230317/1636666614310690816.png",
            alt: "",
          },
        }),
      ]),
      _c("div", { staticClass: "process" }, [
        _c("img", {
          attrs: {
            src: "https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/195/1/20230317/1636666694396731392.png",
            alt: "",
          },
        }),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "index" }, [
          _c("img", {
            attrs: {
              src: "https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230314/1635574649825452032.png",
              alt: "",
            },
          }),
        ]),
        _c("div", { staticClass: "cont" }, [
          _c("p", [
            _vm._v(
              "上报材料应包括《“扎根基层工作、献身林草事业”林草学科优秀毕业生推荐人选汇总表》《“扎根基层工作、献身林草事业”林草学科优秀毕业生推荐人选申报表》（附件3、4），个人详细事迹材料WORD版和PDF版（需加盖公章）各1份，支撑材料1份（凸显推荐人选工作特质的图片jpg/jpeg格式电子版5幅，毕业证书、学位证书、获奖证书等证明材料扫描件）。"
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "index" }, [
          _c("img", {
            attrs: {
              src: "https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230314/1635574635153776640.png",
              alt: "",
            },
          }),
        ]),
        _c("div", { staticClass: "cont" }, [
          _c("p", [_vm._v("（一）国家林业和草原局人事司教育培训处")]),
          _c("p", [_vm._v("联系人：张英帅")]),
          _c("p", [_vm._v("电 话：010—84239709")]),
          _c("p", [_vm._v("（二）国家林业和草原局职业教育研究中心")]),
          _c("p"),
          _c("p", [_vm._v("电 话：010—60282199 13552249920")]),
          _c("p", [_vm._v("邮 箱：linyezhijiao@126.com")]),
          _c("p", [_vm._v("地 址：北京市大兴区林校北路8号")]),
          _c("p", [_vm._v("（三）中国林业教育学会秘书处")]),
          _c("p", [_vm._v("联系人：王 壮")]),
          _c("p", [_vm._v("电 话：010-62337705")]),
          _c("p", [_vm._v("特此通知。")]),
        ]),
      ]),
      _c("div", { staticClass: "title" }, [
        _c("img", {
          attrs: {
            src: "https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230310/1634027188975755264.png",
            alt: "",
          },
        }),
      ]),
      _c("div", { staticClass: "link" }, [
        _c("a", { attrs: { href: "javascript:;" } }, [
          _vm._v("1.林草学科优秀毕业生联络员申请表"),
        ]),
        _c("a", { attrs: { href: "javascript:;" } }, [
          _vm._v("2.林草学科优秀毕业生推荐人选名额分配表"),
        ]),
        _c("a", { attrs: { href: "javascript:;" } }, [
          _vm._v(
            "3.“扎根基层工作、献身林草事业”林草学科优秀毕业生推荐人选汇总表"
          ),
        ]),
        _c("a", { attrs: { href: "javascript:;" } }, [
          _vm._v("4.“扎根基层工作、献身林草事业”林草学科优秀毕业生推荐申报表"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _c("img", {
        attrs: {
          src: "https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230310/1634090269336592384.png",
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
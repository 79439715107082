var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "my-table",
      style: {
        height: _vm.tableData.length > 0 ? "calc(100% - 76px)" : "100%",
      },
    },
    [
      _c(
        "table",
        {
          style: {
            height: _vm.tableData.length > 0 ? "auto" : "100%",
            width: _vm.isMobile
              ? 120 * (_vm.tableDataColumn[0].length + 4) + "px"
              : "100%",
          },
          attrs: { width: "100%" },
        },
        [
          _c("thead", [
            _c(
              "tr",
              [
                _c("td", [_vm._v("序号")]),
                _vm._l(_vm.tableDataColumn[0], function (item, index) {
                  return _c("td", { key: index }, [
                    _vm._v(" " + _vm._s(item.label) + " "),
                  ])
                }),
                _c("td", [_vm._v("联系方法")]),
                _c("td", [_vm._v("时间")]),
                _c("td", [_vm._v("操作")]),
              ],
              2
            ),
          ]),
          _vm.tableData.length > 0
            ? _c(
                "tbody",
                _vm._l(_vm.tableData, function (item, index) {
                  return _c(
                    "tr",
                    { key: index },
                    [
                      _c("td", { attrs: { align: "center" } }, [
                        _vm._v(_vm._s(index + 1)),
                      ]),
                      _vm._l(_vm.tableDataColumn[index], function (a, aindex) {
                        return _c("td", { key: aindex }, [
                          a.type === "picture-upload"
                            ? _c(
                                "div",
                                _vm._l(a.value, function (imgItem) {
                                  return _c("el-image", {
                                    key: imgItem,
                                    staticStyle: {
                                      width: "60px",
                                      margin: "0 5px",
                                    },
                                    attrs: {
                                      src: imgItem,
                                      alt: "",
                                      srcset: "",
                                      "preview-src-list": [imgItem],
                                    },
                                  })
                                }),
                                1
                              )
                            : a.type === "file-upload"
                            ? _c(
                                "div",
                                _vm._l(a.value, function (aItem, aIndex) {
                                  return _c(
                                    "a",
                                    {
                                      key: aItem,
                                      attrs: { href: aItem, title: aItem },
                                    },
                                    [_vm._v(_vm._s("附件" + (aIndex + 1)))]
                                  )
                                }),
                                0
                              )
                            : _c("div", [_vm._v(_vm._s(a.value))]),
                        ])
                      }),
                      _c("td", [_vm._v(_vm._s(item.mobile))]),
                      _c("td", { attrs: { align: "center" } }, [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm._f("GMTConvertYMDHMS")(item.applyDate))
                          ),
                        ]),
                      ]),
                      _c(
                        "td",
                        { attrs: { align: "center" } },
                        [_vm._t("btns", null, { item: item })],
                        2
                      ),
                    ],
                    2
                  )
                }),
                0
              )
            : _c("tbody", [_vm._m(0)]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { staticClass: "nodata-td", attrs: { colspan: "4" } }, [
        _vm._v("暂无数据"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
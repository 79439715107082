<template>
  <div class="poster">
    <header>
      <img
        src="https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/195/1/20230322/1638464141599105024.png"
        alt=""
      />
      <div class="sign-out" @click="signOut" v-if="isLogin">
        <img src="https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/195/1/20230317/1636679562362019840.png">
        <span>退出</span>
      </div>
    </header>
    <div class="body">
      <div class="top">
        各省、自治区、直辖市林业和草原主管部门，内蒙古森工集团，新疆生产建设兵团林业和草原主管部门，大兴安岭林业集团，各有关高校：
为认真贯彻党的二十大精神，落实中共中央办公厅、国务院办公厅《关于进一步引导和鼓励高校毕业生到基层工作的意见》《关于做好当前和今后一段时期就业创业工作的意见》，进一步增强广大林草行业工作者的使命感、荣誉感，激励广大林草院校毕业生积极投身林草事业，扎根基层建功立业，为推进林草事业高质量发展和建设生态文明、美丽中国贡献力量，我局决定开展第三届“扎根基层工作、献身林草事业”林草学科优秀毕业生学习宣传活动。本活动分为两个阶段，第一阶段组织推荐30名第三届“扎根基层工作、献身林草事业”林草学科优秀毕业生，第二阶段组织优秀学子进校园宣传学习交流活动（通知另发）。现将第一阶段有关事项通知如下：
      </div>
      <div class="title">
        <img
          src="https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230310/1634027024772947968.png"
          alt=""
        />
      </div>
      <div class="item">
        <div class="index">
          <img
            src="https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230310/1634027868994068480.png"
            alt=""
          />
        </div>
        <div class="cont">
          扎根林草基层工作，服务林草事业发展，为推动基层林草改革和事业发展作出贡献的林草学科大中专毕业生。
        </div>
      </div>
      <div class="item">
        <div class="index">
          <img
            src="https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230310/1634028095431958528.png"
            alt=""
          />
        </div>
        <div class="cont">
          <p><strong>林业和草原建设一线工作的基层人员且符合下列条件。</strong></p>
          <p>（一）自觉拥护党的路线、方针、政策，忠于党和国家事业，品行端正，无违法犯罪和党纪政纪处分记录。</p>
          <p>（二）林草学科专业毕业，中专及以上学历。</p>
          <p>（三）热爱林草事业，扎根林草基层（原则上为县级及以下林草机构或艰苦边远地区林草工作第一线）工作，具有强烈的事业心和责任感。</p>
          <p>（四）精通业务工作，爱岗敬业、勇于创新、甘于奉献，工作期间取得较为突出的业绩成效和社会贡献。</p>
          <p>（五）连续在林草基层工作5年或累计工作10年以上（计算时间截至2023年3月31日），且现仍在林草基层工作。事迹特别突出的对应减少一年的时间限制，但须由所在地县级以上林业和草原主管部门出具先进事迹说明函。</p>
          <p>（六）工作成绩突出，事迹感人。既可以是立足本职工作，在开展国土绿化、国家公园保护地体系建设、深化集体林权制度改革、生物多样性保护、生态系统保护和修复、草原森林河流湖泊湿地修养生息、建立生态产品价值实现机制、树立大食物观和发展乡村特色产业、森林草原防火防虫等某个方面开拓进取，取得了重大成绩的个人；也可以是在艰苦边远地区长期默默奉献，任劳任怨，不计个人得失，为服务林区（牧区）经济发展、社会稳定作出贡献的典型等。</p>
          <p>（七）年龄原则上在45周岁以下（1978年3月31日以后出生）。事迹特别突出的可适当放宽年龄限制。</p>
        </div>
      </div>
      <div class="item">
        <div class="index">
          <img
            src="https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230310/1634028167863394304.png"
            alt=""
          />
        </div>
        <div class="cont">在全国范围内推荐30名林草学科优秀毕业生。</div>
      </div>
      <div class="item">
        <div class="index">
          <img
            src="https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230310/1634028223177875456.png"
            alt=""
          />
        </div>
        <div class="cont">
          <p>推荐工作由我局人事司负责，国家林业和草原局职业教育研究中心（以下简称“林草职教中心”）、中国林业教育学会具体组织实施。推荐办公室设在林草职教中心，负责推荐工作的具体组织实施等。</p>
          <p>（省级林草主管部门和各相关院校在规定时间内组织参选人进行网上材料申报，林草职教中心按程序组织网络推荐和会议推荐，推荐最终结果公示5日后由国家林草局发文向社会公布，获评的优秀毕业生颁发国家林草局荣誉证书。</p>

        </div>
      </div>
      <div class="title">
        <img
          src="https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/195/1/20230317/1636666614310690816.png"
          alt=""
        />
      </div>
      <div class="process">
        <img
          src="https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/195/1/20230317/1636666694396731392.png"
          alt=""
        />
      </div>
      <div class="item">
        <div class="index">
          <img
            src="https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230314/1635574649825452032.png"
            alt=""
          />
        </div>
        <div class="cont">
          <p>上报材料应包括《“扎根基层工作、献身林草事业”林草学科优秀毕业生推荐人选汇总表》《“扎根基层工作、献身林草事业”林草学科优秀毕业生推荐人选申报表》（附件3、4），个人详细事迹材料WORD版和PDF版（需加盖公章）各1份，支撑材料1份（凸显推荐人选工作特质的图片jpg/jpeg格式电子版5幅，毕业证书、学位证书、获奖证书等证明材料扫描件）。</p>
        </div>
      </div>
      <div class="item">
        <div class="index">
          <img
            src="https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230314/1635574635153776640.png"
            alt=""
          />
        </div>
        <div class="cont">
          <p>（一）国家林业和草原局人事司教育培训处</p>
          <p>联系人：张英帅</p>
          <p>电  话：010—84239709</p>
          <p>（二）国家林业和草原局职业教育研究中心</p>
          <p联系人：杜 娟</p>
          <p>电  话：010—60282199   13552249920</p>
          <p>邮  箱：linyezhijiao@126.com</p>
          <p>地  址：北京市大兴区林校北路8号</p>
          <p>（三）中国林业教育学会秘书处</p>
          <p>联系人：王  壮</p>
          <p>电  话：010-62337705</p>
          <p>特此通知。</p>
        </div>
      </div>
      <div class="title">
        <img
          src="https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230310/1634027188975755264.png"
          alt=""
        />
      </div>
      <div class="link">
        <a href="javascript:;">1.林草学科优秀毕业生联络员申请表</a>
        <a href="javascript:;">2.林草学科优秀毕业生推荐人选名额分配表</a>
        <a href="javascript:;">3.“扎根基层工作、献身林草事业”林草学科优秀毕业生推荐人选汇总表</a>
        <a href="javascript:;">4.“扎根基层工作、献身林草事业”林草学科优秀毕业生推荐申报表</a>
      </div>
    </div>
    <div :class="[showBtn ? 'show' : '', 'btns']">
      <div class="btns-login" @click="login">
        {{btnName}}
      </div>
      <div class="btns-ps" @click="go" v-if="!isLogin">去评审</div>
    </div>
    <div class="login-box" v-if="boxShow">
      <div class="login">
        <h3>
          <img
            src="https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230310/1634090269336592384.png"
            alt=""
          />
        </h3>
        <el-form
          ref="merchantsForm"
          :model="merchantsForm"
          status-icon
          :rules="rules2"
          class="demo-ruleForm"
        >
          <el-form-item prop="customerNumber" class='merchants-relative'>
            <el-input v-model="merchantsForm.customerNumber" v-if="false"></el-input>
            <el-input
              ref="userNum"
              v-model="company"
              placeholder="请输入机构名称"
              type="text"
              autocomplete="on"
              @input="getListByCompany"
              prefix-icon="el-icon-my-shop"
              ><i slot="prefix" class="my-icon"
                ><img
                  src="https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230310/1634090404913274880.png"
                  alt="" /></i
            ></el-input>
            <ul class='merchants-list' v-if="isShowList">
              <li v-for="(item,idx) in companyList" :key="idx" @click="getNumber(item)">{{item.company}}</li>
            </ul>
          </el-form-item>
          <el-form-item prop="userName">
            <el-input
              ref="userName"
              v-model="merchantsForm.userName"
              placeholder="请输入账号"
              type="text"
              autocomplete="on"
              prefix-icon="el-icon-my-user"
              ><i slot="prefix" class="my-icon"
                ><img
                  src="https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230310/1634090390921076736.png"
                  alt="" /></i
            ></el-input>
          </el-form-item>
          <el-form-item prop="pwd">
            <el-input
              ref="pwd"
              v-model="merchantsForm.pwd"
              placeholder="请输入密码"
              type="password"
              autocomplete="on"
             
              ><i slot="prefix" class="my-icon"
                ><img
                  src="https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230310/1634090348889956352.png"
                  alt="" /></i
            ></el-input>
          </el-form-item>
          <div>
            <el-form-item>
              <el-button
                :loading="loading"
                native-type="submit"
                @click.native.prevent="merchantsLogin('merchantsForm')"
                >登录</el-button
              >
            </el-form-item>
          </div>
        </el-form>
        <div class="close" @click="boxShow = false">
          <i class="el-icon-close"></i>
        </div>
      </div>

      <div class="mask" @click="boxShow=false"></div>
    </div>
  </div>
</template>
<script>
import { getStorage } from "@/utils/storage";
import { http } from "@/api/plug_ins/plug_ins";
export default {
  data() {
    var validateUserNum = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入机构名称"));
      } else {
        // if (this.ruleForm.userNum !== "") {
        //   this.$refs.ruleForm.validateField("userNum");
        // }
        callback();
      }
    };
    var validateName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入账号"));
      } else {
        // if (this.ruleForm.name !== "") {
        //   this.$refs.ruleForm.validateField("name");
        // }
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        // if (this.ruleForm.pass !== "") {
        //   this.$refs.ruleForm.validateField("password");
        // }
        callback();
      }
    };
    return {
      company:"",
      showBtn: false,
      boxShow: false,
      merchantsForm: {
        customerNumber: "",
        userName: "",
        pwd: "",
      },
      rules2: {
        customerNumber: [{ validator: validateUserNum, trigger: "change" }],
        userName: [{ validator: validateName, trigger: "blur" }],
        pwd: [{ validator: validatePass, trigger: "blur" }],
      },
      loading: false,
      isLogin: getStorage("laike_admin_userInfo"),
      getRoleName:"",
      btnName:"去登录",
      type:0,
      companyList:[], //机构列表
      isShowList:false, //是否显示机构列表
      competitionId:"", // 竞赛id
    };
  },
  created(){
    this.getRoleName = window.localStorage.getItem("loginRole")
    if(this.isLogin){
      switch(this.getRoleName){
        case "学生":
            this.getData()
          break;
        case "专家":
          this.btnName = "去评审"
          break;
        default:
          this.btnName = "进入"
      }
    }else{
      this.btnName = "去登录"
    }
  },
  mounted() {
   
    document.addEventListener(
      "scroll",
      (e) => {
        let top = document.body.scrollTop || document.documentElement.scrollTop;
        this.showBtn = top >= 200;
      },
      true
    );
  },
  methods: {
    // 根据公司名称模糊查询机构列表
    getListByCompany(){
      if(this.company){
        http({
          api:"admin.saas.user.getListByCompany",
          company:this.company
        }).then(res=>{
          res = res.data
          if(res.code == '200'){
            this.isShowList = res.data.length
            this.companyList = res.data
          }else{
            this.isShowList = false
          }
        })
      }
    },
    // 获取机构编码
    getNumber(obj){
      console.log(obj)
      this.merchantsForm.customerNumber = obj.customerNumber
      this.company = obj.company
      this.isShowList = false
    },
    // 退出登录
    signOut() {
      http({
        api: 'admin.saas.user.logout'
      }).then(res => {
        window.localStorage.clear()
        location.reload()
      })
    },
    // 活动列表数据
    getData() {
      http({
        api: "selection.competitionContent.activities",
        pageNum: 1,
        pageSize: 1,
        name: "",
      }).then(res=>{
        res = res.data
        if(res.code == '200'){
          this.competitionId = res.data.list[0].id
          
        }else{
          this.competitionId = ''
        }
        this.isEnroll()
      }) 
    },
    // 判断学生是否报名
    isEnroll(){
      http({
        api: "selection.competitionContent.selectEcho",
        competitionId:this.competitionId,
      }).then(res=>{
        res = res.data
        if(res.code == '200'){
          this.btnName = res.data.operate_state ? '去查看' : '去报名'
        }else{
          this.btnName = '去报名'
        }
      })
    },
    merchantsLogin(merchantsForm) {
      this.$refs["merchantsForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$store
            .dispatch("user/login", this.merchantsForm)
            .then((res) => {
              if (window.localStorage.getItem("relBindURL")) {
                this.toBindMini();
                this.loading = false;
                return;
              }

              // wxType 1关联 2注册 loginStep 0未完成注册或者关联 1已完成注册或者关联
              let url = "";
              res = res.data;
              if (res.wxType === 1 && res.loginStep === 0) {
                url = "/wxthird/rel";
                // window.location.replace(baseUrl.openUrl)
              } else if (res.wxType === 2 && res.loginStep < 3) {
                url = "/wxthird/reg";
              } else {
                url = this.redirect || "/";
              }
              // this.$router.push({ path: url });
              window.localStorage.setItem('poster',true)
              this.loginRole(url) // 获取当前登录用户角色
              
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 获取当前登录用户角色
    loginRole(val){
      http({
        api: "selection.competition.group.loginRole"
      }).then(res=>{
        res = res.data
        if(res.code == '200'){
          window.localStorage.setItem('loginRole',res.data.name)
          if(res.data.name == '学生'){
            if(this.type == 1){
              window.localStorage.clear()
              return this.$message.error('您不是专家，请重新登录！')
            }
            this.$router.push({ path: '/studentform' });
          }else{
            this.$router.push({ path: val });
          }
        }
      })
    },
    // 点击登录 
    login(){
      this.type = 0
      if(this.isLogin){
        if(this.getRoleName == '学生'){
          this.$router.push({ path: '/studentform' });
        }else{
          this.$router.push('/plug_ins/selectionContest/index')
        }
      }else{
        this.boxShow = true;
      }
    },
    // 去评审
    go() {
      this.type = 1
      if (this.isLogin) {
        this.$router.push('/plug_ins/selectionContest/index')
      } else {
        this.boxShow = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
header {
  background: #0c1443;
  text-align: center;
  width: 100%;
  overflow: hidden;
  position:relative;
  .sign-out{
    position:absolute;
    right:30px;
    top:30px;
    color:#fff;
    display:flex;
    align-items:center;
    font-size:20px;
    cursor:pointer;
    img{
      width:24px;
      height:24px;
      margin-right:6px;
    }
  }
  img {
    height: 700px;
    vertical-align: top;
  }
}
.body {
  background: url(https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230315/1635852005253242880.png);
  overflow: hidden;
  .top {
    margin: 126px auto 60px;
    width: 1080px;
    // height: 566px;
    background: #ffffff;
    box-shadow: 4px 12px 30px 6px rgba(0, 0, 0, 0.09);
    border-radius: 20px;
    padding: 50px 110px;
    font-size: 26px;
    font-weight: 400;
    color: #191919;
    line-height: 51px;
  }
  .title {
    text-align: center;
    margin-bottom: 60px;
    img {
      height: 100px;
    }
  }
  .item {
    width: 1080px;
    margin: 0 auto 124px;
    position: relative;
    .index {
      position: absolute;
      top: -16px;
      left: -21px;
      z-index: 2;
      img {
        width: 374px;
      }
    }
    .cont {
      width: 853px;
      background: #ffffff;
      box-shadow: 4px 12px 30px 6px rgba(0, 0, 0, 0.09);
      padding: 65px 60px 65px 128px;
      border-radius: 20px;
      margin: 0 0 0 225px;
      color: #666666;
      line-height: 34px;
      font-size: 20px;
      position: relative;
      top: 75px;
      min-height: 160px;
      p{
        margin-bottom: 20px;
      }
    }
  }
  .process{
    text-align:center;
    margin-bottom:60px;
  }
  .link {
    background: #ffffff;
    box-shadow: 4px 12px 30px 6px rgba(0, 0, 0, 0.09);
    min-height: 100px;
    width: 1080px;
    margin: 0 auto 50px;
    border-radius: 20px;
    padding: 60px 100px;
    a{
      display: block;
      color: #C39961;
      text-decoration: underline;
      padding:15px 0;
      font-size: 26px;
    }
  }
}
.merchants-relative{
  position:relative;
  .merchants-list{
    position:absolute;
    left:0px;
    top:64px;
    width: 100%;
    background: #fff;
    border: 1px solid #eee;
    z-index: 10001;
    border-radius: 0 0 6px 6px;
    padding-left:20px;
    li{
      cursor:pointer;
      &:hover{
        color:#dcb682;
      }
    }
  }
}
.btns {
  position: fixed;
  left: 50%;
  top: 68vh;
  transform: translateX(640px);
  display: none;
  opacity: 0;
  transition: all 3s;

  &.show {
    display: block;
    opacity: 1;
  }
  div {
    width: 220px;
    text-align: center;
    height: 66px;
    line-height: 66px;
    box-shadow: 4px 12px 30px 6px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    font-size: 24px;
    margin-bottom: 20px;
    color: #fff;
    cursor: pointer;
  }
  .btns-login {
    background: linear-gradient(135deg, #dcb682 0%, #e4cfb2 100%);
  }
  .btns-ps {
    background: linear-gradient(90deg, #0c1443 0%, #212c71 100%);
  }
}
.login-box {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2;
  left: 0;
  top: 0;
  .mask {
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
  }
  .login {
    position: absolute;
    z-index: 2;
    width: 560px;
    height: 660px;
    background: #fff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    h3 {
      text-align: center;
      margin: 80px 0 60px;
    }
    &::after {
      display: block;
      content: "";
      background: url("https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230310/1634084502608994304.png")
        no-repeat;
      background-size: 561px 183px;
      height: 183px;
      width: 561px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .el-form {
      width: 400px;
      margin: 0 auto;
      position: relative;
      z-index: 1;
    }
    .el-button {
      width: 400px;
      height: 67px;
      background: linear-gradient(135deg, #dcb682 0%, #e4cfb2 100%);
      border-radius: 6px;
      font-size: 21px;
      color: #fff;
      margin-top: 60px;
      border: 0;
    }
    .my-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      img {
        width: 18px;
      }
    }
  }
  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 36px;
    cursor: pointer;
    i {
      vertical-align: top;
    }
  }
}

</style>
<style lang="less" scoped>
.login /deep/.el-input__inner {
  height: 67px !important;
  line-height: 67px !important;
  background: #f2f4f9;
  border: 0;
  border-radius: 6px;
}
</style>
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main", staticStyle: { position: "relative" } },
    [
      _c("div", {
        staticStyle: {
          "z-index": "999",
          position: "absolute",
          height: "100vh",
        },
        attrs: { id: "fcytMap" },
      }),
      _c("img", {
        staticClass: "title",
        attrs: {
          alt: "数据看板",
          src: require("@/assets/imgs/board_title.png"),
        },
        on: { click: _vm.getCenter },
      }),
      _c(
        "div",
        {
          staticClass: "verticalList showtop",
          staticStyle: {
            position: "absolute",
            left: "0",
            "margin-left": "3.33%",
          },
        },
        [
          _c(
            "div",
            { staticClass: "verticalItem ", staticStyle: { height: "17rem" } },
            [
              _c(
                "div",
                { staticClass: "graphBar" },
                [
                  _vm._m(0),
                  _c(
                    "el-select",
                    {
                      staticClass: "select-input",
                      attrs: { placeholder: "请选择状态" },
                      on: { change: _vm.onDurationSaleChange },
                      model: {
                        value: _vm.turnoverIndex,
                        callback: function ($$v) {
                          _vm.turnoverIndex = $$v
                        },
                        expression: "turnoverIndex",
                      },
                    },
                    _vm._l(_vm.durationSaleOptions, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.lable, value: index },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "subTitle" }, [
                _c("div", { staticStyle: { color: "#D3F5FF" } }, [
                  _vm._v("累计营业额"),
                ]),
                _c(
                  "div",
                  {
                    staticStyle: {
                      color: "#43EDF8",
                      "font-weight": "bold",
                      "margin-left": "1rem",
                    },
                  },
                  [_vm._v(_vm._s(_vm.z_total))]
                ),
              ]),
              _c("div", { staticClass: "graph", attrs: { id: "turnoverTJT" } }),
              _c("div", { staticClass: "container-header" }),
              _c("div", { staticClass: "container-footer" }),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "verticalItem",
              staticStyle: { height: "15.0625rem" },
            },
            [
              _c(
                "div",
                { staticClass: "graphBar" },
                [
                  _vm._m(1),
                  _c(
                    "el-select",
                    {
                      staticClass: "select-input",
                      attrs: { placeholder: "请选择状态" },
                      on: { change: _vm.onDurationOrderChange },
                      model: {
                        value: _vm.orderIndex,
                        callback: function ($$v) {
                          _vm.orderIndex = $$v
                        },
                        expression: "orderIndex",
                      },
                    },
                    _vm._l(_vm.durationOptions, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.lable, value: index },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "graph", attrs: { id: "ddTJT" } }),
              _c("div", { staticClass: "container-header" }),
              _c("div", { staticClass: "container-footer" }),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "verticalItem",
              staticStyle: { height: "17.125rem" },
            },
            [
              _c(
                "div",
                { staticClass: "graphBar" },
                [
                  _vm._m(2),
                  _c(
                    "el-select",
                    {
                      staticClass: "select-input",
                      attrs: { placeholder: "请选择状态" },
                      on: { change: _vm.onDurationUserChange },
                      model: {
                        value: _vm.userIndex,
                        callback: function ($$v) {
                          _vm.userIndex = $$v
                        },
                        expression: "userIndex",
                      },
                    },
                    _vm._l(_vm.durationUserOptions, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.lable, value: index },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "graph", attrs: { id: "usersPie" } }),
              _c("div", { staticClass: "container-header" }),
              _c("div", { staticClass: "container-footer" }),
            ]
          ),
        ]
      ),
      _vm.controlBoard
        ? _c(
            "div",
            {
              staticClass: "horizontalList showtop",
              staticStyle: { position: "absolute" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "horizontalItem",
                  on: {
                    click: function ($event) {
                      return _vm.toOrderDetail("0")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "itemTitle" }, [_vm._v("待付款")]),
                  _c("div", { staticClass: "itemValue" }, [
                    _vm._v(_vm._s(_vm.dataMain.notPay)),
                  ]),
                  _c("div", { staticClass: "container-header" }),
                  _c("div", { staticClass: "container-footer" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "horizontalItem",
                  on: {
                    click: function ($event) {
                      return _vm.toOrderDetail("1")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "itemTitle" }, [_vm._v("待发货")]),
                  _c("div", { staticClass: "itemValue" }, [
                    _vm._v(_vm._s(_vm.dataMain.noSendOrderNum)),
                  ]),
                  _c("div", { staticClass: "container-header" }),
                  _c("div", { staticClass: "container-footer" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "horizontalItem",
                  on: {
                    click: function ($event) {
                      return _vm.toOrderDetail("2")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "itemTitle" }, [_vm._v("待收货")]),
                  _c("div", { staticClass: "itemValue" }, [
                    _vm._v(_vm._s(_vm.dataMain.notDeliverNum)),
                  ]),
                  _c("div", { staticClass: "container-header" }),
                  _c("div", { staticClass: "container-footer" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "horizontalItem",
                  on: {
                    click: function ($event) {
                      return _vm.toOrderDetail("5")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "itemTitle" }, [_vm._v("待评价")]),
                  _c("div", { staticClass: "itemValue" }, [
                    _vm._v(_vm._s(_vm.dataMain.orderNotCommentsNum)),
                  ]),
                  _c("div", { staticClass: "container-header" }),
                  _c("div", { staticClass: "container-footer" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "horizontalItem",
                  on: {
                    click: function ($event) {
                      return _vm.toOrderDetail("退货")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "itemTitle" }, [_vm._v("待退货")]),
                  _c("div", { staticClass: "itemValue" }, [
                    _vm._v(_vm._s(_vm.dataMain.orderReturnWaitNum)),
                  ]),
                  _c("div", { staticClass: "container-header" }),
                  _c("div", { staticClass: "container-footer" }),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm.controlBoard
        ? _c(
            "div",
            {
              staticClass: "verticalList showtop",
              staticStyle: {
                position: "absolute",
                right: "0",
                "margin-right": "3.33%",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "verticalItem news",
                  staticStyle: { height: "10.0625rem" },
                },
                [
                  _c("div", { staticClass: "container-header" }),
                  _c("div", { staticClass: "container-footer" }),
                  _vm._m(3),
                  _c(
                    "ul",
                    {
                      staticStyle: {
                        "margin-top": "0.625rem",
                        height: "7.1875rem",
                        overflow: "auto",
                      },
                    },
                    [
                      _c(
                        "infinite-scroll",
                        { attrs: { list: _vm.dataMain.sysNotice } },
                        _vm._l(_vm.dataMain.sysNotice, function (item, index) {
                          return _c(
                            "li",
                            {
                              key: index,
                              on: {
                                click: function ($event) {
                                  return _vm.toNoticeDetail(item.id)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "item" }, [
                                _c("span", { staticClass: "newsTitle" }, [
                                  _vm._v(_vm._s(item.title)),
                                ]),
                                _c("span", { staticClass: "newsTime" }, [
                                  _vm._v(
                                    _vm._s(_vm._f("dateFormat")(item.add_time))
                                  ),
                                ]),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "verticalItem",
                  staticStyle: { height: "18.5625rem" },
                },
                [
                  _c("div", { staticClass: "container-header" }),
                  _c("div", { staticClass: "container-footer" }),
                  _vm._m(4),
                  _c(
                    "ul",
                    {
                      staticStyle: { height: "15.625rem", overflow: "hidden" },
                    },
                    [
                      _vm._m(5),
                      _c(
                        "infinite-scroll",
                        { attrs: { list: _vm.dataMain.newOrders } },
                        _vm._l(_vm.dataMain.newOrders, function (item, index) {
                          return _c("li", { key: index }, [
                            _c("div", { staticClass: "newOrderItem" }, [
                              _c("div", { staticClass: "projectArea name" }, [
                                _vm._v(
                                  _vm._s(item.goodsName) +
                                    _vm._s(item.goodsName)
                                ),
                              ]),
                              _c("div", { staticClass: "projectArea money" }, [
                                _vm._v("￥" + _vm._s(item.orderPrice)),
                              ]),
                              _c("div", { staticClass: "projectArea user" }, [
                                _vm._v(_vm._s(item.userName)),
                              ]),
                              _c("div", { staticClass: "projectArea time" }, [
                                _c("div", [_vm._v(_vm._s(item.timeDay))]),
                                _c("div", [_vm._v(_vm._s(item.timeTime))]),
                              ]),
                            ]),
                          ])
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._m(6),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticClass: "iconImg",
        attrs: { alt: "营业额", src: require("@/assets/imgs/ic_turnover.png") },
      }),
      _c("span", { staticClass: "graphTitle" }, [_vm._v("营业额")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticClass: "iconImg",
        attrs: { alt: "订单数", src: require("@/assets/imgs/ic_order.png") },
      }),
      _c("span", { staticClass: "graphTitle" }, [_vm._v("订单数")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticClass: "iconImg",
        attrs: { alt: "访客数", src: require("@/assets/imgs/ic_visitor.png") },
      }),
      _c("span", { staticClass: "graphTitle" }, [_vm._v("访客数")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "graphBar" }, [
      _c("div", [
        _c("img", {
          staticClass: "iconImg",
          attrs: {
            alt: "通知公告",
            src: require("@/assets/imgs/ic_notice.png"),
          },
        }),
        _c("span", { staticClass: "graphTitle" }, [_vm._v("通知公告")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "graphBar" }, [
      _c("div", [
        _c("img", {
          staticClass: "iconImg",
          attrs: { alt: "新订单", src: require("@/assets/imgs/ic_new.png") },
        }),
        _c("span", { staticClass: "graphTitle" }, [_vm._v("新订单")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "div",
        {
          staticClass: "newOrderItem",
          staticStyle: { height: "1.4375rem", "margin-top": "0.6875rem" },
        },
        [
          _c("div", { staticClass: "projectArea" }, [_vm._v("商品名称")]),
          _c("div", { staticClass: "projectArea" }, [_vm._v("订单金额")]),
          _c("div", { staticClass: "projectArea" }, [_vm._v("买家信息")]),
          _c("div", { staticClass: "projectArea" }, [_vm._v("下单时间")]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "verticalItem", staticStyle: { height: "20.6875rem" } },
      [
        _c("div", { staticClass: "graphBar" }, [
          _c("div", [
            _c("img", {
              staticClass: "iconImg",
              attrs: {
                alt: "销量排行",
                src: require("@/assets/imgs/ic_rank.png"),
              },
            }),
            _c("span", { staticClass: "graphTitle" }, [_vm._v("销量排行")]),
          ]),
        ]),
        _c("div", { staticClass: "graph", attrs: { id: "graphSalesRank" } }),
        _c("div", { staticClass: "container-header" }),
        _c("div", { staticClass: "container-footer" }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="l-upload">
    <div
      v-for="(url, index) of fileList"
      :key="index"
      class="img-item"
      :style="{
        width: size + 'px',
        height: size + 'px',
        marginRight: marginRight
      }"
    >
      <img :src="url" alt="" :style="{ width: size + 'px', height: size + 'px' }">
      <div v-if="!disabled" class="actions">
        <i class="el-icon-delete" @click="removeImg(index)" />
        <i v-if="index != 0 && mainImg" class="set-main" @click="setMain(index)">设为主图</i>
      </div>
      <i v-if="index == 0 && mainImg" class="img-main">主图</i>
    </div>
    <div
      v-if="fileList.length < limit"
      class="upload"
      :style="{
        width: size + 'px',
        height: size + 'px',
        marginRight: marginRight
      }"
      @click="dialogVisible = true"
    >
      <i class="el-icon-plus" />
    </div>
    <div class="desc">
      {{ text }}
    </div>

    <!--    <upload-dialog-->
    <!--      :isChoice="true"-->
    <!--      @getPic="handleGetPic"-->
    <!--      @getPicD="handleGetPicD"-->
    <!--      v-model="dialogVisible"-->
    <!--    ></upload-dialog>-->
    <uDialog v-if="dialogVisible" :group-type="groupType" :group-id="groupId" @getPicD="handleGetPicD" @getPic="handleGetPic" @close="close" />
  </div>
</template>

<script>
import uDialog from '@/packages/Upload/dialog'

export default {
  name: 'LUpload',
  provide() {
    return {
      indexCom: this
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    uDialog
  },
  props: {
    limit: {
      type: Number,
      default: 1
    },
    text: {
      type: String,
      default: '（最多上传一张，建议上传1：1尺寸的图片）'
    },
    value: {
      type: [Array, String],
      default: ''
    },
    list: {
      type: Array,
      default: () => {
        return []
      }
    },

    mainImg: {
      // 是否显示主题
      type: Boolean,
      default: false
    },

    append_to_body: {
      type: Boolean,
      default: true
    },
    cropperWidth:{
      type: Number
    },
    cropperHeight:{
      type: Number
    },
    size: {
      type: [Number, String],
      default: 80
    },
    disabled: {
      type: Boolean
    },
    maxSize: {
      type: Number,
      default: 0
    },
    // groupId: {
    //   type: Number,
    //   default: -1
    // }
  },
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      imgNumber: 0,
      imgLimit: 1,
      fileList: [],
      maxSelectNum: 1,
      groupId: -1,
      groupType: sessionStorage.getItem('uploadAsideTabIndex') || '0',
    }
  },
  computed: {
    isChoice() {
      return this.limit === 1
    },
    marginRight() {
      if (this.text) {
        return '15px'
      }
      return '0'
    }
  },
  watch: {
    'value.length': {
      handler(newVal, oldVal) {
        // console.log('watch handler value.length:', newVal, oldVal, this.value)
        if (Array.isArray(this.value)) {
          this.fileList = this.value
        } else {
          if (this.value) {
            this.fileList = [this.value]
          } else {
            this.fileList = []
          }
        }

        if (this.limit === 1) return 1
        this.maxSelectNum = this.limit - this.fileList.length
        // console.log(this.maxSelectNum)
      },
      // 这里是关键，代表递归监听 demo 的变化
      deep: true,
      immediate: true
    },
    'value': {
      handler(newVal, oldVal) {
        // console.log('watch handler value:', newVal, oldVal, this.value)
        if (Array.isArray(this.value)) {
          this.fileList = this.value
        } else {
          if (this.value) {
            this.fileList = [this.value]
          } else {
            this.fileList = []
          }
        }

        if (this.limit === 1) return 1
        this.maxSelectNum = this.limit - this.fileList.length
        // console.log(this.maxSelectNum)
      },
      // 这里是关键，代表递归监听 demo 的变化
      deep: true,
      immediate: true
    },

    limit(val) {
      this.imgLimit = val
      if (this.limit === 1) return 1
      this.maxSelectNum = this.limit - this.fileList.length
    },
    dialogVisible(val){
      // watch模式是因为存在多个上传组件时，后面传入的值的覆盖
      if(val){
        // 存储要裁切图片尺寸
        console.log('设置裁切框尺寸：',this.cropperWidth, this.cropperHeight)
        this.$store.commit('upload/SET_SIZE',{cropperWidth: this.cropperWidth, cropperHeight: this.cropperHeight})
      }
    }
  },
  created(){

  },
  methods: {
    getLimit() {
      return this.limit
    },
    removeImg(index) {
      this.fileList.splice(index, 1)
      this.$emit('remove', index)
      if (this.limit === 1) {
        this.$emit('input', '')
      } else {
        this.$emit('input', this.fileList)
      }
    },

    setMain(index) {
      const imgUrl = this.fileList[index]
      this.fileList.splice(index, 1)
      this.fileList.unshift(imgUrl)
    },

    // 单选
    handleGetPic(data) {
      console.log(data)
      this.fileList.push(data.url)
      this.$emit('select', data.url)
      this.$emit('input', data.url)
      this.dialogVisible = false
    },
    // 多选
    handleGetPicD(data) {
      let list = []
      for (let i = 0; i < data.length; i++) {
        list.push(data[i].url)
      }
      list = this.fileList.concat(list)
      this.fileList = list
      this.$emit('select', list)
      this.$emit('input', list)
      this.dialogVisible = false
    },
    close(){
      this.dialogVisible = false
      sessionStorage.removeItem('uploadAsideTabCommonMenuIndex')
      sessionStorage.removeItem('uploadAsideTabIndex')
    }
  }
}
</script>

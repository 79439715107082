<template>
  <div class="wpp">
    <wxthird-header />
    <div class="box" v-loading="loading" v-if="!isErr">
      <el-steps
        :active="active"
        finish-status="success"
        style="margin-bottom: 100px; cursor: pointer;"
      >
        <el-step :title="item" v-for="(item,index) in stepList" :key="index" @click.native="changeStep(index)"></el-step>
      </el-steps>
      <el-form
        ref="ruleForm"
        :model="param"
        :rules="rules"
        label-width="180px"
        :close-on-click-modal="false"
      >
        <div v-if="active == 0">
          <el-form-item ref="miniName" label="小程序名称" prop="miniName">
            <div class="name-item" style="display: flex; align-items: center">
              <el-input
                v-model="param.miniName"
                style="width: 50%; margin-right: 10px"
                :disabled="miniNameObj.nickNameAuditstatus==1"
              />
              <el-button
                type="primary"
                v-model="param.miniName"
                @click="checkName"
                :disabled="miniNameObj.nickNameAuditstatus==1"
                >检测名称是否可用</el-button
              >
            </div>
          </el-form-item>
          <el-form-item
            label="营业执照或组织代码证"
            required
            prop="licenseMediaid"
            v-if="miniNameObj.showMore"
            :rules="{
                required: true, message: '营业执照或组织代码证不能为空', trigger: 'change'
              }"
          >
            <el-upload
              :action="imgUrl"
              :data="{ appid: appid, type: 'image' }"
              :limit="1"
              accept="image/png, image/jpeg"
              :before-upload="beforeUpload"
              :file-list="miniNameObj.fileListLicense"
              :on-success="
                (response, file, fileList) =>
                  getNameFile(response, file, fileList, 'license')
              "
              :on-remove="
                (file, fileList) => removeNameFile(file, fileList, 'license')
              "
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件，且不超过1MB
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item
            v-if="false" 
            label="管理员身份证照片"
          >
            <el-upload
              :action="imgUrl"
              :data="{ appid: appid, type: 'image' }"
              :limit="1"
              accept="image/png, image/jpeg"
              :before-upload="beforeUpload"
              :file-list="miniNameObj.fileListIdcard"
              :on-success="
                (response, file, fileList) =>
                  getNameFile(response, file, fileList, 'idCard')
              "
              :on-remove="
                (file, fileList) => removeNameFile(file, fileList, 'idCard')
              "
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件，且不超过1MB
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item
            label="补充材料"
            v-if="miniNameObj.showMore"
          >
            <el-upload
              :action="imgUrl"
              :data="{ appid: appid, type: 'image' }"
              :limit="8"
              accept="image/png, image/jpeg"
              :before-upload="beforeUpload"
              :file-list="miniNameObj.fileListFJ"
              :on-success="
                (response, file, fileList) =>
                  getNameFile(response, file, fileList, 'FJ')
              "
              :on-remove="
                (file, fileList) => removeNameFile(file, fileList, 'FJ')
              "
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件，且不超过1MB
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <div class="name-tips" :class="miniNameObj.status?'suc':'err'" v-if="miniNameObj.tips">
              {{ miniNameObj.tips }}
            </div>
            <div class="name-tips" v-else>
              帐号名称长度为4-30个字符，一个中文字等于2个字符。
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitName" v-if="miniNameObj.status || (miniNameObj.showMore&&param.licenseMediaid)">提交</el-button>
          </el-form-item>
        </div>

        <div v-if="active == 1">
          <el-form-item ref="miniHead" label="小程序logo" prop="miniHead">
            <el-upload
              :action="imgUrl"
              :data="{ appid: appid, type: 'image' }"
              accept="image/png, image/jpeg"
              :before-upload="beforeUpload"
              :show-file-list="false"
              :on-success="getLogoFile"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                头像不允许涉及政治敏感与色情;图片格式必须为：png,bmp,jpeg,jpg,gif；不可大于1M；建议使用png格式图片，以保持最佳效果；建议图片尺寸为144px*144px
              </div>
            </el-upload>
            <!-- <p class="gary">
                头像不允许涉及政治敏感与色情;图片格式必须为：png,bmp,jpeg,jpg,gif；不可大于2M；建议使用png格式图片，以保持最佳效果；建议图片尺寸为144px*144px
              </p> -->
            <div v-if="param.miniHead" class="upload-image-show">
              <img class="upload-image-show1" :src="param.miniHead" alt="" />
              <img class="upload-image-show2" :src="param.miniHead" alt="" />
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitLogo" v-if="logoId">提交</el-button>
          </el-form-item>
        </div>
        <div v-if="active == 2">
          <el-form-item
            ref="miniSignature"
            label="小程序介绍"
            prop="miniSignature"
          >
            <el-input
              v-model="param.miniSignature"
              type="textarea"
              :auto-size="{ minRows: 2, maxRows: 6 }"
            />
            <p class="gary">
              请确认介绍内容不含国家相关法律法规禁止内容,介绍字数为4~120个字
            </p>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitSignature">提交</el-button>
          </el-form-item>
        </div>

        <div v-if="active == 3">
          <el-form-item><div class="name-tips">以下4种联系方式至少要填一种</div></el-form-item>
          <el-form-item
            ref="ownerContactPhone"
            label="电话"
            prop="ownerContactPhone"
          >
            <el-input v-model="param.ownerContactPhone" />
          </el-form-item>
          <el-form-item
            ref="ownerContactEmail"
            label="邮箱"
            prop="ownerContactEmail"
          >
            <el-input v-model="param.ownerContactEmail" />
          </el-form-item>
          <el-form-item
            ref="ownerContactWeixin"
            label="微信"
            prop="ownerContactWeixin"
          >
            <el-input v-model="param.ownerContactWeixin" />
          </el-form-item>
          <el-form-item ref="ownerContactQq" label="QQ" prop="ownerContactQq">
            <el-input v-model="param.ownerContactQq" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitContact">提交</el-button>
          </el-form-item>
        </div> 
      </el-form>
    </div>
    <div class="box" v-else>
      <el-result title="注册错误" :subTitle="isErr">
        <template slot="icon">
          <el-image style="width: 400px;" src="https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/460/1/20240805/1820305289252704256.png"></el-image>
        </template>
        <template slot="extra">
          <el-button type="primary" size="medium" @click="relogin">重新登录，再试一次</el-button>
        </template>
      </el-result>
    </div>
  </div>
</template>

<script>
import wxthirdHeader from "../head.vue";
import { post } from "@/api/common";

export default {
  components: {
    wxthirdHeader,
  },
  data() {
    return {
      loading: false,
      appid: "",
      isErr: '',
      active: 0,
      param: {
        miniName: "",
        miniHead: "",
        miniSignature: "",
        idCardMediaid: "",
        licenseMediaid: "",
        nickNameMediaids: [],
        ownerContactPhone: "",
        ownerContactEmail: "",
        ownerContactWeixin: "",
        ownerContactQq: "",
      },
      rules: {
        miniName: [
          { required: true, message: "小程序名称不能为空！", trigger: "blur" },
          {
            max: 30,
            min: 2,
            message: "小程序名称字数为4~30个字",
            trigger: "blur",
          },
        ],
        miniHead: [
          { required: true, message: "小程序logo不能为空！", trigger: "blur" },
        ],
        miniSignature: [
          { required: true, message: "小程序介绍不能为空！", trigger: "blur" },
          {
            max: 120,
            min: 4,
            message: "小程序介绍字数为4~120个字",
            trigger: "blur",
          },
        ],

        ownerContactPhone: [{ validator: this.checkPhoneNub }],
        ownerContactEmail: [{ validator: this.checkEmail }],
      },
      miniNameObj: {
        status: false,
        tips: "",
        fileListLicense: [],
        fileListIdcard: [],
        fileListFJ: [],
        nickNameAuditstatus: '' //审核状态
      },
      logoFiles: "",
      logoId: '',
      stepList:['设置小程序名称','设置小程序logo','设置小程序介绍','设置用户隐私保护指引']
    };
  },
  computed: {
    imgUrl() {
      return process.env.VUE_APP_UPLOAD_IMG_URL;
    },
  },
  created() {
    this.isErr = this.$route.query.errmsg
    this.appid = this.$route.query.appId
    this.checkNameStatus()
  },
  methods: {
    // 重新登陆 
    relogin(){
      // 清除缓存后跳转登录页面
      localStorage.clear()
      this.$router.push('/login')
    },
    // 校验小程序名称是否可用
    checkName() {
      this.$refs.ruleForm.validateField("miniName", (valid) => {
        if (!valid) {
          this.loading = true;
          post({
            api: "customer.wx.check.mini.name",
            nickName: this.param.miniName,
          }).then((res) => {
            this.loading = false;
            if (res.data.code == 200) {
              // 名称可用直接用
              if (res.data.data == "Success") {
                this.miniNameObj.status = true;
                this.miniNameObj.showMore = false;
                this.miniNameObj.tips = "你的名字可以使用";
              } else {
                this.miniNameObj.status = false;
                this.miniNameObj.tips = res.data.data.errorMsg;
                // 需要提交资料
                if (res.data.data.isShow) {
                  this.miniNameObj.showMore = true;
                } else {
                  // 不可以用
                  this.miniNameObj.showMore = false;
                }
              }
            }
          });
        }
      });
    },
    // 修改名字
    setName() {
      post({
        api: "customer.wx.set.mini.name",
        nickName: this.param.miniName,
        idCardMediaid:
          !this.miniNameObj.status && this.miniNameObj.showMore
            ? this.param.idCardMediaid
            : "",
        licenseMediaid:
          !this.miniNameObj.status && this.miniNameObj.showMore
            ? this.param.licenseMediaid
            : "",
        nickNameMediaids:
          !this.miniNameObj.status &&
          this.miniNameObj.showMore &&
          this.param.nickNameMediaids.length
            ? this.param.nickNameMediaids.join(",")
            : "",
      }).then((res) => {
        if (res.data.code == 200) {
          this.active = 1;
        }
      });
    },
    // 提交名字
    submitName(){
      this.$refs.ruleForm.validateField("miniName", (msg) => {
        if (!msg) {
          // 判断名字是否可直接用
          if (this.miniNameObj.status) {
            // 直接修改
            this.setName();
          } else {
            // 判断是否要提交更多资料
            if (this.miniNameObj.showMore) { 
              this.$refs.ruleForm.validateField("licenseMediaid",(valid)=>{
                if (!valid) {
                  this.setName();
                }
              })
              
            } else {
            }
          }
        }
      });
    },
    // 限制上传大小
    beforeUpload(file) { 
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!isLt1M) {
        this.$message.error('上传头像图片大小不能超过 1MB!');
      }
      return isLt1M;
    },
    getNameFile(response, file, fileList, name) {
      if(name=='license'){
        this.param.licenseMediaid = response.data
        this.miniNameObj.fileListLicense = fileList
      }else if(name=='idCard'){
        this.param.idCardMediaid = response.data
        this.miniNameObj.fileListIdcard = fileList
      } else{
        this.param.nickNameMediaids.push(response.data)
        this.miniNameObj.fileListFJ = fileList
      }
    },
    getLogoFile(e,file) {
      this.logoFiles = file.raw;
      this.param.miniHead = window.URL.createObjectURL(this.logoFiles);
      this.logoId = e.data
    },
    submitLogo(){
      if (!this.logoFiles) {
        this.$message.error("请上传logo");
        return;
      }
      post({
        headImgMediaId: this.logoId,
        x1: 0,
        x2: 1,
        y1: 0,
        y2: 1,
        api: "customer.wx.set.mini.head",
      }).then((res) => {
        this.loading = false;
        if(res.data.code == 200){
          this.active = 2;
        }
      });
    },
    // 移除时
    removeNameFile(file, fileList, name) {
      if(name=='license'){
        this.param.licenseMediaid = ''
        this.miniNameObj.fileListLicense = []
      }else if(name=='idCard'){
        this.param.idCardMediaid = ''
        this.miniNameObj.fileListIdcard = []
      } else{
        const index = this.miniNameObj.fileListFJ.findIndex(item => item.uid === file.uid);
        this.param.nickNameMediaids.splice(index, 1)
        this.miniNameObj.fileListFJ.splice(index, 1)
      }
    },
    submitSignature(){
      this.$refs.ruleForm.validateField("miniSignature", (msg) => {
        if (!msg) {
          post({
            api: "customer.wx.set.mini.signature",
            signature: this.param.miniSignature,
          }).then((res) => {
            if (res.data.code == 200) {
              this.active = 3;
            }
          });
        }
      });
    },
    submitContact(){
      var privacy = this.param;
      if (
        privacy.ownerContactPhone == "" &&
        privacy.ownerContactEmail == "" &&
        privacy.ownerContactWeixin == "" &&
        privacy.ownerContactQq == ""
      ) {
        this.$message.error("4种联系方式至少要填一种");
        return false;
      }
      post({
        api: "customer.wx.code.privacy",
        ownerContactPhone: this.param.ownerContactPhone,
        ownerContactEmail: this.param.ownerContactEmail,
        ownerContactWeixin: this.param.ownerContactWeixin,
        ownerContactQq: this.param.ownerContactQq,
      }).then((res) => {
        res = res.data;
        if (res.code == 200) {
          this.$router.push("/");
        }
      });
    },
    checkNameStatus(){
      post({api: 'customer.wx.miniName.examine'}).then(res=>{
        if(res.data.code == 200){
          // nickNameAuditstatus 审核状态 1审核中 2驳回 3成功
          // nickNameReason 小程序名称驳回原因
          let obj = res.data.data
          this.param.miniName = obj.nickName
          this.param.miniHead = obj.headImg
          this.param.miniSignature = obj.signature
          this.miniNameObj.nickNameAuditstatus = obj.nickNameAuditStatus
 
          if(obj.nickNameAuditStatus == 1){
            this.miniNameObj.tips = '审核中'
          }        
          if(obj.nickNameAuditStatus == 2){
            this.miniNameObj.tips = obj.nickNameReason
          } 
       
        }
      })
    },
    changeStep(e){
      this.active = e
    }

  },
};
</script>

<style lang="scss" scoped>
.wpp {
  height: 100%;
  .box {
    background: #fff;
    box-shadow: 0 0 20px #eee;
    border-radius: 20px;
    padding: 100px;
    margin: 0 10%;
    height: calc(100% - 150px);
    box-sizing: border-box;
    position: relative;
    top: 70px;
  }
  .name-tips {
    color: #666; 
    &.err {
      color: #F56C6C;
      line-height: 1.4;
    }    
    &.suc {
      color: #67C23A;
      line-height: 1.4;
    }
  }
  .upload-image-show {
    display: flex;
    padding: 10px;
    background: #f1f1f1;
    width: 236px;
    img {
      width: 104px;
      height: 104px;
    }
    .upload-image-show1 {
      margin-right: 10px;
    }
    .upload-image-show2 {
      border-radius: 100px;
    }
  }
}
</style>